import { Drawer, Menu } from 'antd'
import { pipe, toggleArrayItem } from '../../libs/utils'

import React from 'react'
import { useQuery } from 'react-query'
import { userGroups } from './routingConfig'

export default function SimulationDrawer({
  user,
  simulatedUser,
  onSimulateUser,
  showUserSimulatorDrawer,
  toggleUserSimulatorDrawer,
}) {
  const courseQuery = useQuery('/course')
  const productQuery = useQuery('/produto')

  return (
    <Drawer
      title='Simulação'
      placement='right'
      width={300}
      closable={true}
      onClose={() => toggleUserSimulatorDrawer(false)}
      visible={showUserSimulatorDrawer}
    >
      <Menu
        mode='inline'
        selectedKeys={[
          `group-${simulatedUser.group}`,
          `course-${simulatedUser.course}`,
          ...simulatedUser.product.map((product) => `product-${product}`),
        ]}
      >
        <Menu.SubMenu title='Acesso'>
          {pipe(
            userGroups.findIndex(([group]) => group === user.group),
            (userLevel) =>
              userGroups.map(
                ([group, text], index) =>
                  index >= userLevel && (
                    <Menu.Item
                      key={`group-${group}`}
                      onClick={() =>
                        onSimulateUser({ ...simulatedUser, group })
                      }
                    >
                      {`${group === user.group ? '* ' : ''}${text}`}
                    </Menu.Item>
                  )
              )
          )}
        </Menu.SubMenu>
        <Menu.SubMenu title='Cursos'>
          {courseQuery.data?.map((course) => (
            <Menu.Item
              key={`course-${course.id}`}
              onClick={() =>
                onSimulateUser({ ...simulatedUser, course: course.id })
              }
            >
              {`${user.course === course.id ? '* ' : ''}${course.name}`}
            </Menu.Item>
          ))}
        </Menu.SubMenu>
        <Menu.SubMenu title='Pacotes'>
          {productQuery.data?.map((product) => (
            <Menu.Item
              key={`product-${product.id}`}
              onClick={() =>
                onSimulateUser({
                  ...simulatedUser,
                  product: toggleArrayItem(simulatedUser.product, product.id),
                })
              }
            >
              {`${user.product.includes(product.id) ? '* ' : ''}${
                product.nome
              }`}
            </Menu.Item>
          ))}
        </Menu.SubMenu>
      </Menu>
    </Drawer>
  )
}
