import { Button, Divider } from 'antd'
import {
  appRoutesList,
  isRouteAllowedForUser,
} from '../components/routing/routingConfig'

import { Link } from 'react-router-dom'
import React from 'react'
import styled from 'styled-components'

const StyledContainer = styled.div`
  padding: 20px 0;
  text-align: center;

  p {
    color: #999;
  }
`

const GroupContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  a {
    margin: 10px;

    button {
      width: 120px !important;
      height: 120px !important;
      margin: 5px;

      .label {
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        max-width: 120px;
        height: 30px;
        margin: 0 auto;
        span {
          font-size: 12px;
          white-space: pre-wrap;
        }
      }

      .anticon {
        font-size: 50px;
        color: var(--theme-color-dark);
      }

      :hover {
        width: 130px !important;
        height: 130px !important;
        margin: 0px;

        .anticon {
          color: var(--theme-color-accent);
        }
      }

      @media (max-width: 600px) {
        width: 100px !important;
        height: 100px !important;

        :hover {
          width: 110px !important;
          height: 110px !important;
        }

        .anticon {
          font-size: 30px;
        }
      }
    }
  }
`

export default function HomePage(props) {
  const renderRoutes = (routes) =>
    routes.map(
      ({ icon: Icon, ...route }) =>
        !route.hidden &&
        isRouteAllowedForUser(route, props.user) && (
          <Link key={route.path} to={route.path}>
            <Button size='large' shape='circle'>
              <Icon />
              <div className='label'>
                <span>{route.title}</span>
              </div>
            </Button>
          </Link>
        )
    )

  return (
    <StyledContainer>
      <h1>Intelecto</h1>
      <p>Selecione um serviço abaixo.</p>
      <div>
        {props.user.group === 'student' || props.user.group === 'guest' ? (
          <GroupContainer>
            {appRoutesList.map((routeGroup) => renderRoutes(routeGroup.routes))}
          </GroupContainer>
        ) : (
          appRoutesList.map(
            (routeGroup) =>
              isRouteAllowedForUser(routeGroup, props.user) && (
                <div key={routeGroup.groupName}>
                  <Divider orientation='left'>{routeGroup.groupTitle}</Divider>
                  <GroupContainer>
                    {renderRoutes(routeGroup.routes)}
                  </GroupContainer>
                </div>
              )
          )
        )}
      </div>
    </StyledContainer>
  )
}
