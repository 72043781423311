import { pipe, stringMatch } from '../../../../libs/utils'

import { BaseType } from '../BaseType'
import React from 'react'
import { Select } from 'antd'
import styled from 'styled-components'

const StyledContainer = styled.div`
  display: flex;

  .ant-select {
    width: 100%;
  }
`

export class TokenType extends BaseType {
  static getDefaultTypeParams() {
    return BaseType.extendTypeParams({
      autoSelectSingleOption: this.fromParams(
        ({ optional, allowMultiple }) => !optional && !allowMultiple,
        ['optional', 'allowMultiple']
      ),
      defaultToFirst: this.fromParams(
        ({ tokens, autoSelectSingleOption }) =>
          autoSelectSingleOption && tokens?.length === 1,
        ['tokens', 'autoSelectSingleOption']
      ),
      valueDefault: this.fromParams(
        ({ getTokens, tokens, defaultToFirst, allowMultiple }) =>
          pipe(
            defaultToFirst && tokens?.length > 0
              ? Object.keys(getTokens(tokens)[0])[0]
              : '',
            (value) => (!allowMultiple ? value : value === '' ? [] : [value])
          ),
        ['getTokens', 'tokens', 'defaultToFirst', 'allowMultiple']
      ),
      format: (value, token) => token?.[value],
      formatEmpty: 'Nenhum',
      placeholder: 'Selecione...',
      tokens: this.required(),
      filterTokens: () => true,
      getTokens: this.fromParams(
        ({ filterTokens }) =>
          (tokens) =>
            tokens
              .filter(filterTokens)
              .map((t) => (typeof t === 'object' ? t : { [t]: t })),
        ['filterTokens']
      ),
      renderAddon: null,
      filters: this.fromParams(
        ({ tokens, getTokens }) =>
          getTokens(tokens).map((token) => ({
            text: Object.values(token)[0],
            value: Object.keys(token)[0],
          })),
        ['tokens', 'getTokens']
      ),
      validationTriggers: ['change'],
      allowMultiple: false,
      allowClear: this.fromParams('optional'),
      onClear: undefined,
    })
  }

  static isEmpty = (value) => !value || value.length === 0

  static renderer = (typeParams) => {
    const {
      value,
      onChange,
      inputRef,
      autoFocus,
      readOnly,
      disabled,
      optional,
      placeholder,
      formatEmpty,
      tokens,
      getTokens,
      renderAddon,
      allowMultiple,
      allowClear,
      onClear,
    } = typeParams

    const options = [
      {
        label: optional ? formatEmpty : placeholder,
        value: '',
        style: optional ? undefined : { display: 'none' },
      },
      ...getTokens(tokens).map((token) => {
        const [value, label] = Object.entries(token)[0]
        return { label, value }
      }),
    ]

    return (
      <StyledContainer>
        <Select
          ref={inputRef}
          options={options}
          value={value}
          onChange={onChange}
          readOnly={readOnly}
          disabled={disabled}
          mode={allowMultiple ? 'multiple' : undefined}
          allowClear={allowClear}
          onClear={onClear}
          showSearch
          filterOption={(input, option) => stringMatch(option.label, input)}
          autoFocus={autoFocus}
        />
        {renderAddon && renderAddon(typeParams)}
      </StyledContainer>
    )
  }

  static formatter = (
    value,
    { tokens, getTokens, format, formatEmpty, fullFieldPath }
  ) =>
    value
      ? format(value, findToken(getTokens(tokens), value, fullFieldPath))
      : formatEmpty

  static validators = (value, { tokens, getTokens, fullFieldPath }) => [
    !!findToken(getTokens(tokens), value, fullFieldPath),
  ]
}

const TOKEN_CACHE_TTL = 60 * 1000
function tokenMemoizer(finder) {
  const cache = {}

  return (tokens, key, cacheKey) => {
    cache[cacheKey] = cache[cacheKey] ?? {}

    if (
      cache[cacheKey][key] !== undefined &&
      Date.now() > cache[cacheKey].__meta.expiration
    ) {
      if (
        JSON.stringify(tokens) !== JSON.stringify(cache[cacheKey].__meta.tokens)
      ) {
        cache[cacheKey] = {}
      } else {
        cache[cacheKey].__meta = null
      }
    }

    if (!cache[cacheKey].__meta) {
      cache[cacheKey].__meta = {
        tokens,
        expiration: Date.now() + TOKEN_CACHE_TTL,
      }
    }

    if (cache[cacheKey][key] === undefined) {
      cache[cacheKey][key] = finder(tokens, key)
    }

    return cache[cacheKey][key]
  }
}

const findToken = tokenMemoizer((tokens, key) => {
  const token = tokens.find((t) => key in t)
  return token ?? null
})
