import { Alert, Col, Row, Tag } from 'antd'
import { CashIcon, HistoryIcon, WarningIcon } from '../../components/ui/Icons'
import {
  CurrencyType,
  DateTimeType,
  RefType,
  TextType,
} from '../../components/data/fieldtypes'

import CRUDPage from '../../components/data/CRUDPage'
import React from 'react'
import { appRoutes } from '../../components/routing/routingConfig'
import styled from 'styled-components'

const BalanceContainer = styled(Alert)`
  padding-top: 15px;
  padding-bottom: 15px;
  margin-bottom: 16px;
  font-size: 15px;
  height: 50px;
  text-align: center;
`

export default function Transactions(props) {
  const renderBalance = ({ balanceQuery }) => {
    const label = (c, l) =>
      `${l}: ${CurrencyType.format((balanceQuery?.data?.[c] || 0) / 100)}`
    return (
      <Row>
        <Col span={8}>
          <BalanceContainer
            message={
              <>
                <HistoryIcon className='ant-alert-icon' />
                {label('transferred', 'Transferido')}
              </>
            }
            type='info'
          />
        </Col>
        <Col span={8}>
          <BalanceContainer
            message={
              <>
                <CashIcon className='ant-alert-icon' />
                {label('available', 'Disponível')}
              </>
            }
            type='success'
          />
        </Col>
        <Col span={8}>
          <BalanceContainer
            message={
              <>
                <WarningIcon className='ant-alert-icon' />
                {label('pending', 'A receber')}
              </>
            }
            type='warning'
          />
        </Col>
      </Row>
    )
  }

  return (
    <CRUDPage
      user={props.user}
      history={props.history}
      params={props.match.params}
      resource='/transaction'
      dependencies={{
        balanceQuery: '/transaction/balance',
        userQuery: '/user',
      }}
      header={renderBalance}
      listColumns={({ userQuery }) => [
        {
          header: 'Transação',
          name: 'id',
          type: new TextType({ parseValueIn: (id) => String(id) }),
          render: ({ record }) => (
            <Tag
              color={record.status === 'refused' ? 'error' : 'success'}
              title={record.status_reason}
            >
              {record.id}
            </Tag>
          ),
        },
        {
          header: 'Data',
          name: 'date_created',
          type: DateTimeType,
        },
        {
          header: 'Usuário',
          name: 'user',
          type: new RefType({
            query: userQuery,
            display: 'name',
            formatEmpty: '',
            routeConfig: appRoutes.system.users,
          }),
        },
        {
          header: 'Nome Cartão',
          name: 'card_holder_name',
          type: TextType,
        },
        {
          header: 'Valor',
          name: 'amount',
          type: new CurrencyType({ parseValueIn: (amount) => amount / 100 }),
          render: ({ record }) =>
            `${CurrencyType.format(record.amount / 100)} em ${
              record.installments
            }x`,
        },
      ]}
      listOptions={{
        mapRecord: (record) => ({
          ...record,
          user: record?.metadata?.user_id ?? '',
        }),
        sortColumn: 'id',
        sortOrder: 'descend',
      }}
      crudOptions={{
        show: {
          create: false,
        },
        hook: {
          viewRecord: (id) => {
            window.open(`https://dashboard.pagar.me/#/transactions/${id}`)
            return false
          },
        },
      }}
    />
  )
}
