import { ArrayType, RefType, TextType } from '../../components/data/fieldtypes'

import CRUDPage from '../../components/data/CRUDPage'
import React from 'react'
import { appRoutes } from '../../components/routing/routingConfig'

export default function Teachers(props) {
  return (
    <CRUDPage
      user={props.user}
      history={props.history}
      params={props.match.params}
      resource='/teacher'
      dependencies={{
        subjectQuery: '/subject',
      }}
      schema={({ subjectQuery }) => ({
        name: new TextType({ unique: true }),
        id_subjects: new ArrayType({
          childrenType: new RefType({
            query: subjectQuery,
            display: 'name',
            sort: 'order',
            routeConfig: appRoutes.admin.subjects,
          }),
        }),
      })}
      listColumns={[
        {
          header: 'Professor',
          name: 'name',
        },
        {
          header: 'Matérias',
          name: 'id_subjects',
        },
      ]}
      formFields={[
        {
          name: 'name',
          label: 'Professor',
        },
        {
          name: 'id_subjects',
          label: 'Matérias',
        },
      ]}
    />
  )
}
