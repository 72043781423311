import {
  ArrayType,
  BooleanType,
  DateType,
  RefType,
  TextType,
  TimeRangeType,
  TokenType,
} from '../../components/data/fieldtypes'
import { Link, Redirect } from 'react-router-dom'

import CRUDPage from '../../components/data/CRUDPage'
import { GraphIcon } from '../../components/ui/Icons'
import IconButton from '../../components/ui/IconButton'
import React from 'react'
import { Tag } from 'antd'
import { appRoutes } from '../../components/routing/routingConfig'
import { stopPropagation } from '../../libs/utils'
import { useResponsiveness } from '../../libs/hooksLib'

export default function Exams(props) {
  const { isMobile } = useResponsiveness()

  const isGuestView = props.user.group === 'guest'
  const isStudentView = props.user.group === 'student'

  if ((isGuestView || isStudentView) && props.match.params.id) {
    return (
      <Redirect
        to={appRoutes.exams.liveExam.path.replace(':id', props.match.params.id)}
      />
    )
  }

  return (
    <CRUDPage
      user={props.user}
      history={props.history}
      params={props.match.params}
      resource='/exam'
      dependencies={{
        themeQuery: '/tema',
        ...(!isGuestView && { courseQuery: '/course' }),
      }}
      schema={({ themeQuery, courseQuery }) => ({
        name: new TextType({ unique: true }),
        course_exam: new BooleanType({
          valueDefault: true,
          formatTrue: 'Curso',
          formatFalse: 'Individual',
          displayTrue: 'Curso',
          displayFalse: 'Individual',
        }),
        ...(!isGuestView && {
          courses: new ArrayType({
            childrenType: new RefType({
              query: courseQuery,
              display: 'name',
              routeConfig: appRoutes.courses.root,
            }),
          }),
        }),
        date: new DateType({ displayShort: true }),
        time_range: new TimeRangeType({
          minHour: 8,
          maxHour: 22,
          minuteStep: 15,
        }),
        essay_theme: new RefType({
          query: themeQuery,
          filterFn: (theme) => theme.category === 'Simulado',
          displayFn: (theme) => `${theme.category} - ${theme.titulo}`,
          routeConfig: appRoutes.essays.themes,
          optional: true,
        }),
        status: new TokenType({
          tokens: [
            { hidden: 'Não-listado' },
            { scheduled: 'Agendado' },
            { in_progress: 'Em andamento' },
            { available: 'Aberto' },
            { processing: 'Em processamento' },
            { finished: 'Finalizado' },
            { archived: 'Arquivado' },
          ],
          valueDefault: 'hidden',
        }),
      })}
      listColumns={[
        {
          header: '',
          name: 'id',
          sort: false,
          filter: false,
          hidden: isGuestView || isStudentView,
          render: ({ value }) => (
            <Link
              to={appRoutes.exams.dashboard.path.replace(':id', value)}
              onClick={stopPropagation}
            >
              <GraphIcon />
            </Link>
          ),
        },
        {
          header: 'Simulado',
          name: 'name',
          ellipsis: true,
        },
        {
          header: 'Categoria',
          name: 'course_exam',
          hidden: isGuestView || (isStudentView && !props.user.hasExamService),
        },
        {
          header: 'Cursos',
          name: 'courses',
          hidden: isGuestView || isStudentView,
        },
        {
          header: 'Data',
          name: 'date',
          hidden: isGuestView,
        },
        {
          header: 'Hora',
          name: 'time_range',
          hidden: isGuestView || (isStudentView && isMobile),
        },
        {
          header: 'Status',
          name: 'status',
          align: 'right',
          hidden: (isGuestView || isStudentView) && isMobile,
          render: ({ value, formattedValue }) => (
            <Tag
              color={
                {
                  hidden: 'cyan',
                  scheduled: 'blue',
                  in_progress: 'error',
                  available: 'warning',
                  processing: 'warning',
                  finished: 'success',
                  archived: 'default',
                }[value]
              }
              title={formattedValue}
            >
              {formattedValue}
            </Tag>
          ),
        },
      ]}
      formFields={[
        {
          render: ({ record }) => (
            <Link
              to={appRoutes.exams.dashboard.path.replace(':id', record.id)}
              onClick={stopPropagation}
            >
              <IconButton.Graph type='primary' text='Ver Dashboard' />
            </Link>
          ),
          hiddenFn: CRUDPage.isEditing,
        },
        {
          name: 'name',
          label: 'Simulado',
        },
        {
          name: 'course_exam',
          label: 'Categoria',
          onChange: ({ value, record, setFields }) => {
            if (value === true && record.status === 'available') {
              setFields({ status: 'in_progress' })
            } else if (value === false) {
              setFields({
                courses: [],
                date: '',
                time_range: { start: '', end: '' },
                status:
                  record.status === 'archived'
                    ? 'archived'
                    : record.status === 'in_progress'
                    ? 'available'
                    : 'hidden',
              })
            }
          },
        },
        {
          name: 'courses',
          label: 'Cursos',
          dependencies: ['course_exam'],
          hiddenFn: ({ record }) => record.course_exam === false,
        },
        {
          name: 'date',
          label: 'Data',
          dependencies: ['course_exam'],
          hiddenFn: ({ record }) => record.course_exam === false,
        },
        {
          name: 'time_range',
          label: 'Hora',
          dependencies: ['course_exam'],
          hiddenFn: ({ record }) => record.course_exam === false,
        },
        {
          name: 'essay_theme',
          label: 'Tema da Redação',
        },
        {
          name: 'status',
          label: 'Status',
          dependencies: ['course_exam'],
          typeParamsFn: ({ record }) => ({
            filterTokens:
              record.course_exam === false
                ? (token) =>
                    ['hidden', 'available', 'archived'].includes(
                      Object.keys(token)[0]
                    )
                : (token) => Object.keys(token)[0] !== 'available',
          }),
        },
      ]}
      listOptions={{
        sortFunction: (record) =>
          [
            'in_progress',
            'available',
            'processing',
            'scheduled',
            'hidden',
            'finished',
            'archived',
          ].indexOf(record.status),
        filterFunction:
          !isGuestView && !isStudentView
            ? undefined
            : (record) =>
                record.status !== 'hidden' &&
                record.status !== 'archived' &&
                (record.courses.includes(props.user.course) ||
                  props.user.productData.some((p) =>
                    p.exams?.includes(record.id)
                  )),
      }}
      crudOptions={{
        show: {
          create: !isGuestView && !isStudentView,
        },
      }}
    />
  )
}
