import {
  BackIcon,
  CheckIcon,
  CrossIcon,
  DeleteIcon,
  DownloadIcon,
  EditIcon,
  FilterIcon,
  GraphIcon,
  LinkIcon,
  NextIcon,
  PauseIcon,
  PlusIcon,
  PreviousIcon,
  ReloadIcon,
  SearchIcon,
  SubmitIcon,
  UploadIcon,
} from './Icons'

import { Button } from 'antd'
import ConfirmPopover from './ConfirmPopover'
import { Link } from 'react-router-dom'
import React from 'react'
import { useAppContext } from '../../libs/hooksLib'

export default function IconButton({
  text,
  noText,
  routeTo,
  hidden = false,
  type = 'default',
  danger,
  block,
  disabled,
  icon,
  loading,
  onClick,
  inline = false,
  blockOnProcessing = false,
  className = '',
  ...props
}) {
  const { isProcessing } = useAppContext()

  const renderButton = () => (
    <Button
      className={className}
      type={type}
      danger={danger}
      block={block}
      disabled={disabled}
      icon={icon}
      loading={loading || (isProcessing && blockOnProcessing)}
      onClick={!danger && onClick}
      {...props}
    >
      {!noText && text}
    </Button>
  )

  const renderButtonContainer = () =>
    inline ? (
      <span className='clickable' onClick={!danger && onClick}>
        {icon}
      </span>
    ) : routeTo ? (
      <Link to={routeTo}>{renderButton()}</Link>
    ) : (
      renderButton()
    )

  return (
    !hidden &&
    (danger && !disabled ? (
      <ConfirmPopover confirmCallback={onClick} placement='bottom'>
        {renderButtonContainer()}
      </ConfirmPopover>
    ) : (
      renderButtonContainer()
    ))
  )
}

function buttonFactory({ text, icon, type, blockOnProcessing }) {
  return (props) => (
    <IconButton
      {...props}
      danger={
        props.danger !== undefined
          ? props.danger
          : type === 'danger'
          ? true
          : false
      }
      type={
        props.type !== undefined
          ? props.type
          : type === 'danger'
          ? 'default'
          : type
      }
      icon={props.icon !== undefined ? props.icon : icon}
      text={props.text !== undefined ? props.text : text}
      blockOnProcessing={blockOnProcessing}
    />
  )
}

IconButton.Save = buttonFactory({
  text: 'Salvar',
  icon: <CheckIcon />,
  type: 'primary',
  blockOnProcessing: true,
})
IconButton.Submit = buttonFactory({
  text: 'Enviar',
  icon: <SubmitIcon />,
  type: 'primary',
  blockOnProcessing: true,
})
IconButton.Cancel = buttonFactory({
  text: 'Cancelar',
  icon: <CrossIcon />,
  type: 'default',
})
IconButton.Edit = buttonFactory({
  text: 'Editar',
  icon: <EditIcon />,
  type: 'primary',
})
IconButton.Back = buttonFactory({
  text: 'Voltar',
  icon: <BackIcon />,
  type: 'default',
})
IconButton.New = buttonFactory({
  text: 'Novo',
  icon: <PlusIcon />,
  type: 'primary',
})
IconButton.Remove = buttonFactory({
  text: 'Remover',
  icon: <DeleteIcon />,
  type: 'danger',
})
IconButton.Okay = buttonFactory({
  text: 'OK',
  icon: <CheckIcon />,
  type: 'default',
})
IconButton.Download = buttonFactory({
  text: 'Baixar',
  icon: <DownloadIcon />,
  type: 'default',
})
IconButton.Upload = buttonFactory({
  text: 'Selecionar',
  icon: <UploadIcon />,
  type: 'default',
})
IconButton.Reload = buttonFactory({
  text: 'Atualizar',
  icon: <ReloadIcon />,
  type: 'default',
})
IconButton.Pause = buttonFactory({
  text: 'Pausar',
  icon: <PauseIcon />,
  type: 'default',
})
IconButton.Graph = buttonFactory({
  text: 'Estatísticas',
  icon: <GraphIcon />,
  type: 'default',
})
IconButton.Previous = buttonFactory({
  text: 'Anterior',
  icon: <PreviousIcon />,
  type: 'default',
})
IconButton.Next = buttonFactory({
  text: 'Próximo',
  icon: <NextIcon />,
  type: 'default',
})
IconButton.Link = buttonFactory({
  text: 'Link',
  icon: <LinkIcon />,
  type: 'default',
})
IconButton.ResetFilters = buttonFactory({
  text: 'Limpar filtros',
  icon: <FilterIcon />,
  type: 'default',
})
IconButton.Search = buttonFactory({
  text: 'Pesquisar',
  icon: <SearchIcon />,
  type: 'primary',
})
