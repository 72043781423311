import { DateTimeRangeType } from './DateTimeRangeType'
import { DateType } from './DateType'

export class DateRangeType extends DateType {
  static getDefaultTypeParams() {
    return DateType.extendTypeParams({
      rangePicker: true,
    })
  }

  static isEmpty = DateTimeRangeType.isEmpty
}
