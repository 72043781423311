import { If, WrapIf } from '../../../utils'
import React, { useEffect, useState } from 'react'
import { s3GetLink, s3Upload } from '../../../../libs/awsLib'
import { useAppContext, useMultiState } from '../../../../libs/hooksLib'

import { BaseType } from '../BaseType'
import IconButton from '../../../ui/IconButton'
import ImgCrop from 'antd-img-crop'
import { InboxIcon } from '../../../ui/Icons'
import Modal from 'antd/lib/modal/Modal'
import { Upload } from 'antd'
import { assert } from '../../../../libs/utils'
import styled from 'styled-components'

// TODO: remove this after migrating DB (also update isEmpty)
function convertLegacyValue(legacyValue) {
  return typeof legacyValue !== 'string'
    ? legacyValue
    : {
        timestamp: legacyValue.split(':')[0],
        displayName: legacyValue.split(':')[1],
      }
}

const FILETYPES = {
  any: {
    mimetype: undefined,
    extensions: [],
  },
  word: {
    mimetype:
      '.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    extensions: ['DOC', 'DOCX'],
  },
  pdf: {
    mimetype: 'application/pdf',
    extensions: ['PDF'],
  },
  image: {
    mimetype: 'image/jpeg,image/gif,image/png',
    extensions: ['JPEG', 'JPG', 'GIF', 'PNG'],
  },
}

const StyledUpload = styled(Upload)`
  & .ant-upload-select {
    display: ${({ readOnly, $hasMaxFiles }) =>
      readOnly || $hasMaxFiles ? 'none' : 'block'};
  }
  & > .ant-upload-list {
    margin-top: ${({ readOnly, $count }) =>
      readOnly && $count === 1 ? '-7px' : '0'};
  }
`

// TODO: find a way to share CSS between these two components
const StyledUploadDragger = styled(Upload.Dragger)`
  &.ant-upload-drag {
    display: ${({ readOnly, $hasMaxFiles }) =>
      readOnly || $hasMaxFiles ? 'none' : 'block'};
  }
  & > .ant-upload-list {
    margin-top: ${({ readOnly, $count }) =>
      readOnly && $count === 1 ? '-7px' : '0'};
  }
`

export class FileListType extends BaseType {
  static getDefaultTypeParams() {
    return BaseType.extendTypeParams({
      valueDefault: [],
      parseValueIn: this.fromParams(
        ({ maxFiles }) =>
          (value) => {
            if (!value) {
              return []
            } else {
              const arr = [convertLegacyValue(value)].flat()
              return arr.slice(arr.length - maxFiles, maxFiles)
            }
          },
        ['maxFiles']
      ),
      parseValueOut: this.fromParams(
        ({ maxFiles }) =>
          (value) => {
            return !value
              ? []
              : value.slice(value.length - maxFiles, value.length)
          },
        ['maxFiles']
      ),
      formatEmpty: 'Nenhum arquivo',
      type: 'any',
      maxFiles: Infinity,
      formatCountOnly: this.fromParams(
        ({ maxFiles }) => maxFiles > 1,
        ['maxFiles']
      ),
      displayMode: this.fromParams(
        ({ readOnly, type }) =>
          !readOnly ? 'picture' : type === 'image' ? 'picture-card' : 'text',
        ['readOnly', 'type']
      ),
      displayAs: 'input',
      showDropzone: true,
      allowCropImage: true,
      cropOptions: {},
      allowMultipleUpload: false, // TODO: support (value gets out of sync)
      allowFolderUpload: false, // TODO: support (need to parse it manually)
      validationTriggers: ['change'],
    })
  }

  static isEmpty = (value) =>
    !value || (Array.isArray(value) && value.length === 0)

  static comparator = (value1, value2) =>
    value1 === value2 ||
    (value1?.length === value2?.length &&
    value1.every(
      (_, i) =>
        value1[i] === value2[i] ||
        (value1[i].timestamp === value2[i].timestamp &&
          value1[i].displayName === value2[i].displayName)
    )
      ? 0
      : undefined)

  static renderer = ({
    value,
    onChange,
    readOnly,
    disabled,
    type,
    maxFiles,
    formatEmpty,
    displayMode,
    showDropzone,
    allowCropImage,
    cropOptions,
    // allowMultipleUpload,
    // allowFolderUpload,
  }) => {
    allowCropImage = allowCropImage && type === 'image'

    const appContext = useAppContext()
    const [fileTimestampMap, setFileTimestamp] = useMultiState({})
    const [fileList, setFileList] = useState()
    const [fileCount, setFileCount] = useState(0)
    const [previewImage, setPreviewImage] = useState(null)

    const getFileDescriptor = (file, isNew) => {
      return this._getFileDescriptor(file, {
        timestamp: isNew ? Date.now() : fileTimestampMap[file.uid] || file.uid,
      })
    }

    // TODO: cancel effect when needed to avoid console errors
    useEffect(() => {
      const getLinks = async () => {
        const links = await Promise.all(
          value.map((fileDescriptor) => this._getFileLink(fileDescriptor))
        )
        setFileList(
          value.map((fileDescriptor, index) => ({
            uid: fileDescriptor.timestamp,
            name: fileDescriptor.displayName,
            status: 'done',
            url: links[index],
          }))
        )
        setFileCount(value.length)
      }
      if (value) {
        getLinks()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onAddFile = async ({ file, onProgress, onSuccess, onError }) => {
      const fileDescriptor = getFileDescriptor(file, true)
      setFileTimestamp(file.uid, fileDescriptor.timestamp)
      onChange([...value, fileDescriptor])
      if (maxFiles !== 1 || value.length !== 1) {
        setFileCount((count) => count + 1)
      }

      if (type === 'any' || this._isFileValid(file.name, type)) {
        await this.uploadFile(file, file.name, fileDescriptor, onProgress)
        if (maxFiles === 1 && value.length === 1) {
          await this._deleteFile(value[0])
        }
        onSuccess()
      } else {
        onError()
      }
    }

    const onRemoveFile = async (file) => {
      const fileDescriptor = getFileDescriptor(file)
      onChange(value.filter((fd) => fd.timestamp !== fileDescriptor.timestamp))
      setFileCount((count) => count - 1)
      await this._deleteFile(fileDescriptor)
    }

    const onDownloadFile = async (file) => {
      if (!fileTimestampMap[file.uid]) {
        const fileDescriptor = getFileDescriptor(file)
        await this._downloadFile(fileDescriptor)
      }
    }

    const onPreviewFile = (file) => {
      if (this._isFileValid(file.name, 'image')) {
        setPreviewImage({ url: file.url, name: file.name })
      } else {
        onDownloadFile(file)
      }
    }

    const hasMaxFiles = maxFiles > 1 && fileCount >= maxFiles
    const uploadProps = {
      ...(fileList ? { defaultFileList: fileList } : {}),
      customRequest: onAddFile,
      onRemove: onRemoveFile,
      onPreview: onPreviewFile,
      onDownload: onDownloadFile,
      onChange: ({ file }) => {
        if (file.status === 'done') {
          appContext.stopProcessing()
        } else {
          appContext.startProcessing()
        }
      },
      listType: displayMode,
      showUploadList: {
        showPreviewIcon: true,
        showRemoveIcon: !readOnly,
        showDownloadIcon: true,
      },
      disabled: disabled,
      readOnly: readOnly,
      accept: FILETYPES[type].mimetype,
      // multiple: allowMultipleUpload,
      // directory: allowFolderUpload,
      maxCount: maxFiles,
      $count: fileCount ?? 0,
      $hasMaxFiles: hasMaxFiles,
    }
    const cropProps = {
      aspect: 1,
      shape: 'rect', // rect or round
      grid: false,
      quality: 1,
      fillColor: 'white',
      zoom: true,
      rotate: true,
      minZoom: 1,
      maxZoom: 3,
      modalTitle: 'Editar imagem',
      ...cropOptions,
    }

    return (
      <If
        condition={readOnly && (!fileList || fileList.length === 0)}
        then={formatEmpty}
        else={
          <If condition={fileList}>
            <WrapIf
              condition={allowCropImage}
              wrapper={ImgCrop}
              wrapperProps={cropProps}
            >
              <If
                condition={showDropzone}
                then={
                  <StyledUploadDragger {...uploadProps}>
                    <p className='ant-upload-drag-icon'>
                      <InboxIcon />
                    </p>
                    <p className='ant-upload-text'>
                      Clique ou arraste um arquivo aqui para enviar
                    </p>
                    <p className='ant-upload-hint'>
                      Tipos de arquivo válidos:{' '}
                      {FILETYPES[type].extensions.join(', ') || 'todos'}
                    </p>
                  </StyledUploadDragger>
                }
                else={
                  <StyledUpload {...uploadProps}>
                    <IconButton.Upload disabled={disabled} hidden={readOnly} />
                  </StyledUpload>
                }
              />
            </WrapIf>
            <Modal
              visible={!!previewImage}
              title={previewImage?.name}
              footer={null}
              onCancel={() => setPreviewImage(null)}
            >
              <img alt='' style={{ width: '100%' }} src={previewImage?.url} />
            </Modal>
          </If>
        }
      />
    )
  }

  // TODO: inline download icon
  static formatter = (value, { formatCountOnly }) =>
    formatCountOnly
      ? `${value.length} ${value.length === 1 ? 'arquivo' : 'arquivos'}`
      : value.map((fd) => fd.displayName).join(', ')

  static validators = (value, { type, maxFiles }) => {
    const exts = FILETYPES[type].extensions
    return [
      assert(
        type === 'any' ||
          value.every((fd) => this._isFileValid(fd.displayName, type)),
        [
          'O campo %% deve conter ',
          maxFiles > 1 ? 'apenas arquivos ' : 'um arquivo ',
          exts[0],
          ...exts.slice(1, exts.length - 2).map((ext) => `, ${ext}`),
          exts.length === 1 ? '' : ` ou ${exts[exts.length - 1]}`,
        ].join('')
      ),
    ]
  }

  static _getFileDescriptor(file, override = {}) {
    return {
      timestamp: Date.now(),
      displayName: file.name,
      ...override,
    }
  }

  static _isFileValid(name, type) {
    return FILETYPES[type].extensions.some((ext) =>
      name.toUpperCase().endsWith(`.${ext}`)
    )
  }

  static _getInternalName(fileDescriptor) {
    return `${fileDescriptor.timestamp}-${fileDescriptor.displayName}`
  }

  static _getFileLink(fileDescriptor) {
    return s3GetLink(this._getInternalName(fileDescriptor))
  }

  static async _downloadFile(fileDescriptor) {
    const link = await this._getFileLink(fileDescriptor)
    window.open(link)
  }

  static async downloadFile(file) {
    return this._downloadFile(convertLegacyValue([file].flat()[0]))
  }

  static uploadFile(file, name, fileDescriptor, onProgress = () => {}) {
    fileDescriptor =
      fileDescriptor ?? this._getFileDescriptor(file, { displayName: name })
    return s3Upload(
      file,
      this._getInternalName(fileDescriptor),
      name,
      (progress) =>
        onProgress({ percent: (100 * progress.loaded) / progress.total })
    )
  }

  static async _deleteFile(fileDescriptor) {
    // TODO: flag file to be deleted from S3 after confirmation (onSave)
    console.log(fileDescriptor)
  }
}
