import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { querystring } from '../../libs/utils'

export default function UnauthenticatedRoute({
  component: C,
  appProps,
  ...rest
}) {
  const redirect = querystring('redirect')
  return (
    <Route
      {...rest}
      render={(props) =>
        !appProps.isAuthenticated ? (
          <C {...props} {...appProps} />
        ) : (
          <Redirect
            to={redirect === '' || redirect === null ? '/' : redirect}
          />
        )
      }
    />
  )
}
