import { Col, Progress, Row, Tag } from 'antd'
import { FileDoneIcon, LockIcon, UnlockIcon } from '../../ui/Icons'

import React from 'react'
import styled from 'styled-components'

const StyledRow = styled(Row)`
  line-height: 40px;
  &:hover {
    background-color: var(--theme-color-light);
  }
  .progress {
    margin: 10px 0 0 0;
  }
  .icon {
    margin-right: 5px;
    font-size: 18px;
    cursor: pointer;
    &:hover {
      color: var(--theme-color-dark);
    }
  }
`

export default function ExamDashboardRow({
  student,
  answer,
  onDownloadEssay,
  onSetExamStatus,
}) {
  const answerCount = answer ? answer.hitCount + answer.missCount : 0
  const formatNumber = (n) => String(n).padStart(2, '0')
  return (
    <StyledRow>
      <Col span={10}>
        <span>{student.name}</span>
        <span className='pull-right'>
          {answer && answer.status === 'delivered' ? (
            <LockIcon
              className='icon'
              title='Reabrir simulado'
              onClick={() => onSetExamStatus(answer.id, 'in_progress')}
            />
          ) : (
            <UnlockIcon
              className='icon'
              title='Fechar simulado'
              onClick={() => onSetExamStatus(answer.id, 'delivered')}
            />
          )}
          {answer && answer.essay && (
            <FileDoneIcon
              className='icon'
              title={answer.essay}
              onClick={() => onDownloadEssay(student.name, answer.essay)}
            />
          )}
          <Tag
            color={
              !answer
                ? 'error'
                : answer.status === 'delivered'
                ? 'success'
                : 'default'
            }
          >
            {`${formatNumber(answer?.hitCount ?? 0)} / ${formatNumber(
              answer?.count ?? 0
            )}`}
          </Tag>
        </span>
      </Col>
      <Col span={14}>
        {!answer ? (
          <Progress percent={0} status='exception' />
        ) : (
          <Progress
            percent={(100 * answerCount) / answer.maxCount}
            status={answer.status === 'delivered' ? 'success' : 'normal'}
            strokeColor='#ff4d4f'
            success={{ percent: (100 * answer.hitCount) / answer.maxCount }}
            format={() =>
              answerCount === 0
                ? 0
                : `${Math.round((100 * answer.hitCount) / answerCount)}%`
            }
          />
        )}
      </Col>
    </StyledRow>
  )
}
