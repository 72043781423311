import React, { useState } from 'react'

import IconButton from '../components/ui/IconButton'
import PageWrapper from '../components/ui/PageWrapper'
import { TextAreaType } from '../components/data/fieldtypes'
import VerticalSpace from '../components/ui/VerticalSpace'
import styled from 'styled-components'

// TODO: submit message & send email

const StyledText = styled.div`
  color: #999;
`

export default function Contact() {
  const [comment, setComment] = useState('')
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  function submitComment() {
    setIsLoading(true)
    setIsSubmitted(true)
    setTimeout(() => {
      setIsLoading(false)
    }, 1000)
  }

  return (
    <PageWrapper isLoading={isLoading}>
      {!isSubmitted ? (
        <VerticalSpace>
          <StyledText>
            Tem alguma dúvida, sugestão ou reclamação? Escreva no campo abaixo e
            clique em Enviar.
          </StyledText>
          {TextAreaType.render(comment, setComment, { fixedRows: 10 })}
          <IconButton.Submit block onClick={submitComment} />
        </VerticalSpace>
      ) : (
        <StyledText>Obrigado! Em breve entraremos em contato.</StyledText>
      )}
    </PageWrapper>
  )
}
