import {
  appRoutes,
  appRoutesList,
  isRouteAllowedForUser,
  useRouteConfig,
} from './routingConfig'

import { Menu } from 'antd'
import React from 'react'

export default function AppMenu({ user, onNavigate }) {
  const routeConfig = useRouteConfig()

  const renderRoutes = (routes) =>
    routes.map(
      ({ icon: Icon, ...route }) =>
        !route.hidden &&
        isRouteAllowedForUser(route, user) && (
          <Menu.Item
            key={route.path}
            icon={<Icon />}
            onClick={() => onNavigate(route.path)}
          >
            {route.title}
          </Menu.Item>
        )
    )

  return (
    <Menu
      selectedKeys={
        !routeConfig
          ? []
          : routeConfig.highlightMenu
          ? [appRoutes[routeConfig.groupName][routeConfig.highlightMenu].path]
          : [routeConfig.path]
      }
      mode='horizontal'
      className='pull-left nav-menu'
    >
      {user.group === 'student'
        ? appRoutesList.map((routeGroup) => renderRoutes(routeGroup.routes))
        : appRoutesList.map(
            (routeGroup) =>
              isRouteAllowedForUser(routeGroup, user) && (
                <Menu.SubMenu
                  key={routeGroup.groupName}
                  title={routeGroup.groupTitle}
                  className={
                    routeConfig?.groupName === routeGroup.groupName
                      ? 'selected'
                      : ''
                  }
                >
                  {renderRoutes(routeGroup.routes)}
                </Menu.SubMenu>
              )
          )}
    </Menu>
  )
}
