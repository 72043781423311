import React from 'react'

export function WrapIf({
  condition,
  wrapperFn,
  wrapper: Wrapper,
  wrapperProps = {},
  children,
}) {
  return !condition ? (
    children ?? null
  ) : wrapperFn ? (
    wrapperFn(children)
  ) : (
    <Wrapper {...wrapperProps}>{children}</Wrapper>
  )
}
