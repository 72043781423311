import {
  assert,
  fn,
  getNested,
  mapObject,
  throwError,
} from '../../../libs/utils'

import React from 'react'

export class BaseType {
  /* Child type interface */

  static getDefaultTypeParams() {
    return {
      getRecord: fn({}),
      getDataList: fn([]),
      user: null,
      fieldPath: '',
      fullFieldPath: this.fromParams('fieldPath'),
      fieldKey: this.fromParams('fieldPath'),
      fieldLabel: this.fromParams('fieldPath'),
      isSelfControlled: false,
      isFormControlled: false,
      valueDefault: this.defaultRequired(),
      inputRef: undefined, // TODO: implement for all types
      autoFocus: false, // TODO: implement for all types
      unique: false,
      uniqueWith: this.fromParams(
        ({ unique }) => (unique ? [] : null),
        ['unique']
      ),
      duplicateError: null,
      readOnly: false,
      disabled: false,
      optional: false,
      displayAs: 'text', // "text", "input", "custom"
      formatAsComponent: false,
      formatEmpty: null,
      parseValueIn: (value) => value,
      parseValueOut: (value) => value,
      filters: null, // "custom" or array of tokens
      customFilterComparator: null,
      validationTriggers: ['change', 'blur'],
      customValidators: fn([]),
    }
  }

  static isEmpty = (value) => value === undefined

  static shouldUpdate = (prev, next) => this.compare(prev, next) !== 0

  static renderer = ({ value, onChange, onBlur, ...typeParams }) => {
    return <></>
  }

  static displayer = ({ value, ...typeParams }) =>
    throwError('Displayer not implemented!')
  static formatter = (value, typeParams) => value
  static filterRenderer = (
    filters,
    onChangeFilters,
    onConfirm,
    focusRef,
    typeParams
  ) => null
  static filterComparator = (value, filter, typeParams) => value === filter
  static validators = (value, typeParams) => []
  static comparator = (value1, value2, typeParams) =>
    value1 === value2
      ? 0
      : value1 > value2
      ? 1
      : value1 < value2
      ? -1
      : undefined

  /* Static API */

  static required = () => this._requiredSymbol
  static defaultRequired = () => this._defaultRequiredSymbol
  static fromParams = (fnOrField, dependencies) => ({
    [this._fromParamsSymbol]: fnOrField,
    [this._dependenciesSymbol]:
      dependencies ??
      (typeof fnOrField === 'string'
        ? [fnOrField]
        : throwError(
            'fromParams deve ser chamado com um array de dependências'
          )),
  })

  static extendTypeParams(typeParams = {}) {
    return this._parseTypeParams(typeParams, { isExtending: true })
  }

  static filterParams(typeParams = {}) {
    return mapObject(
      this.getDefaultTypeParams(),
      (param) => typeParams[param],
      undefined,
      true
    )
  }

  static getParams(overrideParams = {}) {
    return this._parseTypeParams(overrideParams)
  }

  static withParams(typeParams = {}) {
    return new this(typeParams)
  }

  static getValidationRules(label, triggers, overrideParams = {}) {
    const defaultError = 'Valor inválido'
    const callbacks = {
      change: ['onChangeValidate'],
      blur: ['onBlurValidate'],
      save: [],
    }
    return triggers.map((trigger) => ({
      validateTrigger: callbacks[trigger],
      validator: async (_, value) => {
        try {
          return (await this.validate(value, trigger, overrideParams)) === true
            ? Promise.resolve()
            : Promise.reject(defaultError)
        } catch (error) {
          if (typeof error === 'string') {
            return Promise.reject(
              error ? error.replace('%%', label ?? '') : defaultError
            )
          } else {
            console.error(error)
            throw error
          }
        }
      },
    }))
  }

  static _Input({
    _type: type,
    id,
    value,
    onChange,
    onBlur = fn(),
    onChangeValidate = fn(),
    onBlurValidate = fn(),
    useDisplayer = false,
    ...overrideParams
  }) {
    const [typeParams, parsedValue, parsedOnChange] = type._parseParams(
      overrideParams,
      value,
      onChange,
      onChangeValidate
    )
    return (
      <div
        key={typeParams.fieldKey}
        id={typeParams.fullFieldPath}
        className={type.name}
      >
        {!useDisplayer
          ? type.renderer({
              value: parsedValue,
              onChange: parsedOnChange,
              onBlur: () => {
                onBlur()
                if (typeParams.validationTriggers.includes('blur')) {
                  onBlurValidate()
                }
              },
              ...typeParams,
            })
          : typeParams.displayAs === 'text'
          ? type.format(value, typeParams)
          : typeParams.displayAs === 'input'
          ? type.renderer({ value: parsedValue, ...typeParams })
          : typeParams.displayAs === 'custom'
          ? type.displayer({ value: parsedValue, ...typeParams })
          : throwError(
              `Invalid displayAs param value: ${typeParams.displayAs}`
            )}
      </div>
    )
  }

  static render(
    valueOrGetValue,
    onChange,
    overrideParams = {},
    useDisplayer = false
  ) {
    const value =
      valueOrGetValue instanceof Function ? valueOrGetValue() : valueOrGetValue
    return (
      <this._Input
        _type={this}
        value={value}
        onChange={onChange}
        useDisplayer={useDisplayer}
        {...overrideParams}
      />
    )
  }

  static display(value, overrideParams = {}) {
    const typeParams = this._parseTypeParams(overrideParams)
    return this.render(
      value,
      undefined,
      { ...typeParams, readOnly: true },
      true
    )
  }

  static format(value, overrideParams = {}) {
    const [typeParams, parsedValue] = this._parseParams(overrideParams, value)
    return this.isEmpty(value, typeParams)
      ? typeParams.formatEmpty
      : this.formatter(parsedValue, typeParams)
  }

  static renderFilter(
    filters,
    onChangeFilters,
    onConfirm,
    focusRef,
    overrideParams = {}
  ) {
    const typeParams = this._parseTypeParams(overrideParams)
    return this.filterRenderer(
      filters,
      onChangeFilters,
      onConfirm,
      focusRef,
      typeParams
    )
  }

  static compareFilter(value, filter, overrideParams = {}) {
    const [typeParams, parsedValue] = this._parseParams(overrideParams, value)
    return typeParams.customFilterComparator
      ? typeParams.customFilterComparator(parsedValue, filter, typeParams)
      : this.filterComparator(parsedValue, filter, typeParams)
  }

  static async validate(value, validationTrigger, overrideParams = {}) {
    const [typeParams, parsedValue] = this._parseParams(overrideParams, value)
    const composedUniqueFields =
      Array.isArray(typeParams.uniqueWith) || !!typeParams.uniqueWith
        ? [typeParams.fullFieldPath, ...[typeParams.uniqueWith].flat()]
        : null
    const record = typeParams.getRecord()
    return [
      assert(
        !typeParams.validationTriggers.includes(validationTrigger) ||
          typeParams.optional ||
          !this.isEmpty(value, typeParams),
        'O campo %% é obrigatório'
      ),
      assert(
        validationTrigger !== 'save' ||
          typeParams.optional ||
          !composedUniqueFields ||
          !typeParams
            .getDataList()
            .some(
              (listRecord) =>
                listRecord.id !== record.id &&
                composedUniqueFields.every(
                  (uniqueField) =>
                    getNested(record, uniqueField) ===
                    getNested(listRecord, uniqueField)
                )
            ),
        typeParams.duplicateError ??
          'O campo %% deve ser único, mas já existe um registro com este valor'
      ),
      ...(typeParams.optional && this.isEmpty(value, typeParams)
        ? []
        : [
            ...(await this.validators(parsedValue, {
              validationTrigger,
              ...typeParams,
            })),
            ...(await typeParams.customValidators(parsedValue, {
              validationTrigger,
              ...typeParams,
            })),
          ]),
    ].every((condition) => condition === true)
  }

  static compare(value1, value2, overrideParams = {}) {
    const typeParams = this._parseTypeParams(overrideParams)
    const parsedValue1 = this._parseValue(value1, typeParams)
    const parsedValue2 = this._parseValue(value2, typeParams)
    return this.comparator(parsedValue1, parsedValue2, typeParams)
  }

  /* Internal logic */

  static _requiredSymbol = Symbol('required')
  static _defaultRequiredSymbol = Symbol('defaultRequired')
  static _fromParamsSymbol = Symbol('fromParams')
  static _dependenciesSymbol = Symbol('dependencies')

  static _assertReqdProp(param, paramName) {
    if (param === undefined) {
      throw new Error(`Missing '${paramName}' prop for type ${this.name}`)
    }
  }

  static _parseTypeParams(
    overrideParams,
    { isExtending = false, isConstructing = false } = {}
  ) {
    const defaultParams = this.getDefaultTypeParams()
    const typeParams = { ...defaultParams, ...overrideParams }

    if (!isExtending && this !== BaseType) {
      Object.keys(BaseType.getDefaultTypeParams()).forEach((paramName) => {
        if (!Object.keys(defaultParams).includes(paramName)) {
          throw new Error(
            `Class ${this.name} does not extend its parent default type params`
          )
        }
      })
    }

    Object.keys(typeParams).forEach((paramName) => {
      if (isExtending) {
        if (typeParams[paramName] === this.defaultRequired()) {
          throw new Error(
            `Missing '${paramName}' default for type ${this.name}`
          )
        }
      } else {
        if (!Object.keys(defaultParams).includes(paramName)) {
          throw new Error(
            `Invalid parameter '${paramName}' for class ${this.name}`
          )
        }
        if (typeParams[paramName] === this.required()) {
          throw new Error(`Missing '${paramName}' param for type ${this.name}`)
        }
        if (!isExtending && !isConstructing) {
          this._populateDependencies(typeParams, paramName)
        }
      }
    })

    return typeParams
  }

  static _parseParams(overrideParams, value, onChange, onChangeValidate) {
    const typeParams = this._parseTypeParams(overrideParams)
    const parsedValue = this._parseValue(value, typeParams)
    const parsedOnChange = this._parseOnChange(
      onChange,
      onChangeValidate,
      typeParams
    )
    return [typeParams, parsedValue, parsedOnChange]
  }

  static _parseValue(value, { parseValueIn, valueDefault }) {
    return value === undefined ? valueDefault : parseValueIn(value)
  }

  static _parseOnChange(
    onChange,
    onChangeValidate,
    { parseValueOut, validationTriggers }
  ) {
    return !onChange
      ? undefined
      : (newValue) => {
          onChange(parseValueOut(newValue))
          if (validationTriggers.includes('change')) {
            onChangeValidate()
          }
        }
  }

  static _populateDependencies(typeParams, paramName, stack = []) {
    if (stack.includes(paramName)) {
      throw new Error(
        `Circular dependency on type params for type ${this.name}: ${stack},${paramName}`
      )
    }
    const value = typeParams[paramName]
    if (value?.hasOwnProperty(this._fromParamsSymbol)) {
      value[this._dependenciesSymbol].forEach((dependency) =>
        this._populateDependencies(typeParams, dependency, [
          ...stack,
          paramName,
        ])
      )
      const filteredParams = value[this._dependenciesSymbol].reduce(
        (params, dependency) => ({
          ...params,
          [dependency]: typeParams[dependency],
        }),
        {}
      )
      typeParams[paramName] =
        typeof value[this._fromParamsSymbol] === 'function'
          ? value[this._fromParamsSymbol](filteredParams)
          : typeParams[value[this._fromParamsSymbol]]
    }
  }

  /* Instance handlers */

  constructor(overrideParams = {}) {
    this.storedTypeParams = this.constructor._parseTypeParams(overrideParams, {
      isConstructing: true,
    })
  }

  get name() {
    return this.constructor.name
  }

  staticProxy(methodName, countArgsBeforeParams, ...args) {
    if (countArgsBeforeParams !== null) {
      args[countArgsBeforeParams] = {
        ...this.storedTypeParams,
        ...(args[countArgsBeforeParams] ?? {}),
      }
    }
    return this.constructor[methodName](...args)
  }

  isEmpty = (...args) => this.staticProxy('isEmpty', 1, ...args)
  shouldUpdate = (...args) => this.staticProxy('shouldUpdate', 2, ...args)
  render = (...args) => this.staticProxy('render', 2, ...args)
  display = (...args) => this.staticProxy('display', 1, ...args)
  format = (...args) => this.staticProxy('format', 1, ...args)
  renderFilter = (...args) => this.staticProxy('renderFilter', 4, ...args)
  compareFilter = (...args) => this.staticProxy('compareFilter', 2, ...args)
  validate = (...args) => this.staticProxy('validate', 2, ...args)
  compare = (...args) => this.staticProxy('compare', 2, ...args)
  getParams = (...args) => this.staticProxy('getParams', 0, ...args)
  withParams = (...args) => this.staticProxy('withParams', 0, ...args)
  getValidationRules = (...args) =>
    this.staticProxy('getValidationRules', 2, ...args)
  getDefaultTypeParams = (...args) =>
    this.staticProxy('getDefaultTypeParams', null, ...args)
}
