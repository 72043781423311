import {
  ArrayType,
  BooleanType,
  CurrencyType,
  RefType,
  TextAreaType,
  TextType,
  TokenType,
} from '../../components/data/fieldtypes'

import CRUDPage from '../../components/data/CRUDPage'
import React from 'react'
import { appRoutes } from '../../components/routing/routingConfig'

export default function ServicePacks(props) {
  return (
    <CRUDPage
      user={props.user}
      history={props.history}
      params={props.match.params}
      resource='/produto'
      dependencies={{
        themeQuery: '/tema',
        examQuery: '/exam',
      }}
      schema={({ themeQuery, examQuery }) => ({
        category: new TokenType({
          tokens: ['CTI/ETEC', 'ENEM'],
        }),
        ordem: new TextType(),
        nome: new TextType({ unique: true }),
        desc: new TextAreaType(),
        preco: new CurrencyType(),
        themes: new ArrayType({
          childrenType: new RefType({
            query: themeQuery,
            displayFn: (t) => `${t.category} - ${t.titulo}`,
            filterFn: (t) => t.category !== 'Simulado',
            routeConfig: appRoutes.essays.themes,
            showNewLink: false,
          }),
          formatArray: (themes) => themes.length + ' temas',
          optional: true,
        }),
        exams: new ArrayType({
          childrenType: new RefType({
            query: examQuery,
            display: 'name',
            filterFn: (e) => e.course_exam === false,
            routeConfig: appRoutes.exams.root,
            showNewLink: false,
          }),
          formatArray: (exams) => exams.length + ' simulados',
          optional: true,
        }),
        disabled: new BooleanType(),
      })}
      listColumns={[
        {
          header: 'Categoria',
          name: 'category',
        },
        {
          header: 'Ordem',
          name: 'ordem',
        },
        {
          header: 'Pacote',
          name: 'nome',
        },
        {
          header: 'Preço',
          name: 'preco',
        },
        {
          header: 'Desabilitado',
          name: 'disabled',
        },
      ]}
      formFields={[
        {
          name: 'category',
          label: 'Categoria',
        },
        {
          name: 'ordem',
          label: 'Ordem',
        },
        {
          name: 'nome',
          label: 'Pacote',
        },
        {
          name: 'desc',
          label: 'Descrição',
        },
        {
          name: 'preco',
          label: 'Preço',
        },
        {
          name: 'themes',
          label: 'Temas de Redação',
        },
        {
          name: 'exams',
          label: 'Simulados',
        },
        {
          name: 'disabled',
          label: 'Desabilitado',
        },
      ]}
      listOptions={{
        sortFunction: (p) => p.category + p.ordem,
      }}
    />
  )
}
