import { CopyIcon, CrossIcon, DeleteIcon } from '../../../../ui/Icons'
import React, { useRef, useState } from 'react'

import { Card } from 'antd'
import IconButton from '../../../../ui/IconButton'
import SimpleModal from '../../../../ui/SimpleModal'
import styled from 'styled-components'

// import EquationEmbed from "./EquationEmbed";

const embeds = [
  // TODO: embeds not working after saving
  // EquationEmbed,
]

const StyledCard = styled(Card)`
  border: 1px solid #d9d9d9;
  ${({ $disabled }) => $disabled && 'background-color: #f5f5f5;'}
  & .ant-card-body {
    padding: 5px 5px 5px 36px;
  }
`

const EmbedContainer = styled.div`
  width: fit-content;
  padding-left: 20px;
  padding-right: 20px;
  border: 1px solid ${({ $selected }) => ($selected ? '#ccc' : '#fff')};
  background: transparent;
`

export default function EmbedController({ disabled, children }) {
  const [embedBeingEdited, setEmbedBeingEdited] = useState(null)
  const [copied, setCopied] = useState(false)
  const editorCallbacks = useRef(null)

  return (
    <StyledCard $disabled={disabled}>
      {embeds.map((embed, index) => {
        const Icon = embed.icon
        return (
          <IconButton
            key={index}
            icon={<Icon />}
            style={{ float: 'right' }}
            onClick={() => setEmbedBeingEdited(embed)}
            disabled={disabled}
          />
        )
      })}
      <SimpleModal
        show={embedBeingEdited !== null}
        title={embedBeingEdited?.editorTitle}
        allowClose={true}
        onCancel={() => setEmbedBeingEdited(null)}
        buttons={
          <>
            <IconButton
              icon={<DeleteIcon />}
              text='Limpar'
              type='default'
              onClick={() => editorCallbacks.current.setValue('')}
            />
            <IconButton
              icon={<CopyIcon />}
              text={copied ? 'Copiado!' : 'Copiar'}
              type={copied ? 'default' : 'primary'}
              onClick={() => {
                navigator.clipboard.writeText(
                  editorCallbacks.current.getValue()
                )
                setCopied(true)
              }}
            />
            <IconButton
              icon={<CrossIcon />}
              text='Fechar'
              type={copied ? 'primary' : 'default'}
              onClick={() => setEmbedBeingEdited(null)}
            />
          </>
        }
      >
        {embedBeingEdited !== null && (
          <embedBeingEdited.Editor
            editorCallbacks={editorCallbacks}
            onChange={() => setCopied(false)}
          />
        )}
      </SimpleModal>
      {children}
    </StyledCard>
  )
}

EmbedController.getEmbeds = () =>
  embeds.map((embed) => ({
    title: embed.title,
    keywords: embed.keywords,
    icon: embed.icon,
    matcher: (href) => href.match(new RegExp(`^${embed.linkPrefix}(.*)$`)),
    component: ({ attrs, isSelected }) => (
      <EmbedContainer $selected={isSelected}>
        <embed.Display value={attrs.matches[1]} />
      </EmbedContainer>
    ),
  }))
