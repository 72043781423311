export const dictionary = {
  addColumnAfter: 'Inserir coluna à direita',
  addColumnBefore: 'Inserir coluna à esquerda',
  addRowAfter: 'Inserir linha abaixo',
  addRowBefore: 'Inserir linha acima',
  alignCenter: 'Centralizar',
  alignLeft: 'Alinhar à esquerda',
  alignRight: 'Alinhar à direita',
  bulletList: 'Lista não ordenada',
  checkboxList: 'Lista selecionável',
  codeBlock: 'Bloco de código',
  codeCopied: 'Copiado para a área de transferência',
  codeInline: 'Código',
  createLink: 'Criar link',
  createLinkError: 'Desculpe, ocorreu um erro na criação do link',
  createNewDoc: 'Criar um novo documento',
  deleteColumn: 'Deletar coluna',
  deleteRow: 'Deletar linha',
  deleteTable: 'Deletar tabela',
  deleteImage: 'Deletar imagem',
  alignImageLeft: 'Flutuar à esquerda',
  alignImageRight: 'Flutuar à direita',
  alignImageDefault: 'Centralizar',
  em: 'Itálico',
  embedInvalidLink: 'Desculpe, link inválido para este recurso',
  findOrCreateDoc: 'Encontrar um criar um documento...',
  h1: 'Título grande',
  h2: 'Título médio',
  h3: 'Título pequeno',
  heading: 'Título',
  hr: 'Divisor',
  image: 'Imagem',
  imageUploadError: 'Desculpe, ocorreu um erro no envio da imagem',
  info: 'Informação',
  infoNotice: 'Informação',
  link: 'Link',
  linkCopied: 'Link copiado para a área de transferência',
  mark: 'Grifado',
  newLineEmpty: "Digite '/' para inserir...",
  newLineWithSlash: 'Continue digitando para filtrar...',
  noResults: 'Nenhum comando encontrado',
  openLink: 'Abrir link',
  orderedList: 'Lista ordenada',
  pasteLink: 'Colar um link...',
  pasteLinkWithTitle: (title) => `Colar um link ${title}`,
  placeholder: 'Placeholder',
  quote: 'Citação',
  removeLink: 'Remover link',
  searchOrPasteLink: 'Buscar ou colar um link...',
  strikethrough: 'Riscado',
  strong: 'Negrito',
  subheading: 'Subtítulo',
  table: 'Tabela',
  tip: 'Dica',
  tipNotice: 'Dica',
  warning: 'Aviso',
  warningNotice: 'Aviso',
}
