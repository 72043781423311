import { DateTimeRangeType } from './DateTimeRangeType'
import { TimeType } from './TimeType'

export class TimeRangeType extends TimeType {
  static getDefaultTypeParams() {
    return TimeType.extendTypeParams({
      rangePicker: true,
    })
  }

  static isEmpty = DateTimeRangeType.isEmpty
}
