import { ArrowDownIcon, ArrowUpIcon } from '../../../ui/Icons'

import { BaseType } from '../BaseType'
import IconButton from '../../../ui/IconButton'
import { InputNumber } from 'antd'
import React from 'react'
import { assert } from '../../../../libs/utils'
import styled from 'styled-components'

const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  > div {
    display: flex;
    align-items: center;

    > label {
      width: 50px;
    }

    > .anticon {
      flex: 1;

      &.disabled {
        color: #ccc;
      }

      &.enabled:hover {
        cursor: pointer;
        color: var(--theme-color-accent);
      }
    }
  }

  button {
    width: 100%;
  }
`

export class NumberType extends BaseType {
  static getDefaultTypeParams() {
    const parseNumber = (value, valueDefault) => {
      const parsedFloat = parseFloat(value)
      return Number.isNaN(parsedFloat) ? valueDefault : parsedFloat
    }
    return BaseType.extendTypeParams({
      valueDefault: 0,
      parseValueIn: this.fromParams(
        ({ valueDefault }) =>
          (value) =>
            parseNumber(value, valueDefault),
        ['valueDefault']
      ),
      parseValueOut: this.fromParams(
        ({ valueDefault }) =>
          (value) =>
            parseNumber(value, valueDefault),
        ['valueDefault']
      ),
      formatEmpty: '---',
      format: (x) => x,
      min: 0,
      max: Infinity,
      precision: 0,
      step: 1,
      decimalSeparator: ',',
      prefix: '',
      mask: this.fromParams(
        ({ prefix }) =>
          (v) =>
            `${prefix}${v}`,
        ['prefix']
      ),
      parser: this.fromParams(
        ({ prefix }) =>
          (v) =>
            v.replace(prefix, ''),
        ['prefix']
      ),
      maskOnFormat: true,
      inputWidth: '80px',
      filters: 'custom',
    })
  }

  static isEmpty = (value) => value === undefined

  static renderer = ({
    value,
    onChange,
    onBlur,
    readOnly,
    disabled,
    min,
    max,
    precision,
    decimalSeparator,
    step,
    mask,
    parser,
    inputWidth,
  }) => {
    return (
      <InputNumber
        style={{ width: inputWidth }}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        readOnly={readOnly}
        disabled={disabled}
        min={min}
        max={max}
        precision={precision}
        decimalSeparator={decimalSeparator}
        step={step}
        formatter={mask}
        parser={parser}
      />
    )
  }

  static filterRenderer = (
    filters,
    onChangeFilters,
    onConfirm,
    focusRef,
    typeParams
  ) => {
    const filterParams = {
      ...typeParams,
      optional: true,
      disabled: false,
      readOnly: false,
    }

    return (
      <FilterContainer>
        <div>
          <label>Início:</label>
          {this.renderer({
            value: filters[0]?.start,
            onChange: (value) =>
              onChangeFilters([{ ...filters[0], start: value }]),
            ...filterParams,
          })}
        </div>
        <div>
          <label />
          <ArrowUpIcon
            className={filters[0]?.end ? 'enabled' : 'disabled'}
            onClick={
              !filters[0]?.end
                ? undefined
                : () =>
                    onChangeFilters([{ ...filters[0], start: filters[0].end }])
            }
          />
          <ArrowDownIcon
            className={filters[0]?.start ? 'enabled' : 'disabled'}
            onClick={
              !filters[0]?.start
                ? undefined
                : () =>
                    onChangeFilters([{ ...filters[0], end: filters[0].start }])
            }
          />
        </div>
        <div>
          <label>Fim:</label>
          {this.renderer({
            value: filters[0]?.end,
            onChange: (value) =>
              onChangeFilters([{ ...filters[0], end: value }]),
            ...filterParams,
          })}
        </div>
        <IconButton.Search noText onClick={() => onConfirm()} />
      </FilterContainer>
    )
  }

  static filterComparator = (value, filter) =>
    (!filter.start || (value && value >= filter.start)) &&
    (!filter.end || (value && value <= filter.end))

  static formatter = (
    value,
    { getRecord, format, mask, maskOnFormat, precision, decimalSeparator }
  ) =>
    format(
      !maskOnFormat
        ? value
        : mask(
            String(parseFloat(value).toFixed(precision)).replace(
              '.',
              decimalSeparator
            )
          ),
      getRecord()
    )

  static validators = (value, { min, max }) => [
    assert(value >= min, `O campo %% deve ser maior ou igual a ${min}`),
    assert(value <= max, `O campo %% deve ser menor ou igual a ${min}`),
  ]
}
