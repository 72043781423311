import {
  ArrayTabsType,
  ArrayType,
  BooleanType,
  NumberType,
  ObjectType,
  RefType,
  RichTextType,
  TokenType,
} from '../../components/data/fieldtypes'
import { assert, checkOverlaps } from '../../libs/utils'

import CRUDPage from '../../components/data/CRUDPage'
import React from 'react'
import { appRoutes } from '../../components/routing/routingConfig'

export default function ExamQuestionSets(props) {
  return (
    <CRUDPage
      user={props.user}
      history={props.history}
      params={props.match.params}
      resource='/exam-question-set'
      dependencies={{
        examQuery: '/exam',
        subjectQuery: '/subject',
        teacherQuery: '/teacher',
      }}
      schema={({ list, examQuery, subjectQuery, teacherQuery }) => ({
        id_exam: new RefType({
          query: examQuery,
          display: 'name',
          routeConfig: appRoutes.exams.root,
        }),
        index: new NumberType({
          valueDefault: 1,
          min: 1,
          format: (value, record) =>
            record?.questions?.length > 1
              ? `${value}-${parseInt(value) + record.questions.length - 1}`
              : value,
          customValidators: (value, { validationTrigger, getRecord }) => {
            if (validationTrigger !== 'save') return []
            const record = getRecord()
            const currentFirst = value
            const currentLast =
              currentFirst + (record.questions?.length ?? 1) - 1
            let duplicate
            return [
              assert(
                !record.id_exam ||
                  !list.some((qs) => {
                    const checkFirst = parseInt(qs.index)
                    const checkLast =
                      checkFirst + (qs.questions?.length ?? 1) - 1
                    duplicate = Math.max(currentFirst, checkFirst)
                    return (
                      qs.id_exam === record.id_exam &&
                      qs.id !== record.id &&
                      checkOverlaps(
                        currentFirst,
                        currentLast,
                        checkFirst,
                        checkLast
                      )
                    )
                  }),
                `A questão ${duplicate} já existe para este simulado`
              ),
            ]
          },
          customFilterComparator: (value, filter, { getRecord }) => {
            console.log({ record: getRecord() })
            const firstIndex = value
            const lastIndex =
              value && value + (getRecord().questions?.length ?? 1) - 1
            return (
              (!filter.start || (lastIndex && lastIndex >= filter.start)) &&
              (!filter.end || (firstIndex && firstIndex <= filter.end))
            )
          },
        }),
        id_subject: new RefType({
          query: subjectQuery,
          display: 'name',
          sort: 'order',
          filterFn: (subject) => subject.variant === 'none',
          routeConfig: appRoutes.admin.subjects,
        }),
        id_teacher: new RefType({
          query: teacherQuery,
          display: 'name',
          routeConfig: appRoutes.admin.teachers,
        }),
        introduction: new RichTextType({
          optional: true,
          customValidators: (value, { validationTrigger, getRecord }) => {
            if (validationTrigger !== 'save') return []
            const record = getRecord()
            const isEmpty = RichTextType.isEmpty(value)
            return [
              assert(
                isEmpty || record.questions?.length >= 2,
                'O campo %% só pode ser preenchido se houver mais de uma questão compartilhando a mesma introdução'
              ),
              assert(
                !isEmpty || record.questions?.length < 2,
                'O campo %% é obrigatório se houver mais de uma questão compartilhando a mesma introdução'
              ),
            ]
          },
        }),
        questions: new ArrayTabsType({
          childrenType: new ObjectType({
            schema: {
              question: new RichTextType(),
              alternatives: new ArrayType({
                childrenType: new RichTextType(),
                fixedLength: 4,
                getItemLabel: (index) => 'abcd'[index] + ')',
                displayAs: 'input',
              }),
              answer: new TokenType({
                tokens: ['a', 'b', 'c', 'd'],
              }),
              cancelled: new BooleanType({
                parseValueIn: (value) => value === 'sim' || value === true, // TODO: DB migration
              }),
            },
          }),
          getItemLabel: (index, record) =>
            'Questão ' + (Math.max(parseInt(record.index) || 1, 1) + index),
        }),
      })}
      listColumns={[
        {
          header: 'Simulado',
          name: 'id_exam',
        },
        {
          header: 'Número',
          name: 'index',
        },
        {
          header: 'Matéria',
          name: 'id_subject',
        },
        {
          header: 'Professor',
          name: 'id_teacher',
        },
      ]}
      formFields={({ list, teacherQuery }) => [
        {
          name: 'id_exam',
          label: 'Simulado',
          onChange: ({ value, record, setFields }) => {
            const indexList = list
              .filter((qs) => qs.id_exam === value && qs.id !== record.id)
              .map(
                (qs) =>
                  qs.questions?.map((_, i) => (parseInt(qs.index) || 0) + i) ??
                  []
              )
              .flat()
              .filter((index) => index > 0)
              .sort((a, b) => a - b)
            const nextIndex =
              indexList.findIndex((index, i) => index !== i + 1) + 1
            if (!record.index || indexList.includes(record.index)) {
              setFields({
                index: nextIndex || indexList.length + 1,
              })
            }
          },
        },
        {
          name: 'index',
          label: 'Índice',
        },
        {
          name: 'id_subject',
          label: 'Matéria',
          onChange: ({ value, record, setFields }) => {
            const validTeachers = teacherQuery.data.filter((teacher) =>
              teacher.id_subjects.includes(value)
            )
            if (
              !validTeachers.some((teacher) => teacher.id === record.id_teacher)
            ) {
              setFields({
                id_teacher:
                  validTeachers.length === 1 ? validTeachers[0].id : '',
              })
            }
          },
        },
        {
          name: 'id_teacher',
          label: 'Professor',
          dependencies: ['id_subject'],
          disabledFn: ({ record }) => !record.id_subject,
          typeParamsFn: ({ record }) => ({
            filterFn: (teacher) =>
              teacher.id_subjects.includes(record.id_subject),
          }),
        },
        {
          name: 'introduction',
          label: 'Introdução',
          help: `
            A introdução deve ser utilizada apenas se houver duas ou mais questões
            compartilhando um mesmo recurso (texto, citação ou imagem, por exemplo).
            Se houver apenas uma questão utilizando este recurso, insira a introdução
            diretamente no campo "Pergunta" abaixo.
          `,
          hiddenFn: ({ readOnly, type, value }) =>
            readOnly && type.isEmpty(value),
        },
        {
          name: 'questions',
          label: 'Questões',
          dependencies: ['index'],
          typeParams: {
            childrenParams: {
              formFields: [
                {
                  name: 'question',
                  label: 'Pergunta',
                },
                {
                  name: 'alternatives',
                  label: 'Alternativas',
                },
                {
                  name: 'answer',
                  label: 'Gabarito',
                },
                {
                  name: 'cancelled',
                  label: 'Cancelada',
                },
              ],
            },
          },
        },
      ]}
      listOptions={{
        sortFunction: (qs) => qs.id_exam + String(qs.index).padStart(2, '0'),
      }}
    />
  )
}
