import { useEffect, useState } from 'react'

export function AsyncRender({ input, process, render }) {
  const [state, setState] = useState()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const onLoad = async () => {
      setIsLoading(true)
      const newState = await process(input)
      setState(newState)
      setIsLoading(false)
    }
    onLoad()
  }, [input, process])

  return render(state, isLoading)
}
