import React from 'react'
import styled from 'styled-components'

const StyledContainer = styled.div`
  padding-top: 100px;
  text-align: center;
`

export default function NotFound() {
  return (
    <StyledContainer>
      <h3>Página não encontrada!</h3>
    </StyledContainer>
  )
}
