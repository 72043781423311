import React, { useState } from 'react'

import ExamBody from './ExamBody'
import ExamHeader from './ExamHeader'
import IconButton from '../../ui/IconButton'
import SimpleModal from '../../ui/SimpleModal'

export default function ExamContainer({
  status,
  endTime,
  isSaving,
  lastSaved,
  onDeliverExam,
  selectedSubject,
  onSelectSubject,
  subjectQuestions,
  essayTheme,
  answers,
  onChangeEssay,
  onSubmitAnswer,
  showEndModal,
  onCloseEndModal,
}) {
  const [showConfirmationModal, toggleConfirmationModal] = useState(false)
  return (
    <>
      <ExamHeader
        status={status}
        endTime={endTime}
        isSaving={isSaving}
        lastSaved={lastSaved}
        onDeliverExam={() => toggleConfirmationModal(true)}
      />
      <ExamBody
        readOnly={status !== 'in_progress'}
        selectedSubject={selectedSubject}
        onSelectSubject={onSelectSubject}
        subjectQuestions={subjectQuestions}
        essayTheme={essayTheme}
        answers={answers}
        onChangeEssay={onChangeEssay}
        onSubmitAnswer={onSubmitAnswer}
      />
      <SimpleModal
        show={showEndModal}
        showBackdrop={true}
        allowClose={true}
        title='Simulado finalizado'
        buttons={<IconButton.Okay text='Fechar' onClick={onCloseEndModal} />}
      >
        <p>Todas as suas respostas já foram enviadas com sucesso.</p>
        <p>Obrigado por participar!</p>
        <p>Em breve enviaremos mais detalhes sobre os resultados.</p>
      </SimpleModal>
      <SimpleModal
        show={showConfirmationModal}
        showBackdrop={true}
        allowClose={true}
        title='Atenção!'
        buttons={
          <>
            <IconButton.Cancel
              text='Não, continuar respondendo'
              onClick={() => toggleConfirmationModal(false)}
            />
            <IconButton.Save
              text='Sim, entregar prova!'
              onClick={() => {
                toggleConfirmationModal(true)
                onDeliverExam()
              }}
            />
          </>
        }
      >
        <p>
          Certifique-se de que você respondeu todas as questões$
          {essayTheme ? ' e finalizou a redação' : ''}.
        </p>
        <p>Após a entrega, não será mais possível alterar nenhuma resposta!</p>
      </SimpleModal>
    </>
  )
}
