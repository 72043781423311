import { Redirect, Route } from 'react-router-dom'

import React from 'react'

export default function AuthenticatedRoute({
  component: C,
  access = ['owner'],
  appProps,
  ...rest
}) {
  const hasAccess =
    access === 'all' ||
    access === true ||
    appProps.user.group === 'owner' ||
    (Array.isArray(access) && access.includes(appProps.user.group))

  return (
    <Route
      {...rest}
      render={(props) => {
        const loginRoute = `/entrar?redirect=${props.location.pathname}${props.location.search}`

        if (!appProps.isAuthenticated) {
          return <Redirect to={loginRoute} />
        } else if (hasAccess) {
          return <C {...props} {...appProps} />
        } else {
          return <Redirect to='/' />
        }
      }}
    />
  )
}
