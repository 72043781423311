import React, { useEffect, useRef, useState } from 'react'

import CRUDButtonBar from '../ui/CRUDButtonBar'
import DataForm from './DataForm'
import DataTable from './DataTable'
import { If } from '../utils'
import { NEW_RECORD } from './crud.hooks'
import VerticalSpace from '../ui/VerticalSpace'
import { mapObject } from '../../libs/utils'
import { message } from 'antd'
import { useViewSettings } from './viewSettings'

export default function DataCRUD({
  user,
  schema = {},
  list,
  listColumns,
  record,
  recordId,
  formFields,
  listOptions = {},
  formOptions = {},
  crudOptions = {},
  onViewList,
  onViewRecord,
  onSaveRecord,
  onDeleteRecord,
}) {
  const viewSettings = useViewSettings()
  const [isEditing, setIsEditing] = useState(recordId === NEW_RECORD)
  const formCallbacks = useRef(null)

  const getForm = () => formCallbacks.current

  const defaultValues = mapObject(
    schema,
    (_, typeClass) => typeClass.getParams().valueDefault
  )

  const withDefaults = (record) => ({
    ...record,
    ...mapObject(schema, (field) => record?.[field] ?? defaultValues[field]),
  })

  const hasActionFlag = (action, flag) => {
    const allow = crudOptions?.[flag]?.[action]
    return (
      allow === undefined ||
      allow === true ||
      (typeof allow === 'function' && allow(record) === true)
    )
  }

  const callHook = (hook, ...args) =>
    crudOptions?.hooks?.[hook] && crudOptions?.hooks?.[hook](...args)

  useEffect(() => {
    setIsEditing(recordId === NEW_RECORD)
  }, [recordId])

  const dataList = list.map(withDefaults)
  const parsedRecord = withDefaults(
    formOptions.parseRecord ? formOptions.parseRecord(record) : record
  )

  const renderButtonBar = () => (
    <CRUDButtonBar
      handlers={{
        resetFilters: () => viewSettings.update({ filters: {} }),
        create: () => onViewRecord(NEW_RECORD),
        viewList: () => onViewList(),
        edit: () => setIsEditing(true),
        cancelEdit: () => {
          getForm().reset()
          setIsEditing(false)
        },
        save: async () => {
          const form = getForm()
          const values = getForm().getValues()
          if (callHook('beforeSave', values) === false) {
            return
          } else if (await form.validate()) {
            const predictedUpdate = callHook('predictUpdate', values)
            onSaveRecord(values, predictedUpdate, {
              onSuccess: () =>
                message.success('Alterações salvas com sucesso.'),
              onError: () =>
                message.error(
                  'Erro ao salvar alterações, por favor, tente novamente.'
                ),
            })
            onViewList()
            callHook('afterSave', values)
          } else {
            message.error('Por favor, verifique os campos inválidos.')
          }
        },
        delete: () => {
          onDeleteRecord(record.id, {
            onSuccess: () => message.success('Registro removido com sucesso.'),
            onError: () =>
              message.error(
                'Erro ao remover registro, por favor, tente novamente.'
              ),
          })
          onViewList()
          callHook('afterDelete', record.id)
        },
      }}
      flags={{
        isEditing,
        isCreating: recordId === NEW_RECORD,
        isListView: recordId === undefined,
      }}
      disableCreate={!hasActionFlag('create', 'allow')}
      disableEdit={!hasActionFlag('update', 'allow')}
      disableRemove={!hasActionFlag('delete', 'allow') || !record?.id}
      disableSave={!hasActionFlag('save', 'allow')}
      omitCreate={!hasActionFlag('create', 'show')}
      omitEdit={!hasActionFlag('update', 'show')}
      omitRemove={!hasActionFlag('delete', 'show')}
      omitSave={!hasActionFlag('save', 'show')}
      omitViewList={!hasActionFlag('list', 'show')}
    />
  )

  return (
    <VerticalSpace size='middle'>
      {renderButtonBar()}
      <If
        condition={recordId === undefined}
        then={
          <DataTable
            user={user}
            schema={schema}
            dataList={dataList}
            mapRecord={listOptions.mapRecord}
            onSelectRow={(id) => onViewRecord(id)}
            columns={listColumns}
            sortColumn={listOptions.sortColumn}
            sortOrder={listOptions.sortOrder}
            sortFunction={listOptions.sortFunction}
            sortFunctionOrder={listOptions.sortFunctionOrder}
            filterFunction={listOptions.filterFunction}
            expandFunction={listOptions.expandFunction}
          />
        }
        else={
          <DataForm
            user={user}
            schema={schema}
            dataList={dataList}
            initialValues={parsedRecord}
            fields={formFields}
            readOnly={!isEditing}
            setFormCallbacks={(fc) => {
              formCallbacks.current = fc
            }}
          />
        }
      />
      {renderButtonBar()}
    </VerticalSpace>
  )
}
