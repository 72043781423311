import { Progress } from 'antd'
import React from 'react'
import { getThemeColor } from '../../libs/utils'
import styled from 'styled-components'

const StyledLabel = styled.div`
  font-size: 13px;
  color: #999;
  margin-top: -15px;
`

export default function DashboardStatistic({ label, percent, color }) {
  return (
    <div className='center'>
      <Progress
        type='dashboard'
        percent={isNaN(percent) ? 0 : percent}
        strokeColor={
          {
            theme: getThemeColor(),
            accent: getThemeColor('accent'),
            default: '#1890ff',
            success: '#52c41a',
            error: '#ff4d4f',
          }[color] ??
          color ??
          getThemeColor()
        }
        format={(percent) => `${Math.round(percent)}%`}
      />
      <StyledLabel>{label}</StyledLabel>
    </div>
  )
}
