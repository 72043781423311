import { If, WrapIf } from '../../../utils'
import React, { useEffect, useRef, useState } from 'react'
import { getLinkFromFileData, replaceAsync } from '../../../../libs/utils'

import Editor from 'rich-markdown-editor'
import EmbedController from './richtext/EmbedController'
import { FileType } from '../file'
import { TextType } from './TextType'
import { dictionary } from './richtext/dictionary'
import { light } from 'rich-markdown-editor/dist/theme'
import { message } from 'antd'
import { s3GetLink } from '../../../../libs/awsLib'
import styled from 'styled-components'

const StyledEditor = styled(Editor)`
  width: calc(100% - 32px);
  padding: 4px 4px 4px 8px;
  & > div {
    background: transparent;
    ${({ $disabled }) =>
      $disabled &&
      `
      background-color: #f5f5f5;
      color: rgba(0, 0, 0, 0.25);
    `}
  }
  & .block-menu-trigger {
    margin-top: -3px;
  }
`

export class RichTextType extends TextType {
  static getDefaultTypeParams() {
    return TextType.extendTypeParams({
      placeholder:
        'Digite / ou clique no + para ver as opções de formatação...',
      displayAs: 'input',
      validationTriggers: ['change'],
    })
  }

  static isEmpty = (value) =>
    !value || value.trim() === '' || value.trim() === '\\'

  static renderer = ({ value, onChange, readOnly, disabled, placeholder }) => {
    const editor = useRef()
    const [parsedText, setParsedText] = useState('')
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
      async function parseText() {
        const parsedText = await replaceAsync(
          value,
          /%%FILE:(.*?)%%/g,
          async (match, storedName) => {
            const s3Url = await s3GetLink(storedName.replace(':', '-')) // TODO: DB migration
            return `${s3Url}#${storedName}`
          }
        )
        setParsedText(parsedText)
        setIsLoading(false)
      }
      if (value) {
        parseText()
      } else {
        setIsLoading(false)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function saveChanges() {
      const value = editor.current.value()
      const parsedValue = value.replace(
        /!\[(.*?)\]\(.*?#(.*?)\)/g,
        '![$1](%%FILE:$2%%)'
      )
      onChange(parsedValue)
    }

    async function uploadImage(file) {
      // TODO: set global loading flag to avoid saving before uploaded
      const name = file.name.replace(/[^a-zA-Z0-9.]/g, '_')
      const storedName = await FileType.uploadFile(file, name)
      const dataUri = await getLinkFromFileData(file)
      return `${dataUri}#${storedName}`
    }

    function onShowToast(toastMessage, toastType) {
      switch (toastType) {
        case 'info':
          message.info(toastMessage)
          break
        case 'error':
          message.error(toastMessage)
          break
        default:
      }
    }

    return (
      <WrapIf
        condition={!readOnly}
        wrapper={EmbedController}
        wrapperProps={{ disabled }}
      >
        <If condition={!isLoading}>
          <StyledEditor
            ref={editor}
            placeholder={placeholder}
            defaultValue={parsedText}
            onShowToast={onShowToast}
            uploadImage={uploadImage}
            onSave={saveChanges}
            onChange={saveChanges}
            embeds={EmbedController.getEmbeds()}
            readOnly={readOnly || disabled}
            $disabled={disabled}
            dictionary={dictionary}
            theme={{
              ...light,
              noticeInfoBackground: '#e6f7ff',
              noticeInfoText: '#1890ff',
              noticeTipBackground: '#fffbe6',
              noticeTipText: '#faad14',
              noticeWarningBackground: '#fff2f0',
              noticeWarningText: '#ff4d4f',
            }}
          />
        </If>
      </WrapIf>
    )
  }
}
