import {
  BooleanType,
  DateType,
  FileType,
  NumberType,
  ObjectType,
  RefType,
  TextAreaType,
  TokenType,
} from '../../components/data/fieldtypes'
import {
  CameraIcon,
  CheckIcon,
  ClockIcon,
  InboxIcon,
  QuestionMarkIcon,
  TextIcon,
  VisibleIcon,
} from '../../components/ui/Icons'
import { Card, Tag } from 'antd'
import {
  arrayToObject,
  downloadAsDocx,
  objectToArray,
  pipe,
  sumArray,
} from '../../libs/utils'
import {
  enemCriterias,
  enemScoreTiers,
} from '../../components/essay/enemCriterias'

import CRUDPage from '../../components/data/CRUDPage'
import IconButton from '../../components/ui/IconButton'
import React from 'react'
import { appRoutes } from '../../components/routing/routingConfig'
import moment from 'moment'
import styled from 'styled-components'

const IconContainer = styled.span`
  display: flex;
  gap: 5px;
`

export default function Essays(props) {
  return (
    <CRUDPage
      user={props.user}
      history={props.history}
      params={props.match.params}
      listResource='/redacao/all'
      recordResource='/redacao'
      dependencies={{
        themeQuery: { resource: '/tema', key: 'id_tema', prefix: 'theme' },
        userQuery: '/user',
      }}
      schema={({ record, themeQuery, userQuery }) => ({
        id_tema: new RefType({
          query: themeQuery,
          displayFn: (record) => `${record.category} - ${record.titulo}`,
          routeConfig: appRoutes.essays.themes,
          readOnly: true,
        }),
        user_id: new RefType({
          query: userQuery,
          displayFn: (user) => user.name || user.email,
          routeConfig: appRoutes.system.users,
          formatEmpty: 'N/A',
          readOnly: true,
        }),
        corrigida: new BooleanType({
          displayTrue: 'Corrigida',
          displayFalse: 'Pendente',
          formatTrue: <Tag color='success'>Corrigida</Tag>,
          formatFalse: <Tag color='warning'>Pendente</Tag>,
        }),
        txt_redacao: new BooleanType({
          parseValueIn: (value) => !!value,
          formatTrue: <TextIcon />,
          formatFalse: <CameraIcon />,
        }),
        theme__dtavailable: new DateType({
          displayShort: true,
          formatEmpty: '---',
          readOnly: true,
        }),
        theme__dtdelivery: new DateType({
          displayShort: true,
          formatEmpty: '---',
          readOnly: true,
        }),
        dtenviada: new DateType({ displayShort: true, readOnly: true }),
        dtcorrigida: new DateType({ displayShort: true, readOnly: true }),
        txt_correcao: new TextAreaType(),
        url_correcao: new FileType({ type: 'pdf' }),
        nota:
          themeQuery.findById(record.id_tema)?.category !== 'ENEM'
            ? new NumberType({ min: 0, max: 10, precision: 1 })
            : new ObjectType({
                schema: enemCriterias.reduce(
                  (schema, criteria, indexCriteria) => ({
                    ...schema,
                    [indexCriteria]: new TokenType({
                      tokens: enemScoreTiers.map((tier, indexTier) => ({
                        [tier]: `${tier} - ${criteria.result[indexTier]}`,
                      })),
                      parseValueIn: (value) => String(value),
                      parseValueOut: (value) => parseInt(value),
                      formatEmpty: 'N/A',
                    }),
                  }),
                  {}
                ),
                formFields: [
                  ...enemCriterias.map((criteria, index) => ({
                    label: `Competência ${index + 1}`,
                    help: criteria.title,
                    name: String(index),
                  })),
                  {
                    render: ({ record }) => (
                      <b>
                        {`Total: ${record.nota ? sumArray(record.nota) : 0}`}
                      </b>
                    ),
                  },
                ],
                valueDefault: new Array(enemCriterias.length).fill(undefined),
                parseValueIn: (value) => arrayToObject([value].flat()),
                parseValueOut: objectToArray,
              }),
      })}
      listColumns={[
        {
          header: 'Tema',
          name: 'id_tema',
        },
        {
          header: 'Aluno',
          name: 'user_id',
        },
        {
          header: (
            <IconContainer title='Data de disponibilização para os alunos'>
              <VisibleIcon />
            </IconContainer>
          ),
          name: 'theme__dtavailable',
          align: 'center',
        },
        {
          header: (
            <IconContainer title='Prazo para os alunos enviarem a redação'>
              <ClockIcon />
              <InboxIcon />
            </IconContainer>
          ),
          name: 'theme__dtdelivery',
          align: 'center',
        },
        {
          header: (
            <IconContainer title='Data de envio da redação'>
              <InboxIcon />
            </IconContainer>
          ),
          name: 'dtenviada',
          align: 'center',
        },
        {
          header: (
            <IconContainer title='Prazo para enviar a correção'>
              <ClockIcon />
              <CheckIcon />
            </IconContainer>
          ),
          name: 'dtprazocorrecao',
          align: 'center',
          render: ({ record }) => {
            const deadline = record.theme__category === 'Curso CTI/ETEC' ? 7 : 3
            return record.dtenviada
              ? DateType.format(
                  moment(record.dtenviada).add(deadline, 'days'),
                  { displayShort: true }
                )
              : '---'
          },
        },
        {
          header: (
            <IconContainer title='Data de envio da correção'>
              <CheckIcon />
            </IconContainer>
          ),
          name: 'dtcorrigida',
          align: 'center',
        },
        {
          header: (
            <IconContainer title='Envio por foto ou texto'>
              <CameraIcon />
              <TextIcon />
            </IconContainer>
          ),
          name: 'txt_redacao',
          align: 'center',
        },
        {
          header: (
            <IconContainer title='Status'>
              <QuestionMarkIcon />
            </IconContainer>
          ),
          name: 'corrigida',
          align: 'center',
        },
      ]}
      formFields={({ record, themeQuery, userQuery }) => [
        {
          label: 'Redação',
          render: () =>
            pipe(
              [
                themeQuery.findById(record.id_tema) ?? {},
                userQuery.findById(record.user_id) ?? {},
              ],
              ([theme, user]) => [theme, user.name || user.email || 'N/A'],
              ([theme, username]) => (
                <Card>
                  <h3>
                    {theme.category} - {theme.titulo} - {username}
                  </h3>
                  <h4>{theme.tema}</h4>
                  <IconButton.Download
                    text='Proposta'
                    onClick={() => FileType.downloadFile(theme.material)}
                  />
                  <IconButton.Download
                    text='Redação'
                    type='primary'
                    onClick={
                      record.url_redacao
                        ? () => FileType.downloadFile(record.url_redacao)
                        : () =>
                            downloadAsDocx(
                              'essay.docx',
                              `${username} - Redação.docx`,
                              {
                                text: record.txt_redacao,
                                name: username,
                                theme: theme.tema,
                              }
                            )
                    }
                  />
                </Card>
              )
            ),
        },
        {
          label: 'Correção',
          name: 'url_correcao',
        },
        {
          label: 'Comentários',
          name: 'txt_correcao',
        },
        {
          label: 'Nota',
          name: 'nota',
        },
        {
          label: 'Status',
          name: 'corrigida',
        },
      ]}
      listOptions={{
        filterFunction: (record) => record.enviada !== false,
        sortFunction: (record) => [
          record.corrigida,
          record.theme__category === 'Curso CTI/ETEC',
          record.dtcorrigida ? 0 : record.dtenviada,
          record.dtcorrigida ?? 0,
        ],
        sortFunctionOrder: ['asc', 'asc', 'asc', 'desc'],
      }}
      crudOptions={{
        show: {
          create: false,
        },
      }}
    />
  )
}
