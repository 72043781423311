import { CheckIcon, ClockIcon, LoadingIcon, WarningIcon } from '../../ui/Icons'

import { Card } from 'antd'
import ConfirmPopover from '../../ui/ConfirmPopover'
import IconButton from '../../ui/IconButton'
import React from 'react'
import { TimeType } from '../../data/fieldtypes'
import styled from 'styled-components'

const StyledCard = styled(Card)`
  position: sticky;
  top: 0;
  z-index: 100;
  margin-bottom: 30px;
  background-color: var(--theme-color-light);
  font-size: 14px;

  & .icon-container {
    display: inline-block;
    width: 25px;
  }
`

export default function ExamHeader({
  status,
  endTime,
  isSaving,
  lastSaved,
  onDeliverExam,
}) {
  return (
    <StyledCard>
      {status !== 'in_progress' ? (
        <div className='center'>
          Este simulado já terminou, mas você pode rever as questões e suas
          respostas abaixo.
        </div>
      ) : (
        <div>
          <div className='pull-left'>
            {endTime && (
              <div>
                <span className='icon-container'>
                  <ClockIcon />
                </span>
                <span>Fim da prova: {TimeType.format(endTime)}</span>
              </div>
            )}
            <div>
              {isSaving === null ? null : isSaving === true ? (
                <span>
                  <span className='icon-container' style={{ color: 'orange' }}>
                    <LoadingIcon />
                  </span>
                  <span>Salvando...</span>
                </span>
              ) : isSaving === false ? (
                <span>
                  <span className='icon-container' style={{ color: 'green' }}>
                    <CheckIcon />
                  </span>
                  <span>Respostas salvas às {TimeType.format(lastSaved)}</span>
                </span>
              ) : isSaving === undefined ? (
                <span>
                  <span className='icon-container' style={{ color: 'orange' }}>
                    <WarningIcon />
                  </span>
                  <span>Salvamento pendente</span>
                </span>
              ) : undefined}
            </div>
          </div>
          <div className='pull-right'>
            <ConfirmPopover
              confirmText='Sim, entregar prova'
              confirmCallback={onDeliverExam}
              placement='bottom'
            >
              <IconButton.Submit text='Entregar' />
            </ConfirmPopover>
          </div>
        </div>
      )}
    </StyledCard>
  )
}
