import { Form } from 'antd'
import { If } from '../utils'
import React from 'react'
import { fn } from '../../libs/utils'
import styled from 'styled-components'

const StyledFormItem = styled(Form.Item)`
  margin-bottom: ${({ isListField }) => (isListField ? '0px' : '24px')};
  & .ant-form-item-explain,
  &:last-child {
    margin-bottom: 0px;
  }
`

export default function DataFormItemController({
  user,
  dataList,
  className,
  type,
  fieldPath,
  fullFieldPath,
  isListField,
  label,
  help,
  typeParamsFn,
  hiddenFn = fn(false),
  optionalFn = fn(false),
  disabledFn = fn(false),
  readOnlyFn = fn(false),
  renderFn,
  onChange,
  shouldUpdate,
}) {
  return (
    <Form.Item noStyle className={className} shouldUpdate={shouldUpdate}>
      {(form) => {
        const value = fullFieldPath
          ? form.getFieldValue(fullFieldPath.split('.'))
          : undefined
        const getRecord = () => form.getFieldsValue(true)
        const getDataList = () => dataList
        const typeParams = type?.getParams() ?? {}
        const fnProps = { type, value, record: getRecord() }
        const optional = typeParams.optional || optionalFn(fnProps)
        const disabled = typeParams.disabled || disabledFn(fnProps)
        const readOnly = typeParams.readOnly || readOnlyFn(fnProps)
        const hidden = hiddenFn({ optional, disabled, readOnly, ...fnProps })
        const renderType = type?.withParams({
          getRecord,
          getDataList,
          user,
          fieldPath,
          fullFieldPath,
          optional,
          disabled,
          readOnly,
          fieldLabel: label,
          ...typeParamsFn({ optional, disabled, readOnly, ...fnProps }),
        })
        const formItemName =
          !renderFn && !readOnly && !typeParams.isSelfControlled
            ? fieldPath
            : undefined
        const parsedOnChange = !onChange
          ? undefined
          : (value) =>
              onChange({
                value,
                record: getRecord(),
                setFields: form.setFieldsValue,
              })
        return (
          <If condition={!hidden}>
            <StyledFormItem
              name={
                formItemName !== undefined
                  ? String(formItemName).split('.')
                  : undefined
              }
              isListField={isListField}
              label={label}
              tooltip={help}
              validateFirst={true}
              rules={
                renderType?.getValidationRules(label, [
                  'change',
                  'blur',
                  'save',
                ]) ?? []
              }
            >
              {renderFn
                ? renderFn({
                    value,
                    formattedValue:
                      renderType && fieldPath
                        ? renderType.format(value)
                        : value,
                    record: getRecord(),
                  })
                : readOnly
                ? renderType.display(value, { isFormControlled: true })
                : renderType.render(value, parsedOnChange)}
            </StyledFormItem>
          </If>
        )
      }}
    </Form.Item>
  )
}
