import React from 'react'
import { TextType } from './TextType'
import { copyToClipboard } from '../../../../libs/utils'
import styled from 'styled-components'

const StyledLink = styled.a`
  color: rgba(0, 0, 0, 0.85);
`

export class EmailType extends TextType {
  static getDefaultTypeParams() {
    return TextType.extendTypeParams({
      formatAsComponent: true,
      providers: [
        'gmail.com',
        'hotmail.com',
        'outlook.com',
        'live.com',
        'yahoo.com.br',
      ],
      getSuggestions: this.fromParams(
        ({ providers }) =>
          (text) =>
            providers
              .map((provider) => ({
                value: `${text.split('@')[0]}@${provider}`,
              }))
              .filter((option) => option.value.startsWith(text)),
        ['providers']
      ),
    })
  }

  static formatter = (value) => (
    <StyledLink href={`mailto:${value}`} onClick={copyToClipboard}>
      {value}
    </StyledLink>
  )

  static validators = async (value, typeParams) => [
    // TODO: valid email
    await TextType.validate(value, TextType.filterParams(typeParams)),
  ]
}
