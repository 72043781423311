import {
  AuditIcon,
  ConsoleIcon,
  ContactIcon,
  CourseIcon,
  CoursePinnedResourceIcon,
  CourseResourceIcon,
  CourseCalendarIcon,
  CourseVideoIcon,
  EssayCorrectionIcon,
  EssaySubmissionIcon,
  EssayThemeIcon,
  ExamIcon,
  ExamQuestionIcon,
  ProductIcon,
  ProductResourceIcon,
  StudentIcon,
  SubjectIcon,
  TeacherIcon,
  TestIcon,
  TransactionIcon,
  UserIcon,
} from '../ui/Icons'
import { mapFields, pipe } from '../../libs/utils'

import APIConsole from '../../containers/admin/APIConsole'
import Audit from '../../containers/admin/Audit'
import Contact from '../../containers/Contact'
import CourseResources from '../../containers/courses/CourseResources'
import Courses from '../../containers/courses/Courses'
import EssayResources from '../../containers/essays/EssayResources'
import EssayThemes from '../../containers/essays/EssayThemes'
import Essays from '../../containers/essays/Essays'
import ExamDashboard from '../../containers/exams/ExamDashboard'
import ExamQuestionSets from '../../containers/exams/ExamQuestionSets'
import Exams from '../../containers/exams/Exams'
import LiveExam from '../../containers/exams/LiveExam'
import ServiceCart from '../../containers/admin/ServiceCart'
import ServicePacks from '../../containers/admin/ServicePacks'
import StudentEssays from '../../containers/essays/StudentEssays'
import Students from '../../containers/admin/Students'
import Subjects from '../../containers/admin/Subjects'
import Teachers from '../../containers/admin/Teachers'
import Test from '../../containers/admin/Test'
import Transactions from '../../containers/admin/Transactions'
import Users from '../../containers/admin/Users'
import { useLocation } from 'react-router'

export const userGroups = [
  ['owner', 'owner'],
  ['admin', 'admin'],
  // ["teacher", "professor"],
  ['student', 'aluno'],
  ['guest', 'visitante'],
]

export const appRoutesList = [
  {
    groupName: 'system',
    groupTitle: 'Sistema',
    allow: ['owner'],
    routes: [
      {
        name: 'users',
        path: '/admin/usuarios',
        allow: ['owner'],
        title: 'Usuários',
        icon: UserIcon,
        component: Users,
        allowChildPath: true,
      },
      {
        name: 'audit',
        path: '/admin/audit',
        allow: ['owner'],
        title: 'Auditoria',
        icon: AuditIcon,
        component: Audit,
      },
      {
        name: 'apiConsole',
        path: '/admin/console-api',
        allow: ['owner'],
        title: 'Console API',
        icon: ConsoleIcon,
        component: APIConsole,
      },
      {
        name: 'test',
        path: '/admin/testes',
        allow: ['owner'],
        title: 'Testes',
        icon: TestIcon,
        component: Test,
        allowChildPath: true,
      },
    ],
  },
  {
    groupName: 'admin',
    groupTitle: 'Administração',
    allow: ['owner', 'admin'],
    routes: [
      {
        name: 'students',
        path: '/admin/alunos',
        allow: ['owner', 'admin'],
        title: 'Alunos',
        icon: StudentIcon,
        component: Students,
        allowChildPath: true,
      },
      {
        name: 'teachers',
        path: '/admin/professores',
        allow: ['owner', 'admin'],
        title: 'Professores',
        icon: TeacherIcon,
        component: Teachers,
        allowChildPath: true,
      },
      {
        name: 'subjects',
        path: '/admin/materias',
        allow: ['owner'],
        title: 'Matérias',
        icon: SubjectIcon,
        component: Subjects,
        allowChildPath: true,
      },
      {
        name: 'servicePacks',
        path: '/admin/pacotes',
        allow: ['owner'],
        title: 'Pacotes',
        icon: ProductIcon,
        component: ServicePacks,
        allowChildPath: true,
      },
      {
        name: 'transactions',
        path: '/admin/transacoes',
        allow: ['owner', 'admin'],
        title: 'Transações',
        icon: TransactionIcon,
        component: Transactions,
        allowChildPath: true,
      },
      {
        name: 'cart',
        path: '/pacotes',
        allow: (user) => (user.product?.length > 0 ? [] : ['guest']),
        title: 'Pacotes',
        hidden: true,
        component: ServiceCart,
      },
    ],
  },
  {
    groupName: 'courses',
    groupTitle: 'Cursos',
    allow: ['owner', 'admin'],
    routes: [
      {
        name: 'root',
        path: '/admin/cursos/lista',
        allow: ['owner'],
        title: 'Cursos',
        icon: CourseIcon,
        component: Courses,
        allowChildPath: true,
      },
      {
        name: 'calendars',
        path: '/admin/cursos/calendarios',
        allow: ['owner', 'admin'],
        title: 'Calendários',
        icon: CourseCalendarIcon,
        component: CourseResources,
        allowChildPath: true,
      },
      {
        name: 'studentCalendars',
        path: '/calendarios',
        allow: ['student'],
        title: 'Calendários',
        icon: CourseCalendarIcon,
        component: CourseResources,
      },
      {
        name: 'pinnedResources',
        path: '/admin/cursos/apostilas',
        allow: ['owner', 'admin'],
        title: 'Apostilas',
        icon: CoursePinnedResourceIcon,
        component: CourseResources,
        allowChildPath: true,
      },
      {
        name: 'studentPinnedResources',
        path: '/apostilas',
        allow: ['student'],
        title: 'Apostilas',
        icon: CoursePinnedResourceIcon,
        component: CourseResources,
      },
      {
        name: 'resources',
        path: '/admin/cursos/material',
        allow: ['owner', 'admin'],
        title: 'Material',
        icon: CourseResourceIcon,
        component: CourseResources,
        allowChildPath: true,
      },
      {
        name: 'studentResources',
        path: '/material',
        allow: ['student'],
        title: 'Material',
        icon: CourseResourceIcon,
        component: CourseResources,
      },
      {
        name: 'videos',
        path: '/admin/cursos/videoaulas',
        allow: ['owner', 'admin'],
        title: 'Videoaulas',
        icon: CourseVideoIcon,
        component: CourseResources,
        allowChildPath: true,
      },
      {
        name: 'studentVideos',
        path: '/videoaulas',
        allow: ['student'],
        title: 'Videoaulas',
        icon: CourseVideoIcon,
        component: CourseResources,
      },
    ],
  },
  {
    groupName: 'essays',
    groupTitle: 'Redações',
    allow: ['owner', 'admin'],
    routes: [
      {
        name: 'root',
        path: '/admin/redacoes/correcao',
        allow: ['owner', 'admin'],
        title: 'Correção',
        icon: EssayCorrectionIcon,
        component: Essays,
        allowChildPath: true,
      },
      {
        name: 'themes',
        path: '/admin/redacoes/temas',
        allow: ['owner', 'admin'],
        title: 'Temas',
        icon: EssayThemeIcon,
        component: EssayThemes,
        allowChildPath: true,
      },
      {
        name: 'resources',
        path: '/admin/redacoes/recursos',
        allow: ['owner'],
        title: 'Recursos',
        icon: ProductResourceIcon,
        component: EssayResources,
        allowChildPath: true,
      },
      {
        name: 'submission',
        path: '/redacoes/envio',
        allow: (user) =>
          user.hasEssayService ? ['student', 'guest'] : ['student'],
        title: 'Redações',
        icon: EssaySubmissionIcon,
        component: StudentEssays,
        allowChildPath: true,
      },
    ],
  },
  {
    groupName: 'exams',
    groupTitle: 'Simulados',
    allow: ['owner', 'admin'],
    routes: [
      {
        name: 'root',
        path: '/admin/simulados/cadastro',
        allow: ['owner', 'admin'],
        title: 'Simulados',
        icon: ExamIcon,
        component: Exams,
        allowChildPath: true,
      },
      {
        name: 'questions',
        path: '/admin/simulados/questoes',
        allow: ['owner', 'admin'],
        title: 'Questões',
        icon: ExamQuestionIcon,
        component: ExamQuestionSets,
        allowChildPath: true,
      },
      {
        name: 'dashboard',
        path: '/admin/simulados/simulado/:id',
        allow: ['owner', 'admin'],
        title: 'Simulado',
        hidden: true,
        component: ExamDashboard,
      },
      {
        name: 'studentExams',
        path: '/simulados',
        allow: (user) =>
          user.hasExamService ? ['student', 'guest'] : ['student'],
        title: 'Simulados',
        icon: ExamIcon,
        component: Exams,
        allowChildPath: true,
      },
      {
        name: 'liveExam',
        path: '/simulado/:id',
        allow: (user) =>
          user.hasExamService ? ['student', 'guest'] : ['student'],
        title: 'Simulado',
        hidden: true,
        highlightMenu: 'studentExams',
        component: LiveExam,
      },
    ],
  },
  {
    groupName: 'student',
    groupTitle: null,
    allow: ['student'],
    routes: [
      {
        name: 'contact',
        path: '/contato',
        allow: ['student'],
        title: 'Contato',
        icon: ContactIcon,
        component: Contact,
        hidden: true, // TODO: temporarily hidden until backend is ready
      },
    ],
  },
].map((routeGroup) => ({
  ...routeGroup,
  routes: routeGroup.routes.map((route) => ({
    ...route,
    groupName: routeGroup.groupName,
  })),
}))

export const appRoutes = appRoutesList.reduce(
  (groups, group) => ({
    ...groups,
    [group.groupName]: {
      ...(groups[group.groupName] ?? {}),
      ...mapFields(group.routes, 'name'),
    },
  }),
  {}
)

export const useRouteConfig = () => {
  const { pathname } = useLocation()
  const parsedPath = pathname.substring(1).split('/')
  return appRoutesList
    .map((routeGroup) => routeGroup.routes)
    .flat()
    .find((route) =>
      pipe(
        route.path.substring(1).split('/'),
        (parsedRoute) =>
          (parsedPath.length === parsedRoute.length ||
            (route.allowChildPath &&
              parsedPath.length === parsedRoute.length + 1)) &&
          parsedRoute.every(
            (routePart, index) =>
              routePart.startsWith(':') || routePart === parsedPath[index]
          )
      )
    )
}

export const isRouteAllowedForUser = (route, user) =>
  pipe(
    route.allow instanceof Function ? route.allow(user) : route.allow,
    (allowedGroups) => allowedGroups.includes(user.group)
  )
