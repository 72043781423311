import { DateTimeType } from './DateTimeType'

export class TimeType extends DateTimeType {
  static getDefaultTypeParams() {
    return DateTimeType.extendTypeParams({
      storeFormat: 'HH:mm',
      displayFormat: 'HH:mm',
      showDate: false,
    })
  }
}
