import { Alert, Button, Card, Col, List, Row, Tabs } from 'antd'
import { CurrencyType, TextAreaType } from '../../components/data/fieldtypes'
import React, { useEffect, useState } from 'react'

import Checkout from '../../components/Checkout'
import LoaderContent from '../../components/ui/LoaderContent'
import MediaQuery from 'react-responsive'
import PageWrapper from '../../components/ui/PageWrapper'
import VerticalSpace from '../../components/ui/VerticalSpace'
import { useQuery } from 'react-query'

const { TabPane } = Tabs

const STEP_CHOOSE_PRODUCT = 1
const STEP_PAYMENT = 2
const STEP_CONFIRMATION = 3

export default function ServiceCart(props) {
  const [step, setStep] = useState(STEP_CHOOSE_PRODUCT)
  const [selectedProduct, setSelectedProduct] = useState(null)
  const [isProcessingPayment, setIsProcessingPayment] = useState(false)
  const productQuery = useQuery('/produto')
  const [products, setProducts] = useState([])

  useEffect(() => {
    setProducts(
      productQuery?.data
        ?.filter((p) => !p.disabled)
        .sort((a, b) => parseInt(a.ordem) - parseInt(b.ordem)) ?? []
    )
  }, [productQuery.data])

  function nextStep() {
    setStep((step) => step + 1)
  }

  function renderProducts() {
    return (
      <>
        <MediaQuery minWidth={992}>
          <Row>
            {products.map((p) => (
              <Col
                span={24 / products.length}
                key={p.nome}
                className='productContainer center'
              >
                <Card
                  className={`clickable selected-${
                    selectedProduct && p.id === selectedProduct.id
                  }`}
                  title={<h4>{p.nome}</h4>}
                  onClick={() => setSelectedProduct(p)}
                >
                  {TextAreaType.display(p.desc)}
                  {CurrencyType.display(p.preco)}
                </Card>
              </Col>
            ))}
          </Row>
        </MediaQuery>
        <MediaQuery maxWidth={991}>
          <List>
            {products.map((p) => (
              <List.Item
                key={p.nome}
                className={`active-${
                  !!selectedProduct && p.id === selectedProduct.id
                }`}
                onClick={() => {
                  setSelectedProduct(p)
                  nextStep()
                }}
              >
                <List.Item.Meta
                  title={`${p.nome} - ${CurrencyType.format(p.preco)}`}
                  description={TextAreaType.display(p.desc)}
                />
              </List.Item>
            ))}
          </List>
        </MediaQuery>
      </>
    )
  }

  function renderBuyButton() {
    return (
      <Row>
        {products.map((p) => (
          <Col key={p.id} span={24 / products.length} className='center'>
            {selectedProduct.id === p.id && (
              <Button size='large' type='primary' onClick={nextStep}>
                Comprar agora!
              </Button>
            )}
          </Col>
        ))}
      </Row>
    )
  }

  function renderCheckout() {
    return (
      <Row>
        <Col span={24} className='center'>
          <Card title={<h4>{selectedProduct.nome}</h4>}>
            {TextAreaType.display(selectedProduct.desc)}
            {CurrencyType.display(selectedProduct.preco)}
          </Card>
          <LoaderContent isLoading={isProcessingPayment}>
            <Alert
              type='warning'
              message={
                <>
                  <p>
                    Ao clicar no botão abaixo, você deverá informar os dados do
                    cartão de crédito para efetuar o pagamento. Fique tranquilo
                    pois nosso site é seguro!
                  </p>
                  <p>
                    Caso prefira fazer o pagamento por transferência bancária,
                    entre em contato conosco por telefone ou WhatsApp:
                  </p>
                  <a
                    href='https://api.whatsapp.com/send?phone=5514981782825'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    (14) 98178-2825
                  </a>
                </>
              }
            />
            <Checkout
              product={selectedProduct}
              maxInstallments={2}
              onApprove={() => setIsProcessingPayment(true)}
              onSuccess={() => {
                setIsProcessingPayment(false)
                nextStep()
              }}
            />
          </LoaderContent>
        </Col>
      </Row>
    )
  }

  function renderConfirmation() {
    return (
      <>
        <Alert
          type='success'
          className='center'
          message='Pagamento efetuado com sucesso!'
        />
        <Button
          className='pull-right'
          size='large'
          type='primary'
          onClick={() => props.refreshUserData()}
        >
          Continuar
        </Button>
      </>
    )
  }

  return (
    <PageWrapper isLoading={productQuery.isLoading}>
      <VerticalSpace>
        <Tabs activeKey={String(step)} onChange={(key) => setStep(key)}>
          <TabPane
            key={String(STEP_CHOOSE_PRODUCT)}
            tab='1. Selecione um pacote'
            disabled={step === STEP_CONFIRMATION}
          >
            {renderProducts()}
            {selectedProduct !== null && renderBuyButton()}
          </TabPane>
          <TabPane
            key={String(STEP_PAYMENT)}
            tab='2. Efetue o pagamento'
            disabled={step < STEP_PAYMENT || step === STEP_CONFIRMATION}
          >
            {step === STEP_PAYMENT && renderCheckout()}
          </TabPane>
          <TabPane
            key={String(STEP_CONFIRMATION)}
            tab='3. Confirmação'
            disabled={step < STEP_CONFIRMATION}
          >
            {renderConfirmation()}
          </TabPane>
        </Tabs>
        <div />
        <div>
          <h3>Como funciona?</h3>
        </div>
        <div>
          <h4>Pacotes de Simulados</h4>
          <p>
            Nosso pacote #SimVestibulinho conta com 2 simulados online de nível
            fundamental e é destinado a alunos que prestarão vestibulinhos como
            CTI (Bauru), CTA (Jaboticabal), CTI (Guaratinguetá), Instituto
            Embraer, COTUCA (Unicamp) e Colégio Naval.
          </p>
          <p>
            Cada simulado inclui 50 questões de múltipla escolha e uma redação,
            que deverá ser digitada diretamente no sistema. Os simulados podem
            ser feitos a qualquer momento e não há limite de tempo, mas
            sugerimos que o aluno resolva em 4 horas e 30 minutos. O gabarito, o
            número de acertos e a resolução comentada serão disponibilizados
            automaticamente após o envio das respostas. Já a correção da redação
            será enviada para o aluno em até 3 dias úteis.
          </p>
        </div>
        <div>
          <h4>Pacotes de Redações</h4>
          <p>
            O pacote #RedVestibulinho é destinado a alunos que prestarão
            vestibulinhos de nível fundamental: CTI (Bauru), CTA (Jaboticabal),
            CTI (Guaratinguetá), Instituto Embraer, COTUCA (Unicamp) e Colégio
            Naval. Já o pacote #RedENEM é para os alunos de nível médio e conta
            com correções mais sofisticadas segundo os critérios de avaliação do
            ENEM.
          </p>
          <p>
            Ao adquirir o seu pacote de redações, você receberá temas de
            assuntos diversos. Após escrever o seu texto, deverá submeter sua
            redação e em até 3 dias úteis a correção estará disponível com
            feedback geral sobre o texto, indicando seus pontos fracos e pontos
            fortes. Além disso, os alunos recebem uma videoaula exclusiva sobre
            o texto dissertativo e outra com dicas super valiosas que os
            auxiliarão na confecção da escrita.
          </p>
        </div>
      </VerticalSpace>
    </PageWrapper>
  )
}
