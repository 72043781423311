import { CheckIcon, CrossIcon } from '../../../ui/Icons'

import { BaseType } from '../BaseType'
import React from 'react'
import { Switch } from 'antd'

export class BooleanType extends BaseType {
  static getDefaultTypeParams() {
    return BaseType.extendTypeParams({
      valueDefault: false,
      displayTrue: <CheckIcon />,
      displayFalse: <CrossIcon />,
      formatEmpty: '---',
      formatTrue: 'sim',
      formatFalse: 'não',
      filters: this.fromParams(
        ({ formatTrue, formatFalse }) => [
          { text: formatTrue, value: true },
          { text: formatFalse, value: false },
        ],
        ['formatTrue', 'formatFalse']
      ),
      validationTriggers: ['change'],
    })
  }

  static renderer = ({
    value,
    onChange,
    readOnly,
    disabled,
    displayTrue,
    displayFalse,
  }) => {
    return (
      <Switch
        checked={value}
        onChange={(checked) => onChange(checked)}
        checkedChildren={displayTrue}
        unCheckedChildren={displayFalse}
        readOnly={readOnly}
        disabled={disabled}
      />
    )
  }

  static formatter = (value, { formatTrue, formatFalse }) =>
    value ? formatTrue : formatFalse

  static validators = (value) => [value === true || value === false]
}
