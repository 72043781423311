import { Card } from 'antd'
import React from 'react'
import { TextAreaType } from './TextAreaType'
import { WrapIf } from '../../../utils'

export class HtmlType extends TextAreaType {
  static getDefaultTypeParams() {
    return TextAreaType.extendTypeParams({
      formatAsComponent: true,
      showBorder: true,
    })
  }

  static renderer = ({ value, onChange, ...typeParams }) => {
    return (
      <>
        {TextAreaType.render(
          value,
          onChange,
          TextAreaType.filterParams(typeParams)
        )}
        {HtmlType.displayer({ value, ...typeParams, showBorder: true })}
      </>
    )
  }

  static displayer = ({ value, showBorder }) => (
    <WrapIf condition={showBorder} wrapper={Card}>
      <div dangerouslySetInnerHTML={{ __html: value }} />
    </WrapIf>
  )

  static formatter = (value) => (
    <div
      dangerouslySetInnerHTML={{ __html: value }}
      style={{ display: 'inline' }}
    />
  )
}
