import { Radio, Typography } from 'antd'
import React, { useEffect, useState } from 'react'

import EssaySheet from './EssaySheet'
import { FileType } from '../data/fieldtypes'
import VerticalSpace from '../ui/VerticalSpace'
import styled from 'styled-components'
import { useResponsiveness } from '../../libs/hooksLib'

const Contents = styled.div`
  margin: 0 auto 80px auto;

  > .ant-radio-group {
    width: 100%;
    > label {
      width: 50%;
      text-align: center;
    }
  }

  @media (min-width: 880px) {
    width: 600px;
    .input-container > div {
      height: 227.6px;
    }
  }
`

export default function EssayPopupSubmitView({
  status,
  essayType,
  essayText,
  essayFile,
  onChangeText,
  onChangeFile,
  setIsEmpty,
}) {
  const readOnly = status !== 'pendente'
  const isCourseEssay = essayType === 'Curso CTI/ETEC'
  const [internalEssayText, setEssayText] = useState(essayText)
  const [internalEssayFile, setEssayFile] = useState(essayFile)
  const { isTablet } = useResponsiveness()
  const [inputType, setInputType] = useState(
    !FileType.isEmpty(internalEssayFile) || (isTablet && isCourseEssay)
      ? 'file'
      : 'text'
  )

  useEffect(() => {
    if (inputType === 'text') {
      onChangeText(internalEssayText)
      onChangeFile(null)
    } else {
      onChangeText(null)
      onChangeFile(internalEssayFile)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputType, internalEssayText, internalEssayFile])

  return (
    <VerticalSpace>
      {readOnly ? (
        <Typography.Text type='secondary'>
          Essa redação já foi enviada e não pode ser alterada. Você receberá um
          email quando a correção estiver disponível.
        </Typography.Text>
      ) : (
        <Typography.Text type='secondary'>
          Clique no título do tema acima para fazer o download do material de
          apoio. Envie sua redação abaixo e clique em{' '}
          <b>Enviar para correção</b> quando terminar. Após o envio, não será
          possível editá-la. Se quiser, você pode salvar um rascunho e voltar
          mais tarde para terminar, basta clicar em <b>Salvar para depois</b>.
        </Typography.Text>
      )}
      <Contents>
        {isCourseEssay && !readOnly && !isTablet && (
          <Radio.Group
            value={inputType}
            buttonStyle='solid'
            onChange={(e) => setInputType(e.target.value)}
          >
            <Radio.Button value='text'>Escrever</Radio.Button>
            <Radio.Button value='file'>Enviar PDF</Radio.Button>
          </Radio.Group>
        )}
        <div className='input-container'>
          {inputType === 'text' ? (
            <EssaySheet
              value={internalEssayText}
              onBlur={setEssayText}
              onEmpty={setIsEmpty}
              readOnly={readOnly}
            />
          ) : (
            FileType.render(internalEssayFile, setEssayFile, {
              type: 'pdf',
              readOnly,
            })
          )}
        </div>
      </Contents>
    </VerticalSpace>
  )
}
