import { Button } from 'antd'
import { Link } from 'react-router-dom'
import Logo from '../components/ui/Logo'
import React from 'react'
import styled from 'styled-components'

const StyledContainer = styled.div`
  text-align: center;
  padding-bottom: 30px;

  & button {
    width: 200px;
  }

  & > img {
    display: block;
    margin: 10px auto 30px auto;
    width: 300px;
  }

  & > p {
    font-size: 21px;
    font-weight: 200;
    color: rgb(51, 51, 51);
    margin: 60px 0px;
  }
`

export default function LanderPage() {
  return (
    <StyledContainer>
      <Logo />
      <p>
        A escola que mais aprova em Bauru agora está online. Cadastre-se já!
      </p>
      <Link to='/cadastro'>
        <Button type='primary' size='large'>
          Criar minha conta
        </Button>
      </Link>
      <Link to='/entrar'>
        <Button size='large'>Já tenho uma conta</Button>
      </Link>
    </StyledContainer>
  )
}
