import { fn, getNested } from '../../libs/utils'

import DataFormItemController from './DataFormItemController'
import React from 'react'

export default function DataFormContents({
  dataList,
  schema,
  user,
  fields,
  readOnly = false,
  disabled = false,
}) {
  return (
    <>
      {fields.map((fieldSettings, index) => {
        const name = fieldSettings.name
        const type = schema[name]
        const fieldPath = fieldSettings.fieldPath ?? name
        const fullFieldPath = fieldSettings.fullFieldPath ?? fieldPath
        return (
          <DataFormItemController
            dataList={dataList}
            key={fieldPath ?? index}
            className={type ? type.name : 'UnknownType'}
            user={user}
            type={type}
            fieldPath={fieldPath}
            fullFieldPath={fullFieldPath}
            label={fieldSettings.label}
            help={readOnly ? undefined : fieldSettings.help}
            typeParamsFn={
              fieldSettings.typeParamsFn ?? fn(fieldSettings.typeParams ?? {})
            }
            hiddenFn={fieldSettings.hiddenFn ?? fn(fieldSettings.hidden)}
            optionalFn={fieldSettings.optionalFn ?? fn(fieldSettings.optional)}
            disabledFn={
              disabled
                ? fn(true)
                : fieldSettings.disabledFn ?? fn(fieldSettings.disabled)
            }
            readOnlyFn={
              readOnly
                ? fn(true)
                : fieldSettings.readOnlyFn ?? fn(fieldSettings.readOnly)
            }
            renderFn={fieldSettings.render}
            onChange={fieldSettings.onChange}
            shouldUpdate={
              !name
                ? true
                : (prev, next) =>
                    type.shouldUpdate(
                      getNested(prev, fullFieldPath),
                      getNested(next, fullFieldPath)
                    ) ||
                    fieldSettings.dependencies?.some(
                      (d) => getNested(prev, d) !== getNested(next, d)
                    )
            }
          />
        )
      })}
    </>
  )
}
