import styled, { css } from 'styled-components'

import { BaseArrayType } from './BaseArrayType'
import IconButton from '../../../ui/IconButton'
import React from 'react'

const ArrayItem = styled.div`
  border: 1px solid #ccc;
  border-bottom: none;
  border-radius: 4px;
  display: flex;

  & > .item-contents {
    flex-grow: 1;
  }

  & > .item-prefix,
  & > .item-label {
    flex-grow: 0;
    background-color: #f5f5f5;
  }

  & > .item-label {
    width: 40px;
    min-width: 40px;
    padding-left: 15px;
    line-height: 34px;
    ${({ $selected }) => $selected && 'background-color: var(--theme-color);'}
  }

  & > .item-contents,
  & > .item-contents > * {
    ${({ $selected }) =>
      $selected && 'background-color: var(--theme-color-medium);'}
  }

  ${(props) =>
    props.$clickable &&
    css`
      & > .item-label {
        cursor: pointer;
      }
      & > .item-contents,
      & > .item-contents > * {
        cursor: pointer;
      }
      &:hover > .item-label {
        background-color: ${props.$selected
          ? 'var(--theme-color)'
          : 'var(--theme-color-light)'};
      }
    `}
`

export class ArrayListType extends BaseArrayType {
  static getDefaultTypeParams() {
    return BaseArrayType.extendTypeParams({
      onSelect: undefined,
      selectedIndex: undefined,
      renderArray: this.renderArray,
      displayAs: this.fromParams(
        ({ isFormControlled }) => (isFormControlled ? 'text' : 'custom'),
        ['isFormControlled']
      ),
    })
  }

  static renderArray({
    childrenProps,
    renderChild,
    onAdd,
    onRemove,
    showAddRemoveButtons,
    allowAddItems,
    allowRemoveItems,
    getItemLabel,
    onSelect,
    selectedIndex,
  }) {
    return (
      <>
        {childrenProps.map((childProps, index) => (
          <ArrayItem
            key={childProps.key}
            onClick={!onSelect ? undefined : () => onSelect(index)}
            $clickable={!!onSelect}
            $selected={selectedIndex === index}
          >
            {getItemLabel && (
              <div className='item-label'>{getItemLabel(index)}</div>
            )}
            <div className='item-contents'>{renderChild(childProps)}</div>
            <IconButton.Remove
              className='item-prefix'
              text=''
              danger={false}
              onClick={() => onRemove(index)}
              hidden={!showAddRemoveButtons}
              disabled={!allowRemoveItems}
            />
          </ArrayItem>
        ))}
        {
          <ArrayItem key='new'>
            <IconButton.New
              className='item-prefix'
              type='default'
              text=''
              block
              onClick={onAdd}
              hidden={!showAddRemoveButtons}
              disabled={!allowAddItems}
            />
          </ArrayItem>
        }
      </>
    )
  }
}
