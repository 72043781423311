import { BaseType } from '../BaseType'
import { Card } from 'antd'
import DataFormContents from '../../DataFormContents'
import React from 'react'
import { WrapIf } from '../../../utils'
import { mapObject } from '../../../../libs/utils'

export class ObjectType extends BaseType {
  static getDefaultTypeParams() {
    return BaseType.extendTypeParams({
      isSelfControlled: true,
      valueDefault: this.fromParams(
        ({ schema }) =>
          mapObject(
            schema,
            (_, typeClass) => typeClass.getParams().valueDefault
          ),
        ['schema']
      ),
      formatEmpty: '---',
      formatObject: (fields) => JSON.stringify(fields),
      schema: {},
      formFields: [],
      showBorder: true,
      validationTriggers: ['change'],
      displayAs: 'input',
      optional: true,
    })
  }

  static isEmpty = (value) => value === undefined

  static shouldUpdate = () => false

  static renderer = ({
    fieldPath,
    fullFieldPath,
    readOnly,
    disabled,
    schema,
    user,
    formFields,
    showBorder,
  }) => {
    return (
      <WrapIf condition={showBorder} wrapper={Card}>
        <DataFormContents
          schema={schema}
          user={user}
          fields={formFields.map((field) =>
            field.name
              ? {
                  ...field,
                  fieldPath: `${fieldPath}.${field.name}`,
                  fullFieldPath: `${fullFieldPath}.${field.name}`,
                }
              : field
          )}
          readOnly={readOnly}
          disabled={disabled}
        />
      </WrapIf>
    )
  }

  static formatter = (value, { getRecord, user, formatObject, schema }) =>
    formatObject(
      mapObject(schema, (field, childType) =>
        childType.format(value[field], { getRecord, user })
      )
    )

  static validators = () => []

  static comparator = (value1, value2, { schema }) =>
    value1 === value2 ||
    Object.entries(schema).every(
      ([field, type]) => type.compare(value1[field], value2[field]) === 0
    )
      ? 0
      : undefined
}
