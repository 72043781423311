export {
  ClockCircleOutlined as ClockIcon,
  LoadingOutlined as LoadingIcon,
  CheckOutlined as CheckIcon,
  WarningOutlined as WarningIcon,
  PlusOutlined as PlusIcon,
  DeleteOutlined as DeleteIcon,
  FileDoneOutlined as FileDoneIcon,
  PaperClipOutlined as PaperClipIcon,
  CloseOutlined as CrossIcon,
  LockOutlined as LockIcon,
  UnlockOutlined as UnlockIcon,
  AreaChartOutlined as GraphIcon,
  EditOutlined as EditIcon,
  RollbackOutlined as BackIcon,
  SendOutlined as SubmitIcon,
  DownloadOutlined as DownloadIcon,
  UploadOutlined as UploadIcon,
  ReloadOutlined as ReloadIcon,
  PauseOutlined as PauseIcon,
  LeftOutlined as PreviousIcon,
  RightOutlined as NextIcon,
  BulbFilled as BulbIcon,
  HistoryOutlined as HistoryIcon,
  DollarCircleOutlined as CashIcon,
  InboxOutlined as InboxIcon,
  FunctionOutlined as EquationIcon,
  CopyOutlined as CopyIcon,
  LinkOutlined as LinkIcon,
  YoutubeOutlined as YoutubeIcon,
  UserOutlined as UserIcon,
  AuditOutlined as AuditIcon,
  ConsoleSqlOutlined as ConsoleIcon,
  ExperimentOutlined as TestIcon,
  IdcardOutlined as StudentIcon,
  ContactsOutlined as TeacherIcon,
  FileMarkdownOutlined as SubjectIcon,
  ClusterOutlined as CourseIcon,
  ReadOutlined as CoursePinnedResourceIcon,
  BookOutlined as CourseResourceIcon,
  CalendarOutlined as CourseCalendarIcon,
  VideoCameraOutlined as CourseVideoIcon,
  GroupOutlined as ProductIcon,
  BlockOutlined as ProductResourceIcon,
  FundViewOutlined as TransactionIcon,
  FileSearchOutlined as EssayCorrectionIcon,
  ProfileOutlined as EssayThemeIcon,
  FileTextOutlined as EssaySubmissionIcon,
  FileZipOutlined as ExamIcon,
  FileUnknownOutlined as ExamQuestionIcon,
  QuestionCircleOutlined as ContactIcon,
  SearchOutlined as SearchIcon,
  CameraOutlined as CameraIcon,
  FileTextOutlined as TextIcon,
  FilterOutlined as FilterIcon,
  CaretUpOutlined as ArrowUpIcon,
  CaretDownOutlined as ArrowDownIcon,
  EyeOutlined as VisibleIcon,
  QuestionCircleOutlined as QuestionMarkIcon,
  TableOutlined as TableIcon,
} from '@ant-design/icons'
