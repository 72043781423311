import {
  ArrayType,
  DateType,
  FileListType,
  RefType,
  TextAreaType,
  TextType,
  YoutubeType,
} from '../../components/data/fieldtypes';
import {
  appRoutes,
  useRouteConfig,
} from '../../components/routing/routingConfig';

import CRUDPage from '../../components/data/CRUDPage';
import React from 'react';
import { useResponsiveness } from '../../libs/hooksLib';

export default function CourseResources(props) {
  const { isMobile } = useResponsiveness();
  const routeConfig = useRouteConfig();
  const isStudentView = props.user.group === 'student';
  const isVideoView =
    routeConfig.name === 'videos' || routeConfig.name === 'studentVideos';
  const isPinnedResourcesView =
    routeConfig.name === 'pinnedResources' ||
    routeConfig.name === 'studentPinnedResources';
  const isCalendarsView =
    routeConfig.name === 'calendars' || routeConfig.name === 'studentCalendars';
  return (
    <CRUDPage
      user={props.user}
      history={props.history}
      params={props.match.params}
      resource='/course-resource'
      dependencies={{
        courseQuery: '/course',
        subjectQuery: '/subject',
      }}
      schema={({ courseQuery, subjectQuery }) => ({
        id_courses: new ArrayType({
          childrenType: new RefType({
            query: courseQuery,
            display: 'name',
            routeConfig: appRoutes.courses.root,
          }),
        }),
        id_subject: new RefType({
          query: subjectQuery,
          display: 'name',
          routeConfig: appRoutes.admin.subjects,
        }),
        dtavailable: new DateType({
          valueDefault: DateType.now(),
          displayShort: true,
        }),
        title: new TextType({ unique: true }),
        description: new TextAreaType({ optional: true }),
        pinned_resources: new FileListType(),
        resources: new FileListType(),
        video: new YoutubeType(),
        calendars: new FileListType(),
      })}
      listColumns={[
        {
          header: 'Cursos',
          name: 'id_courses',
          hidden: isStudentView,
        },
        {
          header: 'Matéria',
          name: 'id_subject',
          hidden: isCalendarsView || (isStudentView && isMobile),
        },
        {
          header: 'Título',
          name: 'title',
          ellipsis: true,
        },
        {
          header: 'Data',
          name: 'dtavailable',
        },
      ]}
      formFields={[
        {
          name: 'id_courses',
          label: 'Cursos',
        },
        {
          name: 'id_subject',
          label: 'Matéria',
          hidden: isCalendarsView,
        },
        {
          name: 'dtavailable',
          label: 'Data',
        },
        {
          name: 'title',
          label: 'Título',
        },
        {
          name: 'description',
          label: 'Descrição',
        },
        {
          name: isVideoView
            ? 'video'
            : isPinnedResourcesView
            ? 'pinned_resources'
            : isCalendarsView
            ? 'calendars'
            : 'resources',
          label: isVideoView
            ? 'Vídeo'
            : isPinnedResourcesView
            ? 'Apostilas'
            : isCalendarsView
            ? 'Calendários'
            : 'Arquivos',
        },
      ]}
      listOptions={{
        sortFunction: (record) => [
          record.dtavailable,
          record.id_subject,
          record.title,
        ],
        sortFunctionOrder: ['desc', 'asc', 'asc'],
        filterFunction: !isStudentView
          ? (record) =>
              isVideoView === !!record.video &&
              isPinnedResourcesView === record.pinned_resources?.length > 0 &&
              isCalendarsView === record.calendars?.length > 0
          : (record, getSchemaProxy) => {
              const schema = getSchemaProxy();
              return (
                isVideoView === !!record.video &&
                isPinnedResourcesView === record.pinned_resources?.length > 0 &&
                isCalendarsView === record.calendars?.length > 0 &&
                record.id_courses.includes(props.user.course) &&
                schema.dtavailable.compare(schema.dtavailable.type.now()) !== 1
              );
            },
        expandFunction: !isStudentView
          ? undefined
          : (record, getSchemaProxy) => {
              const schema = getSchemaProxy();
              return (
                <>
                  {schema.description.display()}
                  {<br />}
                  {isVideoView
                    ? schema.video.display()
                    : isPinnedResourcesView
                    ? schema.pinned_resources.display()
                    : isCalendarsView
                    ? schema.calendars.display()
                    : schema.resources.display()}
                </>
              );
            },
      }}
      crudOptions={{
        show: {
          create: !isStudentView,
        },
      }}
    />
  );
}
