import PageWrapper from '../../components/ui/PageWrapper'
import React from 'react'
import styled from 'styled-components'
import { useResources } from '../../components/data/crud.hooks'

const Row = styled.div`
  display: flex;
`

function duplicateExamAnswers(examAnswers) {
  const flagMap = {}
  const duplicates = []

  examAnswers.forEach((examAnswer) => {
    const { user_id: user, id_exam: exam } = examAnswer
    flagMap[user] = flagMap[user] ?? {}
    flagMap[user][exam] = flagMap[user][exam] ?? []
    flagMap[user][exam].push(examAnswer.id)
    if (flagMap[user][exam].length === 2) {
      duplicates.push([user, exam])
    }
  })

  if (duplicates.length === 0) {
    return <div>Nenhum resultado de simulado duplicado.</div>
  } else {
    return (
      <div>
        <div>{duplicates.length} resultados de simulado duplicados.</div>
        <Row>
          <div>Simulado</div>
          <div>Usuário</div>
          <div>Registros</div>
        </Row>
        {duplicates.map(([user, exam]) => (
          <Row key={`${user}-${exam}`}>
            <div>{exam}</div>
            <div>{user}</div>
            <div>{flagMap[user][exam].join(', ')}</div>
          </Row>
        ))}
      </div>
    )
  }
}

export default function Audit() {
  const queries = useResources(['/exam-answer'])

  const isLoading = queries.some((q) => q.isLoading)
  const [examAnswerQuery] = queries

  return (
    <PageWrapper isLoading={isLoading}>
      {!isLoading && [duplicateExamAnswers(examAnswerQuery.data)]}
    </PageWrapper>
  )
}
