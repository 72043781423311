import {
  ArrayType,
  FileType,
  HtmlType,
  RefType,
  TextType,
  YoutubeType,
} from '../../components/data/fieldtypes'

import CRUDPage from '../../components/data/CRUDPage'
import React from 'react'
import { appRoutes } from '../../components/routing/routingConfig'

export default function EssayResources(props) {
  return (
    <CRUDPage
      user={props.user}
      history={props.history}
      params={props.match.params}
      resource='/material'
      dependencies={{
        productQuery: '/produto',
      }}
      schema={({ productQuery }) => ({
        ordem: new TextType(),
        titulo: new TextType({ unique: true }),
        desc: new HtmlType({ optional: true }),
        video: new YoutubeType({ optional: true }),
        material: new FileType({ optional: true, type: 'pdf' }),
        products: new ArrayType({
          childrenType: new RefType({
            query: productQuery,
            display: 'nome',
            sort: 'ordem',
            routeConfig: appRoutes.admin.servicePacks,
          }),
        }),
      })}
      listColumns={[
        {
          header: 'Ordem',
          name: 'ordem',
        },
        {
          header: 'Título',
          name: 'titulo',
        },
        {
          header: 'Pacotes',
          name: 'products',
        },
        {
          header: 'Material',
          name: 'material',
          align: 'right',
          sort: false,
        },
      ]}
      formFields={[
        {
          name: 'ordem',
          label: 'Ordem',
        },
        {
          name: 'titulo',
          label: 'Título',
        },
        {
          name: 'desc',
          label: 'Descrição (HTML)',
        },
        {
          name: 'video',
          label: 'Vídeo',
        },
        {
          name: 'material',
          label: 'Material (PDF)',
        },
        {
          name: 'products',
          label: 'Pacotes',
        },
      ]}
    />
  )
}
