import { ArrayType, RichTextType } from '../../data/fieldtypes'
import { Card, Tabs } from 'antd'

import ExamEssay from './ExamEssay'
import IconButton from '../../ui/IconButton'
import React from 'react'
import VerticalSpace from '../../ui/VerticalSpace'
import styled from 'styled-components'

const StyledContainer = styled.div`
  & .footer {
    margin-top: 30px;
  }
`

export default function ExamBody({
  readOnly = false,
  selectedSubject,
  onSelectSubject,
  subjectQuestions,
  essayTheme,
  answers,
  onChangeEssay,
  onSubmitAnswer,
}) {
  const indexToAnswer = (i) => (i === undefined ? 'none' : 'abcd'[i])
  const answerToIndex = (a) => 'abcd'.indexOf(a)

  return (
    <StyledContainer>
      <Tabs
        activeKey={selectedSubject}
        onChange={(key) => onSelectSubject(key)}
      >
        {subjectQuestions.map((subject, subjectIndex) => (
          <Tabs.TabPane key={subject.id} tab={subject.name}>
            {subject.variant === 'essay' ? (
              <ExamEssay
                essayTheme={essayTheme}
                essayText={answers.essay}
                onChangeEssay={onChangeEssay}
              />
            ) : (
              <VerticalSpace>
                {subject.questions.map((question) =>
                  question.intro ? (
                    <div key={`intro${question.index}`}>
                      {RichTextType.display(question.intro)}
                    </div>
                  ) : (
                    <Card
                      key={`question${question.index}`}
                      title={<h4>Questão {question.index}</h4>}
                    >
                      {RichTextType.display(question.question)}
                      {ArrayType.display(question.alternatives, {
                        childrenType: RichTextType,
                        getItemLabel: (i) => indexToAnswer(i) + ')',
                        onSelect: readOnly
                          ? undefined
                          : (i) =>
                              onSubmitAnswer(question.index, indexToAnswer(i)),
                        selectedIndex: answerToIndex(answers[question.index]),
                      })}
                    </Card>
                  )
                )}
              </VerticalSpace>
            )}
            <div className='footer'>
              <IconButton.Previous
                className='pull-left'
                type='primary'
                text={
                  subjectIndex > 0 &&
                  `Voltar para ${subjectQuestions[subjectIndex - 1].name}`
                }
                hidden={subjectIndex === 0}
                onClick={() =>
                  onSelectSubject(subjectQuestions[subjectIndex - 1].id)
                }
              />
              <IconButton.Next
                className='pull-right'
                type='primary'
                text={
                  subjectIndex < subjectQuestions.length - 1 &&
                  `Ir para ${subjectQuestions[subjectIndex + 1].name}`
                }
                hidden={subjectIndex === subjectQuestions.length - 1}
                onClick={() =>
                  onSelectSubject(subjectQuestions[subjectIndex + 1].id)
                }
              />
            </div>
          </Tabs.TabPane>
        ))}
      </Tabs>
    </StyledContainer>
  )
}
