import { useMutation, useQueries, useQuery } from 'react-query'

import { API } from 'aws-amplify'
import { queryClient } from '../../config/cache'

export const NEW_RECORD = 'novo'

export function useResources(resources = []) {
  return useQueries(resources.map((queryKey) => ({ queryKey }))).map(
    (query) => ({
      findById: (id) =>
        query.isLoading ? undefined : query.data.find((r) => r.id === id),
      ...query,
    })
  )
}

export function useCRUD(listResource, recordResource, recordId) {
  recordResource = recordResource ?? listResource
  const listQuery = useQuery(listResource)
  const recordQuery = useQuery({
    queryKey: [recordResource, recordId],
    enabled: !!recordId && recordId !== NEW_RECORD,
  })

  const recordMutation = useMutation(
    async ({ action, id, record }) => {
      switch (action) {
        case 'create':
          await API.post('api', recordResource, { body: record })
          break
        case 'update':
          await API.put('api', `${recordResource}/${id}`, { body: record })
          break
        case 'delete':
          await API.del('api', `${recordResource}/${id}`)
          break
        default:
      }
    },
    {
      onMutate: async ({ action, id, record, predictedUpdate }) => {
        predictedUpdate = predictedUpdate ?? { ...record }
        if (action !== 'delete') {
          predictedUpdate.id = predictedUpdate.id ?? String(Math.random())
        }
        queryClient.invalidateQueries([recordResource, id])
        await queryClient.cancelQueries(listResource)
        await queryClient.cancelQueries([recordResource, id])
        const previousList = queryClient.getQueryData(listResource)
        const previousRecord = queryClient.getQueryData([recordResource, id])
        queryClient.setQueryData([recordResource, id], record)
        queryClient.setQueryData(listResource, () => {
          switch (action) {
            case 'create':
              return [...previousList, predictedUpdate]
            case 'update':
              return previousList.map((rec) =>
                rec.id === id ? predictedUpdate : rec
              )
            case 'delete':
              return previousList.filter((rec) => rec.id !== id)
            default:
          }
        })
        return { id, previousList, previousRecord }
      },
      onError: (err, data, context) => {
        queryClient.setQueryData(listResource, context.previousList)
        queryClient.setQueryData(
          [recordResource, context.id],
          context.previousRecord
        )
      },
      onSettled: () => {
        queryClient.invalidateQueries(listResource)
        queryClient.invalidateQueries(recordResource)
      },
    }
  )

  function saveRecord(record, predictedUpdate, callbacks) {
    if (record.id) {
      recordMutation.mutate(
        {
          action: 'update',
          id: record.id,
          record,
          predictedUpdate,
        },
        callbacks
      )
    } else {
      recordMutation.mutate(
        {
          action: 'create',
          record,
          predictedUpdate,
        },
        callbacks
      )
    }
  }

  function deleteRecord(id, callbacks) {
    recordMutation.mutate({ action: 'delete', id }, callbacks)
  }

  return {
    list: listQuery.data,
    record: recordQuery.data,
    isLoading: listQuery.isLoading || recordQuery.isLoading,
    saveRecord,
    deleteRecord,
  }
}
