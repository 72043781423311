import {
  JsonParam,
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params'
import { createContext, useCallback, useContext } from 'react'

import React from 'react'
import { filterObject } from '../../libs/utils'

const ViewSettingsContext = createContext(null)

export const ViewSettingsProvider = ({ children }) => {
  const [viewSettings, setViewSettings] = useQueryParams({
    filters: withDefault(JsonParam, {}),
    sort: StringParam,
    page: withDefault(NumberParam, 1),
    pageSize: withDefault(NumberParam, 10),
  })

  const update = useCallback(
    ({ pagination, filters, sorter }) => {
      const settings = {}
      if (pagination) {
        settings.page = pagination.current === 0 ? 1 : pagination.current
        settings.pageSize = pagination.pageSize
      }
      if (filters) {
        settings.filters = filterObject(filters, (key, value) => value !== null)
      }
      if (sorter) {
        settings.sort =
          sorter.order === undefined
            ? undefined
            : `${sorter.order === 'ascend' ? '' : '-'}${sorter.field}`
      }
      setViewSettings(settings)
    },
    [setViewSettings]
  )

  return (
    <ViewSettingsContext.Provider value={{ ...viewSettings, update }}>
      {children}
    </ViewSettingsContext.Provider>
  )
}

export function useViewSettings() {
  return useContext(ViewSettingsContext)
}
