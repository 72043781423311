import React, { useRef, useState } from 'react'

import styled from 'styled-components'

const SheetContainer = styled.div`
  width: 600px;
  margin: 0 auto;

  & > div {
    position: absolute;
    overflow: hidden;
    height: ${({ $lineHeight, $rows }) => $lineHeight * $rows}px;
  }

  & > div > div,
  & > textarea {
    line-height: ${({ $lineHeight }) => $lineHeight}px;
  }

  & > div > div {
    width: 50px;
    text-align: center;
    color: #999;
  }

  & > textarea {
    resize: none;
    width: 100%;
    padding: 0px 20px 0 70px;
    overflow-y: scroll;
    border: 1px solid #eee !important;
    box-shadow: 2px 2px 0 #ddd !important;
    background-color: #fff !important;
    background-attachment: local;
    font-family: 'Consolas', monospace;
    font-size: 15px;
    letter-spacing: 0px;
    tab-size: 2;
    background-size: 100% ${({ $lineHeight }) => $lineHeight}px;
    background-image: linear-gradient(
      to bottom,
      transparent,
      transparent ${({ $lineHeight }) => $lineHeight - 1}px,
      #e7eff8 0px
    );
  }
`

export default function EssaySheet({
  value,
  onChange,
  onBlur,
  onEmpty,
  readOnly,
  rows = 10,
  lineHeight = 30,
}) {
  const textarea = useRef(null)
  const lineNumbersDiv = useRef(null)
  const [renderedLines, setRenderedLines] = useState(rows)
  const [isNumOfRowsCalculated, setIsNumOfRowsCalculated] = useState(false)

  const updateRenderedLines = () => {
    setRenderedLines(
      Math.max(rows, Math.ceil(textarea.current.scrollHeight / lineHeight))
    )
    if (!isNumOfRowsCalculated) {
      setIsNumOfRowsCalculated(true)
    }
  }

  function handleScroll() {
    if (!isNumOfRowsCalculated) {
      updateRenderedLines()
    }
    lineNumbersDiv.current.scrollTop = textarea.current.scrollTop
  }

  function handleChange(newText) {
    const ta = textarea.current
    let parsedText = ''
    let [start, end] = [ta.selectionStart, ta.selectionEnd]
    for (let i = 0; i < newText.length; i++) {
      let char = newText[i]
      if (
        char === '\n' &&
        (i === 0 || (newText[i + 1] === '\n' && newText[i - 1] === '\n'))
      ) {
        char = ''
        if (parsedText.length < start) --start
        if (parsedText.length < end) --end
      } else if (
        char !== '\t' &&
        char !== '\n' &&
        (i === 0 || newText[i - 1] === '\n')
      ) {
        char = '\t' + char
        if (parsedText.length < start) ++start
        if (parsedText.length < end) ++end
      }
      parsedText += char
    }

    ta.value = parsedText
    ta.setSelectionRange(start, end)

    updateRenderedLines()

    if (onChange) {
      onChange(parsedText)
    }

    if (onEmpty) {
      onEmpty(parsedText.length === 0)
    }
  }

  return (
    <SheetContainer $lineHeight={lineHeight} $rows={rows}>
      <div ref={lineNumbersDiv}>
        {[...Array(renderedLines).keys()].map((line) => (
          <div key={line}>{line + 1}</div>
        ))}
      </div>
      <textarea
        ref={textarea}
        rows={rows}
        defaultValue={value}
        readOnly={readOnly}
        onChange={(e) => handleChange(e.target.value)}
        onBlur={
          readOnly || !onBlur ? undefined : () => onBlur(textarea.current.value)
        }
        onScroll={handleScroll}
      />
    </SheetContainer>
  )
}
