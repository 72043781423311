import { Route, Switch } from 'react-router-dom'

import Auth from '../../containers/Auth'
import AuthenticatedRoute from './AuthenticatedRoute'
import HomeRoute from './HomeRoute'
import NotFound from '../../containers/NotFound'
import React from 'react'
import UnauthenticatedRoute from './UnauthenticatedRoute'
import { appRoutesList } from './routingConfig'

export default function Routes({ appProps }) {
  return (
    <Switch>
      <HomeRoute path={['/']} exact appProps={appProps} />
      <UnauthenticatedRoute
        path={['/entrar']}
        exact
        component={Auth}
        appProps={appProps}
      />
      <UnauthenticatedRoute
        path={['/cadastro']}
        exact
        component={Auth}
        appProps={appProps}
      />
      {appRoutesList.map(({ routes }) =>
        routes.map((route) => (
          <AuthenticatedRoute
            key={route.path}
            path={[
              route.path,
              ...(route.allowChildPath ? [`${route.path}/:id`] : []),
            ]}
            access={
              route.allow instanceof Function
                ? route.allow(appProps.user)
                : route.allow
            }
            exact
            component={route.component}
            appProps={appProps}
          />
        ))
      )}
      <Route component={NotFound} />
    </Switch>
  )
}
