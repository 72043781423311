import { NumberType } from './NumberType'

export class CurrencyType extends NumberType {
  static getDefaultTypeParams() {
    return NumberType.extendTypeParams({
      currency: 'R$',
      precision: 2,
      prefix: this.fromParams(({ currency }) => `${currency} `, ['currency']),
      inputWidth: '120px',
    })
  }
}
