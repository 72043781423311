import Logo from '../ui/Logo'
import { Popover } from 'antd'
import React from 'react'
import { RichTextType } from '../data/fieldtypes'
import { sortArray } from '../../libs/utils'
import styled from 'styled-components'

const NUM_ROWS = 10
const ROW_HEIGHT = 35
const COLUMN_WIDTH = 130

const Header = styled.h1`
  text-align: center;

  > img {
    width: 300px;
    margin-bottom: 10px;
  }
`

const Container = styled.div`
  width: 100%;
  height: ${({ $rows }) => $rows * (ROW_HEIGHT + 1) + 1}px;
  display: flex;
  flex-flow: column wrap;
  align-content: center;
  font-size: 16px;

  > span {
    display: flex;
    width: ${COLUMN_WIDTH}px;
    margin: 0 5px;
    border: 1px solid black;
    border-bottom: none;
    cursor: pointer;

    :nth-child(2n + 1) {
      background: var(--theme-color-light);
    }

    :nth-child(${({ $rows }) => $rows}n),
    :last-child {
      border-bottom: 1px solid black;
    }

    :hover {
      background: var(--theme-color);
    }

    > span {
      flex: 1;
      line-height: ${ROW_HEIGHT}px;
      text-align: center;

      :first-child {
        border-right: 1px solid black;
      }
    }
  }
`

const CancelledLabel = styled.span`
  font-size: 10px;
  color: red;
  text-transform: uppercase;
  transform: rotate(-21deg);
`

const PopoverContents = styled.div`
  padding-top: 20px;
  max-width: 500px;
  max-height: 400px;
  overflow-y: auto;

  ol {
    margin-top: 10px;
  }

  li:nth-child(${(p) => p.$answerIndex})::marker {
    color: var(--theme-color-accent);
    content: '✓ ${(p) => p.$answer}.';
    font-weight: bold;
  }
`

export default function ExamAnswers({ examName, questionMap }) {
  const questions = sortArray(
    Object.values(questionMap).sort(),
    ({ index }) => index
  )
  return (
    <>
      <Header>
        <Logo />
        <div>Gabarito - {examName}</div>
      </Header>
      <Container $rows={NUM_ROWS}>
        {questions.map((q) => (
          <Popover
            key={q.index}
            trigger='click'
            placement={q.index < questions.length / 2 ? 'right' : 'left'}
            title={
              <PopoverContents
                $answer={q.answer}
                $answerIndex={'abcde'.indexOf(q.answer) + 1}
              >
                <div>
                  {RichTextType.display(q.question)}
                  <ol type='a'>
                    {q.alternatives.map((a, i) => (
                      <li key={i}>{RichTextType.display(a)}</li>
                    ))}
                  </ol>
                </div>
              </PopoverContents>
            }
          >
            <span>{q.index}</span>
            {q.cancelled ? (
              <CancelledLabel>Cancelada</CancelledLabel>
            ) : (
              <span>{q.answer.toUpperCase()}</span>
            )}
          </Popover>
        ))}
      </Container>
    </>
  )
}
