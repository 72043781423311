import { ArrowDownIcon, ArrowUpIcon } from '../../../ui/Icons'
import { DatePicker, TimePicker } from 'antd'

import { BaseType } from '../BaseType'
import IconButton from '../../../ui/IconButton'
import React from 'react'
import moment from 'moment'
import styled from 'styled-components'

const Container = styled.div`
  .ant-picker {
    width: ${({ $type }) =>
      ({
        dateTime: 160,
        date: 120,
        time: 85,
        dateTimeRange: 320,
        dateRange: 230,
        timeRange: 160,
      }[$type])}px;
  }
`

const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  > div {
    display: flex;
    align-items: center;

    > label {
      width: 50px;
    }

    > .anticon {
      flex: 1;

      &.disabled {
        color: #ccc;
      }

      &.enabled:hover {
        cursor: pointer;
        color: var(--theme-color-accent);
      }
    }
  }

  button {
    width: 100%;
  }
`

const parseEachValueIn = (storeFormat, timeOnly) => (value) =>
  // TODO [TZ issue]: use storeFormat here for all types after DateType
  // fields are fixed in the DB (they shouldn't have time at all)
  !value ? undefined : moment(value, timeOnly ? storeFormat : undefined)

const parseEachValueOut = (storeFormat) => (value) =>
  !value ? value : value.format(storeFormat)

export class DateTimeType extends BaseType {
  static getDefaultTypeParams() {
    return BaseType.extendTypeParams({
      rangePicker: false,
      parseRangeIn: ({ start, end }) => [start, end],
      parseRangeOut: ([start, end]) => ({ start, end }),
      formatRange: this.fromParams(
        ({ showDate, showTime, formatEmpty }) =>
          !showDate
            ? (start, end) =>
                start && end ? `${start} às ${end}` : formatEmpty
            : !showTime
            ? (start, end) => (start && end ? `${start} a ${end}` : formatEmpty)
            : (start, end) =>
                start && end ? `${start} até ${end}` : formatEmpty,
        ['showDate', 'showTime', 'formatEmpty']
      ),
      validationTriggers: ['change'],
      valueDefault: this.fromParams(
        ({ rangePicker }) => (rangePicker ? ['', ''] : ''),
        ['rangePicker']
      ),
      parseValueIn: this.fromParams(
        ({ rangePicker, parseRangeIn, storeFormat, showDate, showTime }) =>
          rangePicker
            ? (value) =>
                parseRangeIn(value).map(
                  parseEachValueIn(storeFormat, showTime && !showDate)
                )
            : (value) =>
                parseEachValueIn(storeFormat, showTime && !showDate)(value),
        ['rangePicker', 'parseRangeIn', 'storeFormat', 'showDate', 'showTime']
      ),
      parseValueOut: this.fromParams(
        ({ rangePicker, parseRangeOut, storeFormat }) =>
          rangePicker
            ? (value) =>
                parseRangeOut(
                  value?.map(parseEachValueOut(storeFormat)) ?? ['', '']
                )
            : (value) => parseEachValueOut(storeFormat)(value),
        ['rangePicker', 'parseRangeOut', 'storeFormat']
      ),
      formatEmpty: '---',
      storeFormat: 'YYYY-MM-DD HH:mm',
      displayFormat: this.fromParams(
        ({ displayShort, displayShortYear }) =>
          displayShort
            ? 'DD/MM HH:mm'
            : displayShortYear
            ? 'DD/MM/YY HH:mm'
            : 'L LT',
        ['displayShort', 'displayShortYear']
      ),
      timeFormat: 'HH:mm',
      hourStep: 1,
      minuteStep: 5,
      minHour: 0,
      maxHour: 24,
      displayShort: false,
      displayShortYear: false,
      showDate: true,
      showTime: true,
      filters: 'custom',
    })
  }

  static renderer = ({
    value,
    onChange,
    optional,
    readOnly,
    disabled,
    displayFormat,
    showDate,
    showTime,
    timeFormat,
    hourStep,
    minuteStep,
    minHour,
    maxHour,
    rangePicker,
  }) => {
    const ComponentBase = showDate ? DatePicker : TimePicker
    const Component = rangePicker ? ComponentBase.RangePicker : ComponentBase

    const type = `${!showTime ? 'date' : !showDate ? 'time' : 'dateTime'}${
      rangePicker ? 'Range' : ''
    }`

    const timeProps = {
      hourStep,
      minuteStep,
      disabledHours: () =>
        [...Array(24).keys()].filter((h) => h < minHour || h > maxHour),
      hideDisabledOptions: true,
    }

    return (
      <Container $type={type}>
        <Component
          value={value}
          allowClear={optional}
          disabled={disabled || readOnly}
          inputReadOnly={false}
          format={displayFormat}
          onChange={(value) => onChange(value)}
          placeholder={
            {
              dateTime: 'Data/Hora',
              date: 'Data',
              time: 'Hora',
              dateTimeRange: ['Início', 'Fim'],
              dateRange: ['Início', 'Fim'],
              timeRange: ['Início', 'Fim'],
            }[type]
          }
          {...(!showTime
            ? {}
            : !showDate
            ? timeProps
            : { showTime: { ...timeProps, format: timeFormat } })}
        />
      </Container>
    )
  }

  static formatter = (value, { displayFormat, rangePicker, formatRange }) =>
    rangePicker
      ? formatRange(...value.map((v) => v?.format(displayFormat)))
      : value.format(displayFormat)

  static filterRenderer = (
    filters,
    onChangeFilters,
    onConfirm,
    focusRef,
    typeParams
  ) => {
    const { storeFormat, showDate, showTime } = typeParams
    const parserIn = parseEachValueIn(storeFormat, showTime && !showDate)
    const parserOut = parseEachValueOut(storeFormat)

    const filterParams = {
      ...typeParams,
      optional: true,
      disabled: false,
      readOnly: false,
      rangePicker: false,
    }

    return (
      <FilterContainer>
        <div>
          <label>Início:</label>
          {this.renderer({
            value: parserIn(filters[0]?.start),
            onChange: (value) =>
              onChangeFilters([{ ...filters[0], start: parserOut(value) }]),
            ...filterParams,
          })}
        </div>
        <div>
          <label />
          <ArrowUpIcon
            className={filters[0]?.end ? 'enabled' : 'disabled'}
            onClick={
              !filters[0]?.end
                ? undefined
                : () =>
                    onChangeFilters([{ ...filters[0], start: filters[0].end }])
            }
          />
          <ArrowDownIcon
            className={filters[0]?.start ? 'enabled' : 'disabled'}
            onClick={
              !filters[0]?.start
                ? undefined
                : () =>
                    onChangeFilters([{ ...filters[0], end: filters[0].start }])
            }
          />
        </div>
        <div>
          <label>Fim:</label>
          {this.renderer({
            value: parserIn(filters[0]?.end),
            onChange: (value) =>
              onChangeFilters([{ ...filters[0], end: parserOut(value) }]),
            ...filterParams,
          })}
        </div>
        <IconButton.Search noText onClick={() => onConfirm()} />
      </FilterContainer>
    )
  }

  static filterComparator = (
    value,
    filter,
    { storeFormat, showDate, showTime }
  ) => {
    const parserIn = parseEachValueIn(storeFormat, showTime && !showDate)
    return (
      (!filter.start ||
        (value && this.comparator(value, parserIn(filter.start)) >= 0)) &&
      (!filter.end ||
        (value && this.comparator(value, parserIn(filter.end)) <= 0))
    )
  }

  static validators = (value, typeParams) => [
    // TODO: min, max, year, weekends?
    true,
  ]

  static comparator = (value1, value2) =>
    // TODO: implement for range
    value1.isSame(value2)
      ? 0
      : value1.isAfter(value2)
      ? 1
      : value1.isBefore(value2)
      ? -1
      : undefined

  static isEmpty = (value, { rangePicker } = {}) =>
    !rangePicker
      ? !value || value.length === 0
      : !value || Object.values(value).every((v) => !v || v.length === 0)

  static now = (overrideParams) => {
    const { storeFormat } = this._parseTypeParams(overrideParams)
    return moment().startOf('day').format(storeFormat)
  }

  now = (...args) => this.staticProxy('now', 0, ...args)
}
