import React from 'react'
import { Space } from 'antd'

export default function VerticalSpace({ center, size = 'large', children }) {
  const textAlign = center ? 'center' : undefined
  return (
    <Space
      direction='vertical'
      size={size}
      style={{ width: '100%', textAlign }}
    >
      {children}
    </Space>
  )
}
