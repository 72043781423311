import React, { useEffect, useState } from 'react'

import { Input } from 'antd'
import { TextType } from './TextType'
import styled from 'styled-components'

const TextAreaInput = styled(Input.TextArea)`
  ${(props) => !props.$allowResize && 'resize: none'}
`

const FormatContainer = styled.div`
  white-space: pre-wrap;
`

export class TextAreaType extends TextType {
  static getDefaultTypeParams() {
    return TextType.extendTypeParams({
      fixedRows: undefined,
      allowResize: false,
      autoSizeMinRows: 2,
      autoSizeMaxRows: 10,
      validationTriggers: ['change'],
      displayAs: 'custom',
    })
  }

  static renderer = ({
    value,
    onChange,
    readOnly,
    disabled,
    optional,
    fixedRows,
    allowResize,
    autoSizeMinRows,
    autoSizeMaxRows,
  }) => {
    const [internalValue, setInternalValue] = useState(value)
    useEffect(() => {
      setInternalValue(value)
    }, [value])

    return (
      <TextAreaInput
        rows={fixedRows}
        value={internalValue}
        onChange={(e) => {
          if (internalValue === '' || e.target.value === '') {
            onChange(e.target.value)
          } else {
            setInternalValue(e.target.value)
          }
        }}
        onBlur={() => !readOnly && onChange(internalValue)}
        readOnly={readOnly}
        disabled={disabled}
        allowClear={optional}
        autoSize={
          fixedRows || allowResize
            ? false
            : { minRows: autoSizeMinRows, maxRows: autoSizeMaxRows }
        }
        $allowResize={allowResize}
      />
    )
  }

  static displayer = ({ value, displayAs, ...typeParams }) => {
    return (
      <FormatContainer>
        {TextType.format(value, TextType.filterParams(typeParams))}
      </FormatContainer>
    )
  }

  static formatter = (value, typeParams) => {
    return TextType.format(value, TextType.filterParams(typeParams))
  }
}
