import { API, Auth } from 'aws-amplify'
import { AppContext, useAppContextState } from './libs/hooksLib'
import React, { useCallback, useEffect, useState } from 'react'
import { StringParam, useQueryParam } from 'use-query-params'

import AppHeader from './components/routing/AppHeader'
import { If } from './components/utils'
import { Layout } from 'antd'
import LoaderContent from './components/ui/LoaderContent'
import { QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import Routes from './components/routing/Routes'
import { queryClient } from './config/cache'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'

const StyledAppLayout = styled(Layout)`
  @media (min-width: 768px) {
    & {
      width: 750px;
    }
  }
  @media (min-width: 992px) {
    & {
      width: 970px;
    }
  }
  @media (min-width: 1200px) {
    & {
      width: 1170px;
    }
  }

  margin: 15px auto;
`

const StyledAppContent = styled(Layout.Content)`
  background: #fff;
  padding: 24px;
  min-height: 300px;
`

const StyledAppFooter = styled(Layout.Footer)`
  text-align: center;
`

function App(props) {
  const appContext = useAppContextState()
  const [simulatedGroup, setSimulatedGroup] = useQueryParam(
    'simulate',
    StringParam
  )
  const [isLoading, setIsLoading] = useState(true)
  const [isAuthenticating, setIsAuthenticating] = useState(true)
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [user, setUser] = useState({})
  const [simulatedUser, setSimulatedUser] = useState({})

  const refreshUserData = useCallback(async () => {
    setIsLoading(true)
    const user = await API.get('api', '/user/self')
    const student = user.student
      ? await API.get('api', `/aluno/${user.student}`)
      : undefined
    const products = !user.product
      ? []
      : await Promise.all(
          user.product.map((p) => API.get('api', `/produto/${p}`))
        )
    setUser({
      course: student?.id_course,
      courseData: undefined, // TODO: query (except for guests); update this on simulation; use it on pages instead of querying again
      studentData: student, // TODO: update this on simulation; use it on pages instead of querying again
      productData: products, // TODO: update this on simulation; use it on pages instead of querying again
      hasEssayService: products.some((p) => !!p.themes?.length),
      hasExamService: products.some((p) => !!p.exams?.length),
      ...user,
    })
    setIsLoading(false)
  }, [])

  const userHasAuthenticated = useCallback(
    async (loggedIn) => {
      if (loggedIn === true) {
        await refreshUserData()
      }
      setIsAuthenticated(loggedIn)
      if (loggedIn === false) {
        setUser({})
      }
    },
    [refreshUserData]
  )

  useEffect(() => {
    async function onLoad() {
      try {
        await Auth.currentSession()
        await userHasAuthenticated(true)
      } catch (e) {
        setIsLoading(false)
        if (e !== 'No current user') {
          console.error(e)
        }
      }

      setIsAuthenticating(false)
    }

    onLoad()
  }, [userHasAuthenticated])

  useEffect(() => {
    if (user) {
      setSimulatedUser({
        ...user,
        group: simulatedGroup || simulatedUser?.group || user.group,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, simulatedGroup])

  async function handleLogout() {
    await Auth.signOut()
    userHasAuthenticated(false)
    props.history.push('/')
  }

  return (
    <If condition={!isAuthenticating}>
      <QueryClientProvider client={queryClient}>
        <AppContext.Provider value={appContext}>
          <StyledAppLayout>
            <AppHeader
              isAuthenticated={isAuthenticated}
              user={user}
              simulatedUser={simulatedUser}
              onSimulateUser={(user) => {
                setSimulatedUser(user)
                setSimulatedGroup(user.group)
              }}
              onNavigate={(route) => props.history.push(route)}
              onLogout={handleLogout}
            />
            <StyledAppContent>
              <LoaderContent isLoading={isLoading}>
                <Routes
                  appProps={{
                    isAuthenticated,
                    userHasAuthenticated,
                    user: simulatedUser,
                    refreshUserData,
                  }}
                />
              </LoaderContent>
            </StyledAppContent>
            <StyledAppFooter>© 2024 Escola Intelecto</StyledAppFooter>
          </StyledAppLayout>
          <ReactQueryDevtools initialIsOpen={false} />
        </AppContext.Provider>
      </QueryClientProvider>
    </If>
  )
}

export default withRouter(App)
