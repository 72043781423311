import { DateTimeType } from './DateTimeType'

export class DateType extends DateTimeType {
  static getDefaultTypeParams() {
    return DateTimeType.extendTypeParams({
      storeFormat: 'YYYY-MM-DD',
      displayFormat: this.fromParams(
        ({ displayShort, displayShortYear }) =>
          displayShort ? 'DD/MM' : displayShortYear ? 'DD/MM/YY' : 'DD/MM/YYYY',
        ['displayShort', 'displayShortYear']
      ),
      displayShort: false,
      displayShortYear: false,
      showTime: false,
    })
  }
}
