import { DeleteIcon, PlusIcon } from '../../../ui/Icons'

import { BaseArrayType } from './BaseArrayType'
import React from 'react'
import { Tabs } from 'antd'

const { TabPane } = Tabs
export class ArrayTabsType extends BaseArrayType {
  static getDefaultTypeParams() {
    return BaseArrayType.extendTypeParams({
      renderArray: this.renderArray,
      displayAs: this.fromParams(
        ({ isFormControlled }) => (isFormControlled ? 'input' : 'invalid'),
        ['isFormControlled']
      ),
    })
  }

  static renderArray({
    childrenProps,
    renderChild,
    onAdd,
    onRemove,
    showAddRemoveButtons,
    allowAddItems,
    allowRemoveItems,
    getItemLabel,
  }) {
    const onEditArray = {
      add: onAdd,
      remove: onRemove,
    }
    return (
      <Tabs
        defaultActiveKey={childrenProps?.[0]?.key}
        type={showAddRemoveButtons ? 'editable-card' : 'card'}
        hideAdd={!allowAddItems}
        addIcon={<PlusIcon />}
        onEdit={(index, action) => onEditArray[action](parseInt(index))}
      >
        {childrenProps.map((childProps, index) => (
          <TabPane
            key={index}
            closable={allowRemoveItems}
            closeIcon={<DeleteIcon />}
            tab={getItemLabel ? getItemLabel(index) : index + 1}
          >
            {renderChild(childProps)}
          </TabPane>
        ))}
      </Tabs>
    )
  }
}
