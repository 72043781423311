import { memoized, sortArray, stopPropagation } from '../../../../libs/utils'

import IconButton from '../../../ui/IconButton'
import { Link } from 'react-router-dom'
import { NEW_RECORD } from '../../crud.hooks'
import React from 'react'
import { TokenType } from './TokenType'
import { WrapIf } from '../../../utils'
import styled from 'styled-components'

const StyledLink = styled(Link)`
  color: rgba(0, 0, 0, 0.85);
`

const FilterContainer = styled.div`
  .ant-select {
    width: 202px;
  }
  button {
    width: 46px;
  }
`

const tokenGenerator = memoized(
  ({ query, displayFn, sortFn, sortOrder, filterFn }) =>
    query.isLoading
      ? []
      : sortArray(query.data.filter(filterFn), sortFn, sortOrder).map((r) => ({
          [r.id]: displayFn(r),
        })),
  (...args) => JSON.stringify(args)
)

export class RefType extends TokenType {
  static getDefaultTypeParams() {
    return TokenType.extendTypeParams({
      formatAsComponent: true,
      query: this.required(),
      display: '',
      displayFn: this.fromParams(
        ({ display }) =>
          (r) =>
            r[display],
        ['display']
      ),
      sort: '',
      sortFn: this.fromParams(
        ({ sort, displayFn }) =>
          (r) =>
            sort ? r[sort] : displayFn(r),
        ['sort', 'displayFn']
      ),
      sortOrder: 'asc',
      filterFn: () => true,
      tokens: this.fromParams(tokenGenerator, [
        'query',
        'displayFn',
        'sortFn',
        'sortOrder',
        'filterFn',
      ]),
      showRefLinks: this.fromParams(
        ({ user, routeConfig }) =>
          user && routeConfig.allow.includes(user.group),
        ['user', 'routeConfig']
      ),
      showNewLink: true,
      routeConfig: this.required(),
      renderAddon: ({
        value,
        showRefLinks,
        showNewLink,
        allowMultiple,
        routeConfig,
      }) =>
        !showRefLinks || allowMultiple ? null : (
          <>
            <IconButton.Link
              noText
              routeTo={`${routeConfig.path}/${value}`}
              disabled={!value}
            />
            {showNewLink && (
              <IconButton.New
                noText
                routeTo={`${routeConfig.path}/${NEW_RECORD}`}
                type='default'
              />
            )}
          </>
        ),
      filters: 'custom',
    })
  }

  static filterRenderer = (
    filters,
    onChangeFilters,
    onConfirm,
    focusRef,
    typeParams
  ) => (
    <FilterContainer>
      {this.renderer({
        ...typeParams,
        value: filters,
        onChange: onChangeFilters,
        inputRef: focusRef,
        allowMultiple: true,
        allowClear: true,
        onClear: () => {
          onChangeFilters([])
          onConfirm()
        },
        renderAddon: () => <IconButton.Search noText onClick={onConfirm} />,
        autoFocus: true,
        disabled: false,
        readOnly: false,
      })}
    </FilterContainer>
  )

  static filterComparator = (value, filter) => value === filter

  static formatter = (value, { showRefLinks, routeConfig, ...typeParams }) => (
    <WrapIf
      condition={showRefLinks}
      wrapper={StyledLink}
      wrapperProps={{
        to: `${routeConfig.path}/${value}`,
        onClick: stopPropagation,
      }}
    >
      {TokenType.format(value, TokenType.filterParams(typeParams))}
    </WrapIf>
  )
}
