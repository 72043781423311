import React, { useEffect, useState } from 'react'
import { leftJoin, mapFields, sortArray } from '../../libs/utils'
import { useCRUD, useResources } from '../../components/data/crud.hooks'

import { DateType } from '../../components/data/fieldtypes'
import EssayList from '../../components/essay/EssayList'
import EssayPopup from '../../components/essay/EssayPopup'
import PageWrapper from '../../components/ui/PageWrapper'
import { Tabs } from 'antd'
import styled from 'styled-components'
import { useQuery } from 'react-query'

const StyledTabs = styled(Tabs)`
  & .tab-container .ant-tabs-tab-btn {
    max-width: 200px;
    white-space: normal;
    text-align: left;
  }
`

export default function StudentEssays(props) {
  const isStudentView = props.user.group !== 'guest'
  const essayCrud = useCRUD('/redacao')
  const [productQuery, themeQuery, materialQuery] = useResources([
    '/produto',
    '/tema',
    '/material',
  ])
  const courseQuery = useQuery({
    queryKey: ['/course', props.user.course],
    enabled: isStudentView,
  })
  const isFetching =
    productQuery.isLoading ||
    themeQuery.isLoading ||
    materialQuery.isLoading ||
    courseQuery.isLoading
  const [selectedEssay, setSelectedEssay] = useState(null)
  const [essayText, setEssayText] = useState()
  const [essayFile, setEssayFile] = useState()
  const [list, setList] = useState([])
  const [courseThemes, setCourseThemes] = useState([])

  useEffect(() => {
    setEssayText(selectedEssay?.essay.txt_redacao)
    setEssayFile(selectedEssay?.essay.url_redacao)
  }, [selectedEssay])

  const closePopup = () => setSelectedEssay(null)

  const savePopup = (submit) => {
    essayCrud.saveRecord({
      ...selectedEssay.essay,
      txt_redacao: essayText ?? undefined,
      url_redacao: essayFile ?? undefined,
      enviada: submit,
    })
    closePopup()
  }

  const onChangeField = (field) => (value) =>
    setSelectedEssay((e) => ({ ...e, essay: { ...e.essay, [field]: value } }))

  useEffect(() => {
    if (!isFetching && essayCrud.list !== undefined) {
      const themeMap = mapFields(themeQuery.data, 'id')
      const courseThemes = !isStudentView
        ? []
        : themeQuery.data?.filter(
            (theme) =>
              theme.id_courses?.includes(props.user.course) &&
              DateType.compare(theme.dtavailable, DateType.now()) !== 1
          ) || []
      setCourseThemes(courseThemes.map((t) => t.id))
      const temas = [
        ...courseThemes,
        ...productQuery.data
          .filter((p) => props.user.product?.includes(p.id))
          .map((p) => p.themes)
          .flat()
          .map((t) => themeMap[t]),
      ]
      setList(
        sortArray(
          leftJoin(temas, 'id', essayCrud.list, 'id_tema', (t, r) => {
            const status =
              !r || r.enviada === false
                ? 'pendente'
                : !r.corrigida
                ? 'enviada'
                : 'corrigida'
            return {
              id_tema: t.id,
              id_redacao: r ? r.id : null,
              dtavailable: t.dtavailable,
              dtdelivery: t.dtdelivery,
              titulo: t.titulo,
              tema: t.tema,
              categoria: t.category,
              status,
              labelStatus:
                status === 'pendente' &&
                t.category === 'Curso CTI/ETEC' &&
                DateType.compare(t.dtdelivery, DateType.now()) !== 1
                  ? 'atrasada'
                  : status,
              material: t.material,
              essay: r || {
                id_tema: t.id,
              },
            }
          }),
          (r) => [r.dtavailable, r.titulo],
          ['desc', 'asc']
        )
      )
    }
  }, [
    isStudentView,
    isFetching,
    essayCrud.list,
    productQuery.data,
    themeQuery.data,
    props.user.product,
    props.user.course,
  ])

  const renderEssayList = (product) => (
    <EssayList
      product={productMap[product]}
      essayList={list}
      materialList={materialQuery.data?.filter((material) =>
        material.products.includes(product)
      )}
      onSelect={setSelectedEssay}
      isLoading={essayCrud.isLoading || isFetching}
    />
  )

  const productMap =
    productQuery.data?.length > 0 ? mapFields(productQuery.data, 'id') : {}
  if (isStudentView) {
    productMap[props.user.course] = {
      isCourse: true,
      id: props.user.course,
      nome: courseQuery.data?.name,
      themes: courseThemes,
    }
  }

  const userProducts = [
    ...(isStudentView ? [props.user.course] : []),
    ...(props.user.product || []),
  ].filter((p) => productMap[p]?.themes?.length)

  return (
    <PageWrapper isLoading={isFetching || !productMap}>
      {userProducts.length === 0 ? (
        <span>Você não possui nenhum pacote de redações.</span>
      ) : (
        <>
          {!isFetching &&
            (userProducts.length === 1 ? (
              renderEssayList(userProducts[0])
            ) : (
              <StyledTabs defaultActiveKey={userProducts[0]}>
                {userProducts.map((product) => (
                  <Tabs.TabPane key={product} tab={productMap[product].nome}>
                    {renderEssayList(product)}
                  </Tabs.TabPane>
                ))}
              </StyledTabs>
            ))}
          <EssayPopup
            key={selectedEssay?.id_redacao}
            show={!!selectedEssay}
            title={selectedEssay?.titulo}
            theme={selectedEssay?.tema}
            essayType={selectedEssay?.categoria}
            status={selectedEssay?.status}
            material={selectedEssay?.material}
            essayText={selectedEssay?.essay.txt_redacao}
            essayFile={selectedEssay?.essay.url_redacao}
            resultText={selectedEssay?.essay.txt_correcao}
            resultFile={selectedEssay?.essay.url_correcao}
            resultGrade={selectedEssay?.essay.nota}
            labelStatus={selectedEssay?.labelStatus}
            onChangeText={onChangeField('txt_redacao')}
            onChangeFile={onChangeField('url_redacao')}
            onCancel={closePopup}
            onSave={() => savePopup(false)}
            onSubmit={() => savePopup(true)}
            onClose={closePopup}
          />
        </>
      )}
    </PageWrapper>
  )
}
