import React from 'react'
import { Space } from 'antd'
import styled from 'styled-components'

const StyledContainer = styled.div`
  text-align: right;
`

export default function ButtonBar({ children }) {
  return (
    <StyledContainer>
      <Space size='middle'>{children}</Space>
    </StyledContainer>
  )
}

ButtonBar.Group = ({ children }) => <div>{children}</div>
