import {
  DateType,
  FileType,
  HtmlType,
  YoutubeType,
} from '../../components/data/fieldtypes'
import { List, Tabs } from 'antd'

import EssayListItem from '../../components/essay/EssayListItem'
import LoaderContent from '../../components/ui/LoaderContent'
import React from 'react'
import VerticalSpace from '../ui/VerticalSpace'
import { sortArray } from '../../libs/utils'

export default function EssayList({
  product,
  essayList,
  materialList,
  onSelect,
  isLoading,
}) {
  const getTheme = (essay) =>
    !product.isCourse ? (
      essay.tema
    ) : (
      <>
        {essay.tema}
        <br />
        {`Disponível: ${DateType.format(essay.dtavailable, {
          displayShort: true,
        })} | Prazo: ${DateType.format(essay.dtdelivery, {
          displayShort: true,
        })}`}
      </>
    )

  const renderEssays = () => (
    <LoaderContent isLoading={isLoading}>
      <List>
        {essayList.map(
          (essay) =>
            product.themes.includes(essay.id_tema) && (
              <EssayListItem
                key={essay.id_tema}
                title={essay.titulo}
                theme={getTheme(essay)}
                status={essay.status}
                labelStatus={essay.labelStatus}
                onClick={() => onSelect(essay)}
                isList
              />
            )
        )}
      </List>
    </LoaderContent>
  )

  return !materialList?.length ? (
    renderEssays()
  ) : (
    <Tabs
      defaultActiveKey='redacoes'
      tabPosition='left'
      className='tab-container'
    >
      <Tabs.TabPane key='redacoes' tab='Redações'>
        {renderEssays()}
      </Tabs.TabPane>
      {materialList &&
        sortArray(materialList, (material) => parseInt(material.ordem)).map(
          (material) =>
            material.products.includes(product.id) && (
              <Tabs.TabPane key={material.id} tab={material.titulo}>
                <VerticalSpace>
                  {!HtmlType.isEmpty(material.desc) &&
                    HtmlType.display(material.desc, { showBorder: false })}
                  {!FileType.isEmpty(material.material) &&
                    FileType.display(material.material)}
                  {!YoutubeType.isEmpty(material.video) &&
                    YoutubeType.display(material.video, { expanded: true })}
                </VerticalSpace>
              </Tabs.TabPane>
            )
        )}
    </Tabs>
  )
}
