import { Storage } from 'aws-amplify'

export async function s3Upload(file, filename, displayName, progressCallback) {
  filename = filename || file.name

  const stored = await Storage.put(filename, file, {
    contentType: file.type,
    contentDisposition: displayName
      ? `attachment; filename=${encodeURIComponent(displayName)}`
      : undefined,
    progressCallback,
  })

  return stored.key
}

export async function s3GetLink(filename) {
  return Storage.get(filename)
}
