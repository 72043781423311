export const enemScoreTiers = [0, 40, 80, 120, 160, 200]

export const enemCriterias = [
  {
    title: 'Domínio da escrita formal da língua portuguesa',
    result: [
      'Demonstra desconhecimento da modalidade escrita formal da língua portuguesa.',
      'Demonstra domínio precário da modalidade escrita formal da língua portuguesa, de forma sistemática, com diversificados e frequentes desvios gramaticais, de escolha de registro e de convenções da escrita.',
      'Demonstra domínio insuficiente da modalidade escrita formal da língua portuguesa, com muitos desvios gramaticais, de escolha de registro e de convenções da escrita.',
      'Demonstra domínio mediano da modalidade escrita formal da língua portuguesa e de escolha de registro, com alguns desvios gramaticais e de convenções da escrita.',
      'Demonstra bom domínio da modalidade escrita formal da língua portuguesa e de escolha de registro, com poucos desvios gramaticais e de convenções da escrita.',
      'Demonstra excelente domínio da modalidade escrita formal da língua portuguesa e de escolha de registro. Desvios gramaticais ou de convenções da escrita serão aceitos somente como excepcionalidade e quando não caracterizarem reincidência.',
    ],
  },
  {
    title: 'Compreender o tema e não fugir do que é proposto',
    result: [
      'Fuga ao tema/não atendimento à estrutura dissertativo-argumentativa. Nestes casos a redação recebe nota zero e é anulada.',
      'Apresenta o assunto, tangenciando o tema, ou demonstra domínio precário do texto dissertativo-argumentativo, com traços constantes de outros tipos textuais.',
      'Desenvolve o tema recorrendo à cópia de trechos dos textos motivadores ou apresenta domínio insuficiente do texto dissertativo-argumentativo, não atendendo à estrutura com proposição, argumentação e conclusão.',
      'Desenvolve o tema por meio de argumentação previsível e apresenta domínio mediano do texto dissertativo-argumentativo, com proposição, argumentação e conclusão.',
      'Desenvolve o tema por meio de argumentação consistente e apresenta bom domínio do texto dissertativo-argumentativo, com proposição, argumentação e conclusão.',
      'Desenvolve o tema por meio de argumentação consistente, a partir de um repertório sociocultural produtivo e apresenta excelente domínio do texto dissertativo-argumentativo.',
    ],
  },
  {
    title:
      'Selecionar, relacionar, organizar e interpretar informações, fatos, opiniões e argumentos em defesa de um ponto de vista',
    result: [
      'Apresenta informações, fatos e opiniões não relacionados ao tema e sem defesa de um ponto de vista.',
      'Apresenta informações, fatos e opiniões pouco relacionados ao tema ou incoerentes e sem defesa de um ponto de vista.',
      'Apresenta informações, fatos e opiniões relacionados ao tema, mas desorganizados ou contraditórios e limitados aos argumentos dos textos motivadores, em defesa de um ponto de vista.',
      'Apresenta informações, fatos e opiniões relacionados ao tema, limitados aos argumentos dos textos motivadores e pouco organizados, em defesa de um ponto de vista.',
      'Apresenta informações, fatos e opiniões relacionados ao tema, de forma organizada, com indícios de autoria, em defesa de um ponto de vista.',
      'Apresenta informações, fatos e opiniões relacionados ao tema proposto, de forma consistente e organizada, configurando autoria, em defesa de um ponto de vista.',
    ],
  },
  {
    title:
      'Conhecimento dos mecanismos linguísticos necessários para a construção da argumentação',
    result: [
      'Não articula as informações.',
      'Articula as partes do texto de forma precária.',
      'Articula as partes do texto, de forma insuficiente, com muitas inadequações e apresenta repertório limitado de recursos coesivos.',
      'Articula as partes do texto, de forma mediana, com inadequações, e apresenta repertório pouco diversificado de recursos coesivos.',
      'Articula as partes do texto com poucas inadequações e apresenta repertório diversificado de recursos coesivos.',
      'Articula bem as partes do texto e apresenta repertório diversificado de recursos coesivos.',
    ],
  },
  {
    title: 'Respeito aos direitos humanos',
    result: [
      'Não apresenta proposta de intervenção ou apresenta proposta não relacionada ao tema ou ao assunto.',
      'Apresenta proposta de intervenção vaga, precária ou relacionada apenas ao assunto.',
      'Elabora, de forma insuficiente, proposta de intervenção relacionada ao tema, ou não articulada com a discussão desenvolvida no texto.',
      'Elabora, de forma mediana, proposta de intervenção relacionada ao tema e articulada à discussão desenvolvida no texto.',
      'Elabora bem proposta de intervenção relacionada ao tema e articulada à discussão desenvolvida no texto.',
      'Elabora muito bem proposta de intervenção, detalhada, relacionada ao tema e articulada à discussão desenvolvida no texto.',
    ],
  },
]
