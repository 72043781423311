import { Col, Divider, List, Progress, Row, Tag, Typography } from 'antd'
import { FileType, TextAreaType } from '../data/fieldtypes'
import React, { useState } from 'react'

import IconButton from '../ui/IconButton'
import VerticalSpace from '../ui/VerticalSpace'
import styled from 'styled-components'
import { sumArray } from '../../libs/utils'
import { useInterval } from 'ahooks'
import { useResponsiveness } from '../../libs/hooksLib'

const Container = styled.div`
  & .ant-space-vertical {
    width: 100%;
  }

  & .ant-list-item:first-child {
    padding-top: 0px;
  }

  & h4.ant-list-item-meta-title > span:first-child {
    display: inline-block;
    width: 90%;
  }

  & .ant-list-item-meta-description {
    font-size: 12px;
  }

  & .ant-divider {
    margin: 0 0 12px 0;
  }

  @media (min-width: 481px) {
    & .scrollable-area {
      height: 300px;
      overflow: hidden auto;
    }
  }
`

export default function EssayPopupResultView({
  essayType,
  gradeArray,
  resultFile,
  resultText,
  enemCriterias,
  enemScoreTiers,
}) {
  const { isMobile, isTablet } = useResponsiveness()
  const enemView = essayType === 'ENEM'
  const progressPercent = (100 * sumArray(gradeArray)) / (enemView ? 1000 : 10)
  const [gradualProgress, setGradualProgress] = useState(0)

  useInterval(
    () => setGradualProgress(Math.min(gradualProgress + 1, progressPercent)),
    gradualProgress >= progressPercent ? null : 5
  )

  return (
    <Container>
      <VerticalSpace>
        <Typography.Text type='secondary'>
          Veja abaixo os comentários gerais sobre sua redação e clique em{' '}
          <b>Baixar correção</b> para fazer o download da correção detalhada.
          Você também pode rever o material de apoio deste tema clicando no
          título do tema acima.
        </Typography.Text>
        <Row gutter={16}>
          <Col xs={isMobile ? 24 : isTablet ? 10 : 5}>
            <VerticalSpace center>
              <Progress
                percent={gradualProgress}
                format={(p) =>
                  (Math.round(p * (enemView ? 100 : 1)) / 10).toFixed(
                    enemView ? 0 : 1
                  )
                }
                type='circle'
                width={156}
              />
              <IconButton.Download
                className='essayDownload'
                text='Baixar correção'
                block
                type='primary'
                onClick={() => FileType.downloadFile(resultFile)}
              />
            </VerticalSpace>
          </Col>
          <Col
            className='scrollable-area'
            offset={isMobile ? 0 : 1}
            span={isMobile ? 24 : isTablet ? 11 : 18}
          >
            {enemView && (
              <>
                <List>
                  {gradeArray.map((score, i) => (
                    <List.Item key={i}>
                      <List.Item.Meta
                        title={
                          <>
                            <span>{`Competência ${i + 1} - ${
                              enemCriterias[i].title
                            }`}</span>
                            <Tag
                              className='pull-right'
                              color={
                                score >= 160
                                  ? 'success'
                                  : score >= 80
                                  ? 'warning'
                                  : 'error'
                              }
                            >
                              {score}
                            </Tag>
                          </>
                        }
                        description={`${
                          enemCriterias[i].result[enemScoreTiers.indexOf(score)]
                        }`}
                      />
                    </List.Item>
                  ))}
                </List>
                <Divider />
              </>
            )}
            {isMobile && <br />}
            <h4>Comentários do corretor</h4>
            {TextAreaType.display(resultText)}
          </Col>
        </Row>
      </VerticalSpace>
    </Container>
  )
}
