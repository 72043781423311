import { Avatar, Dropdown, Menu } from 'antd'
import React, { useState } from 'react'

import ConfirmPopover from '../ui/ConfirmPopover'
import SimulationDrawer from './SimulationDrawer'
import { userGroups } from './routingConfig'

export default function AppAvatarMenu({
  onLogout,
  user,
  simulatedUser,
  showUserSimulator,
  onSimulateUser,
}) {
  const [showUserSimulatorDrawer, toggleUserSimulatorDrawer] = useState(false)
  const [showConfirmLogout, toggleConfirmLogout] = useState(false)
  return (
    <>
      <ConfirmPopover
        confirmText='Sair'
        confirmCallback={() => {
          toggleConfirmLogout(false)
          onLogout()
        }}
        cancelCallback={() => toggleConfirmLogout(false)}
        visible={showConfirmLogout}
        placement='bottom'
      >
        <Dropdown
          trigger={['click']}
          overlay={
            <Menu>
              {showUserSimulator && (
                <Menu.Item onClick={() => toggleUserSimulatorDrawer(true)}>
                  Simulação
                </Menu.Item>
              )}
              <Menu.Item onClick={() => toggleConfirmLogout(true)}>
                Sair
              </Menu.Item>
            </Menu>
          }
        >
          <div>
            <Avatar size={showUserSimulator ? 32 : 40} shape='circle'>
              {user.firstname
                ?.split(' ')
                .map((name) => name[0])
                .join('')}
            </Avatar>
            {showUserSimulator && (
              <div
                className='group'
                onClick={() =>
                  onSimulateUser({
                    ...simulatedUser,
                    group:
                      simulatedUser.group !== 'student'
                        ? 'student'
                        : user.group,
                  })
                }
              >
                {
                  userGroups.find(
                    ([group]) => group === simulatedUser.group
                  )?.[1]
                }
              </div>
            )}
          </div>
        </Dropdown>
      </ConfirmPopover>
      {showUserSimulator && (
        <SimulationDrawer
          user={user}
          simulatedUser={simulatedUser}
          onSimulateUser={onSimulateUser}
          showUserSimulatorDrawer={showUserSimulatorDrawer}
          toggleUserSimulatorDrawer={toggleUserSimulatorDrawer}
        />
      )}
    </>
  )
}
