import { BulbIcon } from './Icons'
import { If } from '../utils'
import React from 'react'
import styled from 'styled-components'

const StyledLoader = styled.div`
  @keyframes fade-theme-color {
    0% {
      color: #bbb;
    }
    100% {
      color: var(--theme-color);
    }
  }

  @keyframes fade {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  & .loading {
    position: absolute;
    left: 50%;
    margin-left: -50px;
    padding-top: 50px;

    & > .label {
      margin-top: 10px;
      display: block;
      color: #bbb;
    }

    & > .icon {
      font-size: 80px;
      animation: fade-theme-color 1s infinite alternate;
      width: 100px;
      height: 100px;
    }
  }

  & .content {
    animation: fade 1.5s;
    visibility: ${({ $isLoading }) => ($isLoading ? 'hidden' : 'visible')};
  }
`

export default function LoaderContent({
  isLoading,
  preRender = false,
  children,
}) {
  return (
    <StyledLoader $isLoading={isLoading}>
      <If condition={isLoading}>
        <div className='loading'>
          <BulbIcon className='icon' />
          <div className='label'>Carregando...</div>
        </div>
      </If>
      <If condition={!isLoading || preRender}>
        <div className='content'>{children}</div>
      </If>
    </StyledLoader>
  )
}
