import { pipe, stopPropagation } from '../../../../libs/utils'

import { BaseType } from '../BaseType'
import { LinkIcon } from '../../../ui/Icons'
import React from 'react'
import { TextType } from '../string'

export class LinkType extends BaseType {
  static getDefaultTypeParams() {
    return BaseType.extendTypeParams({
      valueDefault: '',
      formatAsComponent: true,
      formatEmpty: '---',
      displayAs: 'custom',
      placeholder: '',
      linkPrefix: '',
      linkSuffix: '',
      icon: LinkIcon,
      renderPreview: null,
    })
  }

  static isEmpty = (value) => !value || value.length === 0

  static renderer = ({
    value,
    onChange,
    renderPreview,
    linkPrefix,
    linkSuffix,
    icon: Icon,
    ...typeParams
  }) => {
    return (
      <>
        {TextType.render(value, onChange, {
          addonBefore: linkPrefix || undefined,
          addonAfter: (
            <>
              <span>{linkSuffix && linkSuffix + ' '}</span>
              <Icon
                onClick={() =>
                  window.open(`${linkPrefix}${value}${linkSuffix}`, '_BLANK')
                }
              />
            </>
          ),
          ...TextType.filterParams(typeParams),
        })}
        {renderPreview && renderPreview({ value, ...typeParams })}
      </>
    )
  }

  static displayer = ({ value, renderPreview, ...typeParams }) =>
    renderPreview
      ? renderPreview({ value, ...typeParams })
      : this.formatter(value, typeParams)

  static formatter = (value, { linkPrefix, linkSuffix }) =>
    pipe(`${linkPrefix}${value}${linkSuffix}`, (link) => (
      <a href={link} onClick={stopPropagation}>
        {link}
      </a>
    ))
}
