import { If, WrapIf } from '../../../utils'
import React, { useRef } from 'react'

import { Card } from 'antd'
import EquationEditor from 'equation-editor-react'
import { MathComponent } from 'mathjax-react'
import { TextType } from './TextType'
import styled from 'styled-components'

const StyledCard = styled(Card)`
  cursor: ${({ $editable }) => ($editable ? 'default' : 'pointer')};
  text-align: center;
`

export class EquationType extends TextType {
  static getDefaultTypeParams() {
    return TextType.extendTypeParams({
      displayAs: 'input',
      validationTriggers: ['change'],
      showBorder: this.fromParams(({ readOnly }) => !readOnly, ['readOnly']),
    })
  }

  static renderer = ({ value, onChange, showBorder, readOnly, disabled }) => {
    const editor = useRef(null)
    return (
      <WrapIf
        condition={showBorder}
        wrapper={StyledCard}
        wrapperProps={{
          $editable: readOnly || disabled,
          onClick: () => {
            if (!readOnly && !disabled) {
              editor.current.element.current.firstChild.firstChild.focus()
            }
          },
        }}
      >
        <If
          condition={!readOnly}
          then={
            <EquationEditor
              ref={editor}
              value={value}
              onChange={onChange}
              autoCommands='pi theta sqrt sum prod alpha beta gamma rho'
              // TODO: autoCommands, autoOperatorNames, help with command list
              autoOperatorNames='xxxxxxxxxxxxxxxxxxxxxxxxxx'
            />
          }
          else={<MathComponent tex={value} />}
        />
      </WrapIf>
    )
  }
}
