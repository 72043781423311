import AuthenticatedRoute from './AuthenticatedRoute'
import HomePage from '../../containers/HomePage'
import LanderPage from '../../containers/LanderPage'
import React from 'react'
import { Redirect } from 'react-router-dom'
import UnauthenticatedRoute from './UnauthenticatedRoute'
import { appRoutes } from './routingConfig'

export default function HomeRoute({ path, exact, appProps }) {
  if (!appProps.isAuthenticated) {
    // Not authenticated - lander page
    return (
      <UnauthenticatedRoute
        path={path}
        exact={exact}
        component={LanderPage}
        appProps={appProps}
      />
    )
  } else if (
    appProps.user.group !== 'guest' ||
    (appProps.user.hasEssayService && appProps.user.hasExamService)
  ) {
    // Guest user with more than one service, or other users - home page
    return (
      <AuthenticatedRoute
        path={path}
        exact={exact}
        access={'all'}
        component={HomePage}
        appProps={appProps}
      />
    )
  } else if (appProps.user.hasEssayService) {
    // Guest user - only essay pages
    return <Redirect to={appRoutes.essays.submission.path} />
  } else if (appProps.user.hasExamService) {
    // Guest user - only exams pages
    return <Redirect to={appRoutes.exams.studentExams.path} />
  } else {
    // Guest user - no services available
    return <Redirect to={appRoutes.admin.cart.path} />
  }
}
