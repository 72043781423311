import { CrossIcon, UserIcon } from '../../components/ui/Icons'
import {
  RefType,
  TextAreaType,
  TextType,
  TokenType,
} from '../../components/data/fieldtypes'

import CRUDPage from '../../components/data/CRUDPage'
import { EmailType } from '../../components/data/fieldtypes/string/EmailType'
import React from 'react'
import { appRoutes } from '../../components/routing/routingConfig'

export default function Students(props) {
  return (
    <CRUDPage
      user={props.user}
      history={props.history}
      params={props.match.params}
      resource='/aluno'
      dependencies={{
        courseQuery: '/course',
        userQuery: '/user',
      }}
      schema={({ userQuery, courseQuery }) => ({
        user_id: new RefType({
          query: userQuery,
          display: 'name',
          routeConfig: appRoutes.system.users,
          format: () => <UserIcon />,
          formatEmpty: <CrossIcon />,
          optional: true,
          unique: true,
        }),
        id_course: new RefType({
          query: courseQuery,
          display: 'name',
          routeConfig: appRoutes.courses.root,
        }),
        nome: new TextType({
          uniqueWith: 'sobrenome',
          duplicateError:
            'Já existe um aluno cadastrado com este nome e sobrenome',
        }),
        sobrenome: new TextType(),
        email: new EmailType({ unique: true }),
        rg: new TextType({ unique: true, optional: true }),
        cpf: new TextType({ unique: true }),
        tel: new TextType({ optional: true }),
        escola_ano: new TextType({ optional: true }),
        escola_tipo: new TokenType({
          optional: true,
          tokens: ['Particular', 'Pública'],
          formatEmpty: 'N/A',
        }),
        escola_nome: new TextType({ optional: true }),
        resp_nome: new TextType({ optional: true }),
        resp_rg: new TextType({ optional: true }),
        resp_cpf: new TextType({ optional: true }),
        resp_email: new EmailType({ optional: true }),
        resp_tel1: new TextType({ optional: true }),
        resp_tel2: new TextType({ optional: true }),
        end_cep: new TextType({ optional: true }),
        end_uf: new TextType({ optional: true }),
        end_cidade: new TextType({ optional: true }),
        end_bairro: new TextType({ optional: true }),
        end_rua: new TextType({ optional: true }),
        end_numero: new TextType({ optional: true }),
        end_complemento: new TextType({ optional: true }),
        obs: new TextAreaType({ optional: true }),
      })}
      listColumns={[
        {
          header: 'Usuário',
          name: 'user_id',
          filter: false,
          hidden: props.user.group !== 'owner',
        },
        {
          header: 'Nome',
          name: 'nome',
        },
        {
          header: 'Sobrenome',
          name: 'sobrenome',
        },
        {
          header: 'Email',
          name: 'email',
        },
        {
          header: 'Curso',
          name: 'id_course',
        },
        {
          header: 'Escola',
          name: 'escola_tipo',
        },
      ]}
      formFields={[
        {
          name: 'id_course',
          label: 'Curso',
        },
        {
          name: 'user_id',
          label: 'Usuário',
          hiddenFn: (args) =>
            props.user.group !== 'owner' || CRUDPage.isCreating(args),
        },
        {
          name: 'nome',
          label: 'Aluno - Nome',
        },
        {
          name: 'sobrenome',
          label: 'Aluno - Sobrenome',
        },
        {
          name: 'email',
          label: 'Aluno - Email',
        },
        {
          name: 'rg',
          label: 'Aluno - RG',
        },
        {
          name: 'cpf',
          label: 'Aluno - CPF',
        },
        {
          name: 'tel',
          label: 'Aluno - Telefone',
        },
        {
          name: 'escola_ano',
          label: 'Aluno - Nível Escolar',
        },
        {
          name: 'escola_tipo',
          label: 'Aluno - Tipo da Escola',
        },
        {
          name: 'escola_nome',
          label: 'Aluno - Nome da Escola',
        },
        {
          name: 'resp_nome',
          label: 'Responsável - Nome',
        },
        {
          name: 'resp_rg',
          label: 'Responsável - RG',
        },
        {
          name: 'resp_cpf',
          label: 'Responsável - CPF',
        },
        {
          name: 'resp_email',
          label: 'Responsável - Email',
        },
        {
          name: 'resp_tel1',
          label: 'Responsável - Telefone 1',
        },
        {
          name: 'resp_tel2',
          label: 'Responsável - Telefone 2',
        },
        {
          name: 'end_cep',
          label: 'Endereço - CEP',
        },
        {
          name: 'end_uf',
          label: 'Endereço - UF',
        },
        {
          name: 'end_cidade',
          label: 'Endereço - Cidade',
        },
        {
          name: 'end_bairro',
          label: 'Endereço - Bairro',
        },
        {
          name: 'end_rua',
          label: 'Endereço - Rua',
        },
        {
          name: 'end_numero',
          label: 'Endereço - Número',
        },
        {
          name: 'end_complemento',
          label: 'Endereço - Complemento',
        },
        {
          name: 'obs',
          label: 'Observações',
        },
      ]}
      listOptions={{
        sortColumn: 'nome',
      }}
    />
  )
}
