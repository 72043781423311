import { Popconfirm } from 'antd'
import React from 'react'

export default function ConfirmPopover({
  title = 'Tem certeza?',
  confirmText = 'OK',
  confirmCallback,
  cancelCallback,
  visible,
  placement,
  disabled,
  children,
}) {
  const visibleProp = visible !== undefined ? { visible } : {}
  const onCancelProp =
    cancelCallback !== undefined ? { onCancel: cancelCallback } : {}
  return (
    <Popconfirm
      title={title}
      okText={confirmText}
      cancelText='Cancelar'
      onConfirm={confirmCallback}
      onCancel={cancelCallback}
      placement={placement}
      disabled={disabled}
      {...visibleProp}
      {...onCancelProp}
    >
      {children}
    </Popconfirm>
  )
}
