import AppAvatarMenu from './AppAvatarMenu'
import AppMenu from './AppMenu'
import { If } from '../utils'
import { Layout } from 'antd'
import { Link } from 'react-router-dom'
import React from 'react'
import styled from 'styled-components'

const StyledAppHeader = styled(Layout.Header)`
  padding: 0;

  & .nav-menu {
    background-color: transparent;
    @media (max-width: 1000px) {
      display: none;
    }
  }

  & .logo {
    font-weight: 700;
    font-size: 18px;
    .stage {
      margin-left: 10px;
      color: var(--theme-color-dark);
    }
  }

  & .nav-link {
    line-height: 64px;
    a {
      display: inline-block;
      color: var(--theme-text-color);
      padding: 0 30px;
      &:hover {
        color: var(--theme-color-dark);
      }
    }
  }

  & .user {
    line-height: ${({ $showUserSimulator }) =>
      $showUserSimulator ? '32px' : '44px'};
    padding: 10px 30px;

    & > div {
      text-align: center;
    }

    .ant-avatar {
      background-color: var(--theme-color-light);
      color: var(--theme-color-accent);
    }

    .group {
      color: var(--theme-color-dark);
      line-height: 14px;
      font-size: 10px;
      font-weight: bold;
      text-align: center;
      margin-top: 2px;
    }

    &:hover {
      cursor: pointer;
      .ant-avatar {
        background-color: var(--theme-color-medium);
        border-color: var(--theme-color-accent);
      }
      .group {
        color: var(--theme-color-accent);
      }
    }
  }
`

export default function AppHeader({
  isAuthenticated,
  user,
  simulatedUser,
  onSimulateUser,
  onNavigate,
  onLogout,
}) {
  const showUserSimulator = user.group === 'owner' || user.group === 'admin'
  return (
    <StyledAppHeader $showUserSimulator={showUserSimulator}>
      <div className='pull-left nav-link logo'>
        <Link to='/'>
          Intelecto
          {process.env.REACT_APP_Stage !== 'prod' && (
            <span className='stage'>{process.env.REACT_APP_Stage}</span>
          )}
        </Link>
      </div>
      <If
        condition={isAuthenticated}
        then={
          <>
            <AppMenu user={simulatedUser} onNavigate={onNavigate} />
            <div className='pull-right user'>
              <AppAvatarMenu
                onLogout={onLogout}
                user={user}
                simulatedUser={simulatedUser}
                showUserSimulator={showUserSimulator}
                onSimulateUser={onSimulateUser}
              />
            </div>
          </>
        }
        else={
          <div className='pull-right nav-link'>
            <Link to='/entrar'>Entrar</Link>
          </div>
        }
      />
    </StyledAppHeader>
  )
}
