export function If({
  condition,
  then: thenChildren,
  else: elseChildren,
  children,
}) {
  return (
    condition
    ? (thenChildren ?? children)
    : (elseChildren ?? null)
  );
}
