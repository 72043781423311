import { List, Tag } from "antd";

import React from "react";
import styled from "styled-components";

const StyledItem = styled(List.Item)`
  cursor: pointer;

  ${({$isList}) => $isList && "padding: 12px;"}

  &:hover {
    background-color: var(--theme-color-light);
  }

  & h4 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
  }
`;

export default function EssayListItem({
  title,
  theme,
  status,
  labelStatus,
  onClick,
  isList = false,
}) {
  const style = {
    pendente: "warning",
    atrasada: "error",
    enviada: "default",
    corrigida: "success",
  }[labelStatus];

  const label = {
    pendente: "Envio pendente",
    atrasada: "Envio atrasado",
    enviada: "Aguardando correção",
    corrigida: "Correção disponível",
  }[labelStatus];

  return (
    <StyledItem onClick={onClick} $isList={isList}>
      <List.Item.Meta
        title={(
          !status
          ? <>&nbsp;</>
          : <>
              <span>{title}</span>
              <Tag className="pull-right" color={style}>
                {label}
              </Tag>
            </>
        )}
        description={!status ? "" : theme}
      />
    </StyledItem>
  );
}
