import React, { useState } from 'react'
import {
  TextAreaType,
  TextType,
  TokenType,
} from '../../components/data/fieldtypes'

import { API } from 'aws-amplify'
import IconButton from '../../components/ui/IconButton'
import PageWrapper from '../../components/ui/PageWrapper'
import { Progress } from 'antd'
import VerticalSpace from '../../components/ui/VerticalSpace'
import { delay } from '../../libs/utils'
import styled from 'styled-components'

const REQUEST_DELAY = 1000

const RESOURCES = [
  '/course',
  '/course-resource',
  '/produto',
  '/aluno',
  '/teacher',
  '/subject',
  '/material',
  '/tema',
  '/redacao',
  '/exam',
  '/exam-question-set',
  '/exam-answer',
  '/user',
  '/transaction',
  '/live-exam',
]

const StyledProgress = styled(Progress)`
  path {
    transition: ${({ $delay }) => `
      stroke-dashoffset ${$delay}s ease 0s,
      stroke-dasharray ${$delay}s ease 0s,
      stroke ${$delay}s ease 0s,
      stroke-width 0.06s ease 0.3s,
      opacity ${$delay}s ease 0s
    `}!important;
  }
`

export default function APIConsole(props) {
  const execute = (parsedBody) => {
    const payload =
      method === 'GET' || method === 'DEL' ? undefined : { body: parsedBody }
    return API[method.toLowerCase()](
      'api',
      parsedBody ? path.replace(':id', parsedBody.id) : path,
      payload
    )
  }

  const submit = async () => {
    setLoading(0)
    await delay(0)

    // eslint-disable-next-line no-eval
    const parsedBody = [eval(body)].flat()

    const results = []
    for (let i = 0; i < parsedBody.length; i++) {
      results.push(await execute(parsedBody[i]))
      setLoading(Math.round((100 * (i + 1)) / parsedBody.length))
      await delay(REQUEST_DELAY)
    }

    setBody(JSON.stringify(results))
    setLoading(false)
  }

  const [method, setMethod] = useState('GET')
  const [path, setPath] = useState('/')
  const [body, setBody] = useState('{}')
  const [loading, setLoading] = useState(false)

  return (
    <PageWrapper>
      {loading === false ? (
        <VerticalSpace>
          <div style={{ display: 'flex' }}>
            <div style={{ width: '100px' }}>
              {TokenType.render(method, setMethod, {
                tokens: ['GET', 'POST', 'PUT', 'DEL'],
              })}
            </div>
            <div style={{ width: '100%' }}>
              {TextType.render(path, setPath, {
                getSuggestions: (text) =>
                  RESOURCES.filter((resource) => resource.startsWith(text)).map(
                    (value) => ({ value })
                  ),
              })}
            </div>
          </div>
          {TextAreaType.render(body, setBody, { allowResize: true })}
          <IconButton.Submit block danger={method !== 'GET'} onClick={submit} />
        </VerticalSpace>
      ) : (
        <div className='center'>
          <StyledProgress
            type='circle'
            status='normal'
            width={200}
            percent={loading}
            $delay={(2 * REQUEST_DELAY) / 1000}
          />
        </div>
      )}
    </PageWrapper>
  )
}
