import { Card, Typography } from 'antd'

import EssaySheet from '../../essay/EssaySheet'
import { FileType } from '../../data/fieldtypes'
import React from 'react'
import VerticalSpace from '../../ui/VerticalSpace'

export default function ExamEssay({
  readOnly,
  essayTheme,
  essayText,
  onChangeEssay,
}) {
  return (
    <Card key='essay' title={<h4>Redação: {essayTheme.tema}</h4>}>
      <VerticalSpace>
        <div>{FileType.display(essayTheme.material)}</div>
        <Typography.Text type='secondary'>
          Clique no botão acima para baixar o material de apoio do tema da
          redação em arquivo PDF. Em seguida, escreva sua redação no espaço
          abaixo. Um rascunho será salvo automaticamente a cada 60 segundos, e
          você pode navegar entre as questões e retornar depois para continuar
          escrevendo.
        </Typography.Text>
        <EssaySheet
          rows={30}
          value={essayText || ''}
          readOnly={readOnly}
          onBlur={(text) => onChangeEssay(text, true)}
          onChange={(text) => onChangeEssay(text, false)}
        />
      </VerticalSpace>
    </Card>
  )
}
