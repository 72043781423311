import { Modal } from 'antd'
import React from 'react'

export default function SimpleModal({
  show,
  title,
  buttons,
  allowClose = false,
  showBackdrop = true,
  onOk,
  onCancel,
  onClose,
  width,
  centered,
  children,
}) {
  return (
    <Modal
      title={title}
      visible={show}
      footer={buttons}
      closable={!!allowClose}
      keyboard={!!allowClose}
      mask={!!showBackdrop}
      maskClosable={!!allowClose}
      onOk={onOk}
      onCancel={onCancel}
      afterClose={onClose}
      width={width}
      centered={centered}
    >
      {children}
    </Modal>
  )
}
