import DataFormContents from './DataFormContents'
import { Form } from 'antd'
import React from 'react'
import { mapObject } from '../../libs/utils'

export default function DataForm({
  dataList,
  schema,
  user,
  initialValues,
  fields,
  readOnly = false,
  setFormCallbacks = () => {},
}) {
  const [form] = Form.useForm()

  setFormCallbacks({
    getValues: () => {
      const newValues = form.getFieldsValue(true)
      return mapObject(initialValues, (field) => newValues[field])
    },
    reset: () => form.resetFields(),
    validate: async () => {
      try {
        await form.validateFields()
        return true
      } catch (validationError) {
        form.scrollToField(validationError.errorFields[0].name)
        return false
      }
    },
  })

  return (
    <Form
      form={form}
      initialValues={initialValues}
      layout={readOnly ? 'horizontal' : 'vertical'}
      validateTrigger={['onChangeValidate', 'onBlurValidate']}
      requiredMark={!readOnly}
      onValuesChange={(values) => {
        Object.keys(values).forEach((name) => {
          const error = form.getFieldError(name)
          if (error.length > 0) {
            form.setFields([{ name, errors: [] }])
          }
        })
      }}
    >
      <DataFormContents
        dataList={dataList}
        schema={schema}
        user={user}
        fields={fields}
        readOnly={readOnly}
      />
    </Form>
  )
}
