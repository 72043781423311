import JSZip from 'jszip'
import XlsxTemplate from 'xlsx-template'
import createReport from 'docx-templates'
import { message } from 'antd'
import removeAccents from 'remove-accents'

export function fn(value) {
  return () => value
}

export function fnwrap(value) {
  return value instanceof Function ? value : fn(value)
}

export function pipe(value, fn, ...fns) {
  return !fn ? value : pipe(fn(value), ...fns)
}

export function arrayToObject(arr) {
  return arr.reduce((obj, value, index) => ({ ...obj, [index]: value }), {})
}

export function objectToArray(obj) {
  return sortArray(Object.keys(obj)).map((key) => obj[key])
}

export function sumArray(arr) {
  return arr.reduce((a, b) => (a ?? 0) + (b ?? 0), 0)
}

export function getNested(obj, fieldList) {
  if (!fieldList || fieldList.length === 0) return obj
  const [parentField, ...childFields] = Array.isArray(fieldList)
    ? fieldList
    : fieldList.split('.')
  return getNested(obj[parentField], childFields.join(''))
}

export function getThemeColor(variant = '') {
  return window
    .getComputedStyle(document.documentElement)
    .getPropertyValue('--theme-color' + (variant ? `-${variant}` : ''))
}

export function querystring(name, url = window.location.href) {
  const regex = new RegExp(
    '[?&]' + name.replace(/[[]]/g, '\\$&') + '(=([^&#]*)|&|#|$)',
    'i'
  )
  const results = regex.exec(url)
  if (!results) {
    return null
  } else if (!results[2]) {
    return ''
  } else {
    return decodeURIComponent(results[2].replace(/\+/g, ' '))
  }
}

export function sortArray(array, fn = (x) => x, order = 'asc') {
  const mod = [order].flat().map((order) => (order === 'desc' ? -1 : 1))
  const compare = (fna, fnb, i = 0) =>
    fna[i] > fnb[i]
      ? 1 * (mod[i] ?? mod[0])
      : fna[i] < fnb[i]
      ? -1 * (mod[i] ?? mod[0])
      : i + 1 < fna.length
      ? compare(fna, fnb, i + 1)
      : 0
  return array.sort((a, b) => compare([fn(a)].flat(), [fn(b)].flat()))
}

export function toggleArrayItem(array, item) {
  return array.includes(item)
    ? array.filter((i) => i !== item)
    : [...array, item]
}

export function pluralize(count, singular, plural) {
  return count <= 1 ? singular : plural ?? `${singular}s`
}

export async function replaceAsync(str, regex, fn) {
  const promises = []
  str.replace(regex, (match, ...args) => {
    const promise = fn(match, ...args)
    promises.push(promise)
  })
  const data = await Promise.all(promises)
  return str.replace(regex, () => data.shift())
}

export function filterObject(object = {}, fn = (k, v) => true) {
  return Object.fromEntries(
    Object.entries(object).filter(([key, value]) => fn(key, value))
  )
}

export function mapObject(
  object = {},
  fnValue = (k, v) => v,
  fnKey = (k, v) => k,
  removeUndefined = false
) {
  return Object.fromEntries(
    pipe(
      Object.entries(object).map(([key, value]) => [
        fnKey(key, value),
        fnValue(key, value),
      ]),
      (entries) =>
        removeUndefined
          ? entries.filter(([, value]) => value !== undefined)
          : entries
    )
  )
}

export async function mapObjectAsync(object = {}, fn = (k, v) => v) {
  return Object.fromEntries(
    await Promise.all(
      Object.entries(object).map(([key, value]) => {
        return new Promise(async (resolve) => {
          const v = await fn(key, value)
          resolve([key, v])
        })
      })
    )
  )
}

export async function mapArrayAsync(array = [], fn = (v) => v) {
  return Promise.all(array.map((value) => fn(value)))
}

export function mapFields(list, keyField, valueField = null) {
  return list.reduce(
    (result, l) => ({
      ...result,
      [getNested(l, keyField)]: valueField ? l[valueField] : l,
    }),
    {}
  )
}

export function leftJoin(
  leftList,
  leftField,
  rightList,
  rightField,
  cb,
  skipUndefined = true
) {
  const map = mapFields(rightList, rightField)
  return leftList.reduce((result, l) => {
    const element = cb(l, map[getNested(l, leftField)])
    if (!skipUndefined || element !== undefined) {
      result.push(element)
    }
    return result
  }, [])
}

export function downloadFromArrayBuffer(buffer, filename) {
  const blob = new Blob([buffer])
  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(blob, filename)
  } else {
    const link = document.createElement('a')
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob)
      link.setAttribute('href', url)
      link.setAttribute('download', filename)
      link.style.visibility = 'hidden'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }
}

export function getLinkFromFileData(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () => resolve(reader.result)
    reader.onerror = reject
    reader.readAsDataURL(file)
  })
}

async function fetchTemplate(templateName) {
  const resp = await fetch(`/templates/${templateName}`)
  return resp.arrayBuffer()
}

function createXlsxFromTemplate(template, data) {
  const templater = new XlsxTemplate(template)
  templater.substitute(1, data)
  return templater.generate({ type: 'arraybuffer' })
}

export async function downloadAsXlsx(templateName, filename, data) {
  const template = await fetchTemplate(templateName)

  downloadFromArrayBuffer(
    await createXlsxFromTemplate(template, data),
    filename
  )
}

function createDocxFromTemplate(template, data) {
  return createReport({
    template,
    cmdDelimiter: ['{', '}'],
    data,
  })
}

export async function downloadAsDocx(templateName, filename, data) {
  const template = await fetchTemplate(templateName)

  downloadFromArrayBuffer(
    await createDocxFromTemplate(template, data),
    filename
  )
}

export async function downloadAsDocxZip(templateName, zipName, dataArray) {
  const template = await fetchTemplate(templateName)

  let zip = new JSZip()
  await Promise.all(
    dataArray.map(async ({ filename, data }) => {
      zip.file(filename, await createDocxFromTemplate(template, data), {
        binary: true,
      })
    })
  )

  downloadFromArrayBuffer(
    await zip.generateAsync({ type: 'arraybuffer' }),
    zipName
  )
}

export function isBetween(value, start, end) {
  return value >= start && value <= end
}

export function checkOverlaps(start1, end1, start2, end2) {
  return isBetween(start1, start2, end2) || isBetween(end1, start2, end2)
}

export function assert(condition, message) {
  if (!condition) {
    throw message
  }
  return true
}

export function throwError(err) {
  throw new Error(err)
}

export function stopPropagation(e) {
  return e.stopPropagation()
}

export function preventDefault(e) {
  return e.preventDefault()
}

export function copyToClipboard(e) {
  navigator.clipboard.writeText(e.target.innerText)
  stopPropagation(e)
  message.success('Copiado!')
}

export function delay(t) {
  return new Promise((resolve) => setTimeout(() => resolve(), t))
}

export function memoized(fn, getKey) {
  const cache = {}
  return (...args) => {
    const key = getKey(...args)
    cache[key] = cache[key] ?? fn(...args)
    return cache[key]
  }
}

export function stringMatch(str1, str2) {
  const parsedStr1 = !str1 ? null : removeAccents(str1).toLowerCase()
  const parsedStr2 = !str2 ? null : removeAccents(str2).toLowerCase()
  return parsedStr1?.includes(parsedStr2 ?? '') ?? false
}
