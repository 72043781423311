import React, { useEffect, useState } from 'react'
import { enemCriterias, enemScoreTiers } from './enemCriterias'

import EssayListItem from './EssayListItem'
import EssayPopupResultView from './EssayPopupResultView'
import EssayPopupSubmitView from './EssayPopupSubmitView'
import { FileType } from '../data/fieldtypes'
import IconButton from '../ui/IconButton'
import { PaperClipIcon } from '../ui/Icons'
import SimpleModal from '../ui/SimpleModal'
import { useResponsiveness } from '../../libs/hooksLib'

export default function EssayPopup({
  show,
  title,
  theme,
  essayType,
  status,
  material,
  essayText,
  essayFile,
  resultText,
  resultFile,
  resultGrade,
  labelStatus,
  onChangeText,
  onChangeFile,
  onCancel,
  onSave,
  onSubmit,
  onClose,
}) {
  const { isTablet } = useResponsiveness()
  const [isEmpty, setIsEmpty] = useState(true)
  const [isSaving, setIsSaving] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(() => {
    setIsEmpty(
      (!essayText || essayText.length === 0) && FileType.isEmpty(essayFile)
    )
  }, [essayText, essayFile])

  const gradeArray =
    resultGrade === undefined
      ? Array(essayType === 'ENEM' ? 5 : 1).fill(undefined)
      : [resultGrade].flat().map((g) => {
          const pg = typeof g === 'string' ? parseFloat(g.replace(',', '.')) : g
          return Number.isNaN(pg) ? undefined : pg
        })

  return (
    <SimpleModal
      show={show}
      allowClose={false}
      width={848}
      centered={true}
      title={
        <EssayListItem
          title={
            <>
              <PaperClipIcon /> {title}
            </>
          }
          theme={theme}
          status={status}
          labelStatus={labelStatus}
          onClick={() => FileType.downloadFile(material)}
        />
      }
      buttons={
        <>
          <IconButton.Cancel
            hidden={status !== 'pendente'}
            onClick={onCancel}
          />
          {!isTablet && (
            <IconButton.Save
              hidden={status !== 'pendente'}
              text='Salvar para depois'
              type='default'
              onClick={() => {
                setIsSaving(true)
                onSave()
                setIsSaving(false)
              }}
              loading={isSaving}
            />
          )}
          <IconButton.Submit
            hidden={status !== 'pendente'}
            text='Enviar para correção'
            onClick={() => {
              setIsSubmitting(true)
              onSubmit()
              setIsSubmitting(false)
            }}
            loading={isSubmitting}
            disabled={isEmpty}
          />
          <IconButton.Cancel
            hidden={status === 'pendente'}
            text='Fechar'
            onClick={onClose}
          />
        </>
      }
    >
      {status === 'corrigida' ? (
        <EssayPopupResultView
          essayType={essayType}
          gradeArray={gradeArray}
          resultFile={resultFile}
          resultText={resultText}
          enemCriterias={enemCriterias}
          enemScoreTiers={enemScoreTiers}
        />
      ) : (
        <EssayPopupSubmitView
          status={status}
          essayType={essayType}
          essayFile={essayFile}
          essayText={essayText}
          onChangeText={onChangeText}
          onChangeFile={onChangeFile}
          setIsEmpty={setIsEmpty}
        />
      )}
    </SimpleModal>
  )
}
