export default {
  s3: {
    REGION: process.env.REACT_APP_Region,
    BUCKET: process.env.REACT_APP_UploadsBucketName,
  },
  apiGateway: {
    REGION: process.env.REACT_APP_Region,
    URL: process.env.REACT_APP_ServiceEndpoint,
  },
  cognito: {
    REGION: process.env.REACT_APP_Region,
    USER_POOL_ID: process.env.REACT_APP_UserPoolId,
    APP_CLIENT_ID: process.env.REACT_APP_UserPoolClientId,
    IDENTITY_POOL_ID: process.env.REACT_APP_IdentityPoolId,
  },
}
