import { Helmet } from 'react-helmet'
import LoaderContent from './LoaderContent'
import React from 'react'
import VerticalSpace from './VerticalSpace'
import { useRouteConfig } from '../routing/routingConfig'

export default function PageWrapper({ showTitle = true, isLoading, children }) {
  const routeConfig = useRouteConfig()
  return (
    <div>
      <Helmet>
        <title>Intelecto - {routeConfig.title}</title>
      </Helmet>
      <VerticalSpace>
        {showTitle && <h1>{routeConfig.title}</h1>}
        <LoaderContent isLoading={isLoading}>{children}</LoaderContent>
      </VerticalSpace>
    </div>
  )
}
