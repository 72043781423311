import { DateTimeType } from './DateTimeType'

export class DateTimeRangeType extends DateTimeType {
  static getDefaultTypeParams() {
    return DateTimeType.extendTypeParams({
      rangePicker: true,
    })
  }

  static isEmpty = (value) => DateTimeType.isEmpty(value, { rangePicker: true })
}
