import {
  DownloadIcon,
  LoadingIcon,
  ReloadIcon,
  TableIcon,
} from '../../ui/Icons'
import { Dropdown, Menu, Space, Switch } from 'antd'
import React, { useState } from 'react'
import { preventDefault, sortArray, sumArray } from '../../../libs/utils'

import DashboardStatistic from '../../ui/DashboardStatistic'
import ExamAnswers from '../ExamAnswers'
import IconButton from '../../ui/IconButton'
import SimpleModal from '../../ui/SimpleModal'

// TODO:
// - exam info (name, status)
// - stat numbers (total students, best score, best student name)
// - new buttons: start, end, send message
// - sorters, filters, scramblers (for screenshot/video)
// - new stat for time progress
// - new stat for essays written

export default function ExamDashboardHeader({
  examName,
  examStudents,
  questionMap,
  answersMap,
  startedExamsCount,
  deliveredExamsCount,
  isDownloading,
  isPolling,
  onDownloadResults,
  onDownloadEssaysDocx,
  onDownloadEssaysZip,
  onTogglePolling,
}) {
  const [showAnswersModal, setShowAnswersModal] = useState(false)

  const totalHits = sumArray(
    Object.values(answersMap).map((a) => a?.hitCount ?? 0)
  )
  const totalMiss = sumArray(
    Object.values(answersMap).map((a) => a?.missCount ?? 0)
  )
  const [bestScore, bestStudent] = sortArray(
    Object.entries(answersMap).map(([student, answer]) => [
      !answer ? 0 : answer.hitCount / (answer.hitCount + answer.missCount),
      student,
    ]),
    (answer) => answer[0],
    'desc'
  )[0]
  console.log('Best student:', bestStudent)
  const stats = [
    {
      label: 'Acessos',
      percent: (100 * startedExamsCount) / examStudents.length,
      color: 'default',
    },
    {
      label: 'Acertos',
      percent: (100 * totalHits) / (totalHits + totalMiss),
      color: 'success',
    },
    {
      label: 'Melhor',
      percent: 100 * bestScore,
      color: 'accent',
    },
    {
      label: 'Entregas',
      percent: (100 * deliveredExamsCount) / startedExamsCount,
      color: 'theme',
    },
  ]

  return (
    <div style={{ textAlign: 'center' }}>
      <Space size='large'>
        {stats.map((stat) => (
          <DashboardStatistic key={stat.label} {...stat} />
        ))}
      </Space>
      <Space className='pull-right'>
        <Switch
          checked={isPolling}
          onChange={onTogglePolling}
          checkedChildren={<ReloadIcon />}
          unCheckedChildren={<ReloadIcon />}
        />
        <a href='#!' onClick={() => setShowAnswersModal(true)}>
          <TableIcon />
        </a>
        <Dropdown
          placement='bottomRight'
          overlay={
            <Menu>
              {isDownloading ? (
                <Menu.Item key='downloading'>
                  <LoadingIcon />
                  <span>Baixando...</span>
                </Menu.Item>
              ) : (
                <>
                  <Menu.Item key='downloadResults' onClick={onDownloadResults}>
                    Baixar resultados (.xlsx)
                  </Menu.Item>
                  <Menu.Item
                    key='downloadEssaysDocx'
                    onClick={onDownloadEssaysDocx}
                  >
                    Baixar redações (.docx)
                  </Menu.Item>
                  <Menu.Item
                    key='downloadEssaysZip'
                    onClick={onDownloadEssaysZip}
                  >
                    Baixar redações (.zip)
                  </Menu.Item>
                </>
              )}
            </Menu>
          }
        >
          <a href='#!' onClick={preventDefault}>
            <DownloadIcon />
          </a>
        </Dropdown>
      </Space>
      <SimpleModal
        show={showAnswersModal}
        width={800}
        buttons={<IconButton.Okay onClick={() => setShowAnswersModal(false)} />}
        allowClose
        onCancel={() => setShowAnswersModal(false)}
      >
        <ExamAnswers examName={examName} questionMap={questionMap} />
      </SimpleModal>
      <hr />
    </div>
  )
}
