import { Input } from 'antd'
import React from 'react'
import { TextType } from './TextType'

export class PasswordType extends TextType {
  static getDefaultTypeParams() {
    return TextType.extendTypeParams({
      maskCharacter: '*',
    })
  }

  static renderer = ({ value, onChange, onBlur, readOnly, disabled }) => {
    return (
      <Input.Password
        autoComplete='new-password'
        value={value}
        onChange={(e) => onChange(e.target.value)}
        onBlur={onBlur}
        readOnly={readOnly}
        disabled={disabled}
      />
    )
  }

  static formatter = (value, { maskCharacter }) =>
    maskCharacter.repeat(String(value).length)

  static validators = async (value, typeParams) => [
    // TODO: require lowercase, uppercase, number, special chars...
    await TextType.validate(value, TextType.filterParams(typeParams)),
  ]
}
