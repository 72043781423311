import {
  ArrayType,
  PasswordType,
  RefType,
  TextType,
  TokenType,
} from '../../components/data/fieldtypes'
import { CrossIcon, StudentIcon } from '../../components/ui/Icons'

import CRUDPage from '../../components/data/CRUDPage'
import { EmailType } from '../../components/data/fieldtypes/string/EmailType'
import React from 'react'
import { appRoutes } from '../../components/routing/routingConfig'

export default function Users(props) {
  return (
    <CRUDPage
      user={props.user}
      history={props.history}
      params={props.match.params}
      resource='/user'
      dependencies={{
        productQuery: '/produto',
        studentQuery: '/aluno',
      }}
      schema={({ studentQuery, productQuery }) => ({
        email: new EmailType({ unique: true }),
        password: new PasswordType(),
        name: new TextType(),
        firstname: new TextType({ optional: true }),
        lastname: new TextType({ optional: true }),
        addr: new TextType({ optional: true }),
        city: new TextType({ optional: true }),
        phone: new TextType({ optional: true }),
        detail_prova: new TextType({ optional: true }),
        group: new TokenType({
          tokens: [
            { guest: 'Visitante' },
            { student: 'Aluno' },
            { teacher: 'Professor' },
            { admin: 'Administrador' },
            { owner: 'Root' },
          ],
        }),
        student: new RefType({
          query: studentQuery,
          displayFn: (record) => `${record.nome} ${record.sobrenome}`,
          routeConfig: appRoutes.admin.students,
          format: () => <StudentIcon />,
          formatEmpty: <CrossIcon />,
          optional: true,
          unique: true,
        }),
        product: new ArrayType({
          childrenType: new RefType({
            query: productQuery,
            display: 'nome',
            sortFn: (p) => p.category + p.ordem,
            routeConfig: appRoutes.admin.servicePacks,
          }),
          optional: true,
        }),
      })}
      listColumns={[
        {
          header: 'Aluno',
          name: 'student',
          filter: false,
        },
        {
          header: 'Nome',
          name: 'name',
        },
        {
          header: 'Email',
          name: 'email',
        },
        {
          header: 'Acesso',
          name: 'group',
        },
        {
          header: 'Pacotes',
          name: 'product',
        },
      ]}
      formFields={[
        {
          name: 'student',
          label: 'Aluno',
        },
        {
          name: 'email',
          label: 'Email',
          readOnlyFn: CRUDPage.isUpdating,
        },
        {
          name: 'password',
          label: 'Senha',
          optionalFn: CRUDPage.isUpdating,
        },
        {
          name: 'group',
          label: 'Acesso',
          disabledFn: ({ record }) => record.id === props.user.id,
        },
        {
          name: 'product',
          label: 'Pacotes',
        },
        {
          name: 'firstname',
          label: 'Nome',
        },
        {
          name: 'lastname',
          label: 'Sobrenome',
        },
        {
          name: 'addr',
          label: 'Endereço',
        },
        {
          name: 'city',
          label: 'Cidade',
        },
        {
          name: 'phone',
          label: 'Telefone',
        },
        {
          name: 'detail_prova',
          label: 'Objetivo (prova)',
        },
      ]}
      crudOptions={{
        allow: {
          delete: (record) => record?.id !== props.user.id,
        },
        hooks: {
          predictUpdate: (record) => ({
            ...record,
            name: [record.firstname, record.lastname]
              .filter((n) => !!n)
              .join(' '),
          }),
          afterSave: (record) =>
            record.id === props.user.id && props.refreshUserData(),
        },
      }}
    />
  )
}
