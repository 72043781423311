import React, { useRef } from 'react'
import { Table, Tooltip } from 'antd'
import { getThemeColor, mapObject, sortArray } from '../../libs/utils'

import { SearchIcon } from '../ui/Icons'
import { useResponsiveness } from '../../libs/hooksLib'
import { useViewSettings } from './viewSettings'

export default function DataTable({
  className = '',
  dataList,
  mapRecord = (r) => r,
  schema = {},
  user,
  columns,
  onSelectRow,
  sortColumn,
  sortOrder,
  sortFunction,
  sortFunctionOrder,
  filterFunction = () => true,
  expandFunction,
}) {
  const viewSettings = useViewSettings()
  const { isMobile } = useResponsiveness()
  const filterRef = useRef(null)

  sortColumn = viewSettings.sort?.replace('-', '') ?? sortColumn
  sortOrder = !viewSettings.sort
    ? sortOrder
    : viewSettings.sort[0] === '-'
    ? 'descend'
    : 'ascend'

  const getSchemaProxy = (record) => () =>
    mapObject(schema, (field, type) =>
      ['render', 'display', 'format', 'validate', 'compare'].reduce(
        (schemaProxy, method) => ({
          ...schemaProxy,
          [method]: (...args) => type[method](record[field], ...args),
        }),
        { type }
      )
    )

  const sortFn = sortFunction ?? ((record) => record?.[columns[0].name])
  const list = sortArray(
    dataList
      .map(mapRecord)
      .filter((record) => filterFunction(record, getSchemaProxy(record))),
    (record) => sortFn(record, getSchemaProxy(record)),
    sortFunctionOrder
  )

  return (
    <div className={`DataTable ${className}`}>
      <Table
        rowKey='id'
        dataSource={list}
        columns={columns
          .filter((col) => !col.hidden)
          .map(({ typeParams = {}, ...col }) => {
            const type = col.type ?? schema[col.name]
            const typeFilters = typeParams.filters ?? type?.getParams().filters
            const filterProps = {}
            if (col.filter !== false && typeFilters) {
              filterProps.filterIcon = (filtered) => (
                <SearchIcon
                  style={{
                    color: filtered ? getThemeColor('accent') : undefined,
                  }}
                />
              )
              filterProps.onFilter = (filter, record) =>
                type.compareFilter(record[col.name], filter, {
                  ...typeParams,
                  getRecord: () => record,
                })
              filterProps.filteredValue = viewSettings.filters[col.name] ?? null
              if (typeFilters === 'custom') {
                filterProps.filterDropdown = ({
                  selectedKeys,
                  setSelectedKeys,
                  confirm,
                }) => (
                  <div style={{ padding: 8 }}>
                    {type.renderFilter(
                      selectedKeys,
                      setSelectedKeys,
                      confirm,
                      filterRef,
                      typeParams
                    )}
                  </div>
                )
                filterProps.onFilterDropdownVisibleChange = (visible) => {
                  if (visible && filterRef?.current) {
                    setTimeout(() => filterRef.current.focus(), 100)
                  }
                }
              } else if (Array.isArray(typeFilters)) {
                filterProps.filters = typeFilters
              }
            }
            return {
              title: col.header,
              dataIndex: col.name,
              // TODO: use type to sort correctly
              sorter:
                col.sort === false
                  ? undefined
                  : (a, b) => `${a[col.name]}`.localeCompare(`${b[col.name]}`),
              sortDirections: ['ascend', 'descend'],
              defaultSortOrder:
                sortColumn === col.name ? sortOrder || 'ascend' : undefined,
              render: (value, record) => {
                const formattedValue = !type
                  ? value
                  : type.format(value, {
                      getRecord: () => record,
                      user,
                      ...typeParams,
                    })
                const contents = col.render
                  ? col.render({ value, formattedValue, record, ...typeParams })
                  : formattedValue
                return col.ellipsis ? (
                  <Tooltip title={contents}>{contents}</Tooltip>
                ) : (
                  contents
                )
              },
              align: col.align,
              headerAlign: col.align,
              ellipsis: col.ellipsis ? { showTitle: false } : false,
              ...filterProps,
            }
          })}
        expandable={{
          expandRowByClick: !!expandFunction,
          expandIconColumnIndex: isMobile ? -1 : 0,
          expandedRowRender: !expandFunction
            ? undefined
            : (record) => expandFunction(record, getSchemaProxy(record)),
        }}
        pagination={{
          defaultCurrent: viewSettings.page,
          defaultPageSize: viewSettings.pageSize,
          showQuickJumper: false,
          showSizeChanger: true,
          position: ['topLeft', 'bottomLeft'],
          simple: isMobile,
          hideOnSinglePage: isMobile,
          showTotal: (total) => {
            const first = (viewSettings.page - 1) * viewSettings.pageSize + 1
            const last = viewSettings.page * viewSettings.pageSize
            return `Mostrando ${first}-${Math.min(last, total)} de ${total}`
          },
        }}
        onRow={
          expandFunction
            ? undefined
            : (row) => ({
                onClick: () => onSelectRow(row.id),
              })
        }
        onChange={(pagination, filters, sorter) => {
          viewSettings.update({ pagination, filters, sorter })
        }}
        rowClassName={!onSelectRow ? undefined : () => 'clickable'}
        locale={{
          filterTitle: 'Filtros',
          filterConfirm: 'OK',
          filterReset: 'Limpar filtros',
          filterEmptyText: 'Sem filtros',
          selectAll: 'Selecionar página inteira',
          selectInvert: 'Inverter página inteira',
          selectionAll: 'Selecionar tudo',
          sortTitle: 'Ordenar',
          expand: 'Expandir linha',
          collapse: 'Recolher linha',
          triggerDesc: 'Ordenar por ordem decrescente',
          triggerAsc: 'Ordenar por ordem crescente',
          cancelSort: 'Desfazer ordenação',
        }}
      />
    </div>
  )
}
