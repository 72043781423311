import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'

import { API } from 'aws-amplify'
import { useMediaQuery } from '@react-hook/media-query'

export function useResponsiveness() {
  const isMobile = useMediaQuery('(max-width: 480px)')
  const isTablet = useMediaQuery('(max-width: 880px)')
  return { isMobile, isTablet }
}

export const AppContext = createContext(null)

export function useAppContext() {
  return useContext(AppContext)
}

export function useAppContextState() {
  const [isProcessing, setIsProcessing] = useState(false)
  return {
    isProcessing,
    startProcessing: () => setIsProcessing(true),
    stopProcessing: () => setIsProcessing(false),
    // TODO: loading, maybe others
  }
}

// TODO - remove this or move somewhere else
export function useMultiState(initialState) {
  const [state, setState] = useState(initialState)
  return [
    state,
    (field, value) => setState((state) => ({ ...state, [field]: value })),
    setState,
  ]
}

// TODO - remove this after refactoring ExamDashboard
export function useDependency(resource, filters) {
  const [dataList, setDataList] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [count, setCount] = useState(0)

  useEffect(() => {
    let isReadyToFetch = true
    let query = ''
    if (filters !== undefined) {
      query =
        '?' +
        Object.keys(filters)
          .map((field) => {
            if (filters[field] === undefined) {
              isReadyToFetch = false
            }
            return `${field}=${filters[field]}`
          })
          .join('&')
    }

    async function onLoad() {
      setIsLoading(true)
      try {
        const list = await API.get('api', `/${resource}${query}`)
        setDataList(list)
      } catch (e) {
        console.error(e)
      }
      setIsLoading(false)
    }

    if (isReadyToFetch) {
      onLoad()
    }
  }, [resource, filters, count])

  const refresh = useCallback(() => {
    setCount((c) => c + 1)
  }, [])

  return [dataList, isLoading, refresh]
}
