import { API } from 'aws-amplify'
import { Button } from 'antd'
import { CurrencyType } from './data/fieldtypes'
import React from 'react'
import apiKeys from '../config/apiKeys'
import { getThemeColor } from '../libs/utils'

export default function Checkout({
  product,
  maxInstallments = 1,
  onApprove,
  onSuccess,
  className = '',
  ...props
}) {
  return (
    <Button
      className={`Checkout ${className}`}
      size='large'
      type='primary'
      onClick={() => {
        const checkout = new window.PagarMeCheckout.Checkout({
          encryption_key: apiKeys.pagarme.encryptionKey,
          success: async (data) => {
            onApprove()
            await API.put('api', `/transaction/${data.token}`, {
              body: {
                product: product.id,
              },
            })
            onSuccess()
          },
        })
        checkout.open({
          amount: product.preco.replace('.', ''),
          buttonText: 'Pagar',
          uiColor: getThemeColor('dark'),
          customerData: 'false',
          createToken: 'true',
          paymentMethods: 'credit_card',
          maxInstallments: maxInstallments,
          items: [
            {
              id: product.id,
              title: product.nome,
              unit_price: product.preco.replace('.', ''),
              quantity: 1,
              tangible: false,
            },
          ],
        })
      }}
      {...props}
    >
      Pagar {CurrencyType.format(product.preco)} com cartão de crédito em até{' '}
      {maxInstallments}x
    </Button>
  )
}
