import { Card, Form } from 'antd'
import { assert, fn, getNested, pluralize } from '../../../../libs/utils'

import { BaseType } from '../BaseType'
import DataFormItemController from '../../DataFormItemController'
import React from 'react'
import { WrapIf } from '../../../utils'

export class BaseArrayType extends BaseType {
  static getDefaultTypeParams() {
    return BaseType.extendTypeParams({
      isSelfControlled: this.fromParams(
        ({ renderType }) => renderType === false,
        ['renderType']
      ),
      renderType: false,
      renderArray: this.required(),
      valueDefault: this.fromParams(
        ({ minLength, childrenType, childrenParams, renderType }) =>
          renderType !== false
            ? []
            : new Array(minLength).fill(
                childrenType.getParams(childrenParams).valueDefault
              ),
        ['minLength', 'childrenType', 'childrenParams', 'renderType']
      ),
      formatEmpty: '---',
      childrenType: this.required(),
      childrenParams: {},
      formatAsComponent: true,
      formatSeparator: ', ',
      formatArray: this.fromParams(
        ({ childrenType, childrenParams }) =>
          childrenType.getParams(childrenParams).formatAsComponent
            ? (items, sep) =>
                items.map((item, index) => (
                  <span key={index}>
                    {item}
                    {index < items.length - 1 ? sep : null}
                  </span>
                ))
            : (items, sep) => items.join(sep),
        ['childrenType', 'childrenParams']
      ),
      minLength: this.fromParams(
        ({ fixedLength, optional }) => fixedLength ?? (optional ? 0 : 1),
        ['fixedLength', 'optional']
      ),
      maxLength: this.fromParams(
        ({ fixedLength }) => fixedLength ?? Infinity,
        ['fixedLength']
      ),
      fixedLength: undefined,
      allowDuplicates: false,
      showBorder: false,
      getItemLabel: undefined,
      filters: this.fromParams(
        ({ childrenType, childrenParams }) =>
          childrenType.getParams(childrenParams).filters,
        ['childrenType', 'childrenParams']
      ),
      validationTriggers: ['change'],
    })
  }

  static isEmpty = (value) => value === undefined || value.length === 0

  static shouldUpdate = () => false

  static renderer = ({
    value,
    onChange,
    onBlur,
    renderType,
    ...typeParams
  }) => {
    const {
      user,
      getRecord,
      fieldPath,
      fullFieldPath,
      fieldLabel,
      renderArray,
      showBorder,
      childrenType,
      childrenParams,
      minLength,
      maxLength,
      fixedLength,
      readOnly,
      disabled,
      getItemLabel,
      ...otherTypeParams
    } = typeParams
    const record = getRecord()

    if (renderType) {
      return renderType.render(value, onChange, {
        ...typeParams.childrenParams,
        allowMultiple: true,
        optional: false,
        readOnly,
        disabled,
      })
    }

    const renderChild = (childProps) => {
      const childFullFieldPath = `${fullFieldPath}.${childProps.name}`
      return (
        <DataFormItemController
          key={childProps.key}
          className='BaseArrayType'
          user={user}
          type={childrenType}
          fieldPath={String(childProps.name)}
          fullFieldPath={childFullFieldPath}
          isListField={true}
          typeParamsFn={fn({
            fieldKey: childProps.fieldKey,
            ...childrenParams,
          })}
          optionalFn={fn(false)}
          disabledFn={fn(disabled)}
          readOnlyFn={fn(readOnly)}
          shouldUpdate={(prev, next) =>
            childrenType.shouldUpdate(
              getNested(prev, childFullFieldPath),
              getNested(next, childFullFieldPath)
            )
          }
        />
      )
    }

    return (
      <WrapIf condition={showBorder} wrapper={Card}>
        <Form.List
          name={fieldPath.split('.')}
          validateFirst={true}
          rules={this.getValidationRules(
            fieldLabel,
            ['save'],
            this.filterParams(typeParams)
          )}
        >
          {(childrenProps, { add, remove }, { errors }) => (
            <>
              {renderArray({
                childrenProps,
                renderChild,
                onAdd: () =>
                  add(childrenType.getParams(childrenParams).valueDefault),
                onRemove: (index) => remove(index),
                showAddRemoveButtons: !readOnly && fixedLength === undefined,
                allowAddItems: childrenProps.length < maxLength,
                allowRemoveItems: childrenProps.length > minLength,
                getItemLabel: !getItemLabel
                  ? undefined
                  : (index) => getItemLabel(index, record),
                ...otherTypeParams,
              })}
              <Form.ErrorList errors={errors} />
            </>
          )}
        </Form.List>
      </WrapIf>
    )
  }

  static displayer = ({ value, ...typeParams }) => {
    const {
      getRecord,
      renderArray,
      showBorder,
      childrenType,
      childrenParams,
      getItemLabel,
      ...otherTypeParams
    } = typeParams
    const record = getRecord()

    return (
      <WrapIf condition={showBorder} wrapper={Card}>
        {renderArray({
          childrenProps: value.map((item, index) => ({
            item,
            index,
            key: index,
          })),
          renderChild: (childProps) => (
            <div key={childProps.index}>
              {childrenType.display(childProps.item, {
                fieldKey: childProps.index,
                ...childrenParams,
              })}
            </div>
          ),
          showAddRemoveButtons: false,
          getItemLabel: !getItemLabel
            ? undefined
            : (index) => getItemLabel(index, record),
          ...otherTypeParams,
        })}
      </WrapIf>
    )
  }

  static formatter = (
    value,
    {
      getRecord,
      user,
      formatArray,
      childrenType,
      childrenParams,
      formatSeparator,
    }
  ) =>
    formatArray(
      value.map((item) =>
        childrenType.format(item, { getRecord, user, ...childrenParams })
      ),
      formatSeparator
    )

  static filterRenderer = (
    filters,
    onChangeFilters,
    onConfirm,
    focusRef,
    { childrenType, childrenParams }
  ) =>
    childrenType.renderFilter(
      filters,
      onChangeFilters,
      onConfirm,
      focusRef,
      childrenParams
    )

  static filterComparator = (
    values,
    filter,
    { childrenType, childrenParams }
  ) => {
    const type = childrenType.withParams(childrenParams)
    return values.some((value) => type.compareFilter(value, filter))
  }

  static validators = (value, { validationTrigger, ...typeParams }) => {
    const {
      minLength,
      maxLength,
      fixedLength,
      allowDuplicates,
      childrenType,
      childrenParams,
    } = typeParams
    const type = childrenType.withParams(childrenParams)
    return [
      assert(
        value.length >= minLength,
        `O campo %% deve ter no mínimo ${minLength} ${pluralize(
          minLength,
          'elemento'
        )}`
      ),
      assert(
        value.length <= maxLength,
        `O campo %% deve ter no máximo ${maxLength} ${pluralize(
          minLength,
          'elemento'
        )}`
      ),
      assert(
        fixedLength === undefined || value.length === fixedLength,
        `O campo %% deve ter exatamente ${fixedLength} ${pluralize(
          minLength,
          'elemento'
        )}`
      ),
      assert(
        allowDuplicates ||
          !value ||
          value.length <= 1 ||
          !value.some((currentItem, currentIndex) =>
            value.some(
              (compareItem, compareIndex) =>
                compareIndex !== currentIndex &&
                type.compare(currentItem, compareItem) === 0
            )
          ),
        `O campo %% não pode ter elementos repetidos`
      ),
    ]
  }

  static comparator = (value1, value2, { childrenType, childrenParams }) =>
    value1 === value2 ||
    (value1?.length === value2?.length &&
    value1.every(
      (_, i) => childrenType.compare(value1[i], value2[i], childrenParams) === 0
    )
      ? 0
      : undefined)
}
