import { API } from 'aws-amplify'
import { QueryClient } from 'react-query'
import { createLocalStoragePersistor } from 'react-query/createLocalStoragePersistor-experimental'
import { persistQueryClient } from 'react-query/persistQueryClient-experimental'

const OPTIONS_DEV = {
  staleMinutes: 24 * 60,
  cacheMinutes: 24 * 60,
  useLocalStorage: false,
}

const OPTIONS_PROD = {
  staleMinutes: 5,
  cacheMinutes: 5,
  useLocalStorage: false,
}

function getQueryClient() {
  const options =
    process.env.REACT_APP_Stage === 'prod' ? OPTIONS_PROD : OPTIONS_DEV
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        queryFn: ({ queryKey }) => API.get('api', queryKey.join('/')),
        staleTime: 1000 * 60 * options.staleMinutes,
        cacheTime: 1000 * 60 * options.cacheMinutes,
      },
    },
  })

  if (options.useLocalStorage) {
    persistQueryClient({
      queryClient,
      persistor: createLocalStoragePersistor(),
    })
  }

  return queryClient
}

export const queryClient = getQueryClient()
