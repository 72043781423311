import React, { useEffect, useMemo, useState } from 'react'
import {
  downloadAsDocx,
  downloadAsDocxZip,
  downloadAsXlsx,
  mapFields,
  sortArray,
} from '../../libs/utils'

import { API } from 'aws-amplify'
import ExamDashboardHeader from '../../components/exams/dashboard/ExamDashboardHeader'
import ExamDashboardRow from '../../components/exams/dashboard/ExamDashboardRow'
import PageWrapper from '../../components/ui/PageWrapper'
import { useDependency } from '../../libs/hooksLib'

// TODO:
// - refactor: queries, styled-components
// - view exam questions
// - how to detect idle students?
// - show status buttons: begin, end, deliver results...

const POLL_INTERVAL = 1000 * 5 // 5s

export default function ExamDashboard(props) {
  const [examData, isFetchingExamData, pollExamData] = useDependency(
    `live-exam?id_exam=${props.match.params.id}`
  )
  const [questions, isFetchingQuestions] = useDependency(
    `exam-question-set?id_exam=${props.match.params.id}`
  )
  const [students, isFetchingStudents] = useDependency('aluno')
  const [exam, isFetchingExam] = useDependency(`exam/${props.match.params.id}`)
  const [theme, isFetchingTheme] = useDependency(
    'tema',
    useMemo(() => ({ id: exam.essay_theme }), [exam.essay_theme])
  )
  const [examStudents, setExamStudents] = useState([])
  const [startedExamsCount, setStartedExamsCount] = useState(0)
  const [deliveredExamsCount, setDeliveredExamsCount] = useState(0)
  const [answersMap, setAnswersMap] = useState({})
  const [questionMap, setQuestionMap] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [pollTimer, setPollTimer] = useState(0)
  const [isDownloading, setIsDownloading] = useState(false)

  useEffect(() => {
    if (!isFetchingExam && !isFetchingQuestions && !isFetchingStudents) {
      setExamStudents(
        sortArray(
          students
            .filter((s) => exam.courses.includes(s.id_course))
            .map((s) => ({
              name: `${s.nome} ${s.sobrenome}`,
              cpf: s.cpf,
              publicSchool: s.escola_tipo === 'Pública',
              user_id: s.user_id,
            })),
          (s) => s.name
        )
      )
      setQuestionMap(
        mapFields(
          questions
            .map((qs) =>
              qs.questions.map((q, i) => ({
                index: parseInt(qs.index) + i,
                ...q,
              }))
            )
            .flat(),
          'index'
        )
      )
    }
  }, [
    isFetchingExam,
    isFetchingQuestions,
    isFetchingStudents,
    exam.courses,
    questions,
    students,
  ])

  useEffect(() => {
    if (questionMap && examData && !isFetchingExamData) {
      const maxCount = Object.keys(questionMap).length
      const answersMap = mapFields(
        examData.map((ed) => {
          const answers = ed.answers || {}
          const questionAnswers = Object.keys(answers).filter((i) =>
            /^\d+$/.test(i)
          )
          const hitCount = questionAnswers.filter(
            (i) =>
              answers[i] === questionMap[i].answer ||
              questionMap[i].cancelled === true ||
              questionMap[i].cancelled === 'sim' // TODO: DB migration
          ).length
          const missCount = questionAnswers.length - hitCount
          return {
            ...ed,
            essay: answers.essay,
            count: questionAnswers.length,
            hitCount,
            missCount,
            maxCount,
          }
        }),
        'user_id'
      )
      const startedExams = examStudents.filter((s) => answersMap[s.user_id])
      const deliveredExams = startedExams.filter(
        (s) => answersMap[s.user_id].status === 'delivered'
      )
      setAnswersMap(answersMap)
      setStartedExamsCount(startedExams.length)
      setDeliveredExamsCount(deliveredExams.length)
      setIsLoading(false)
    }
  }, [questionMap, examData, isFetchingExamData, examStudents])

  useEffect(() => {
    return () => clearInterval(pollTimer)
  }, [pollTimer])

  function togglePolling(enabled) {
    if (enabled) {
      setPollTimer(setInterval(pollExamData, POLL_INTERVAL))
    } else {
      clearInterval(pollTimer)
      setPollTimer(0)
    }
  }

  async function setExamStatus(id, status) {
    const examData = await API.get('api', `/exam-answer/${id}`)
    await API.put('api', `/exam-answer/${id}`, {
      body: { ...examData, status },
    })
    pollExamData()
  }

  function getEssayTemplateData(studentName, essayText) {
    return {
      filename: `${studentName} - Redação - ${exam.name}.docx`.replace(
        /\//g,
        '_'
      ),
      data: {
        text: essayText,
        name: studentName,
        theme: theme[0].tema,
      },
    }
  }

  function getEssayTemplateDataList() {
    return examStudents
      .filter((s) => s && answersMap[s.user_id] && answersMap[s.user_id].essay)
      .map((s) => getEssayTemplateData(s.name, answersMap[s.user_id].essay))
  }

  function downloadResults() {
    const results = sortArray(
      examStudents.map((s) => {
        const ans = answersMap[s.user_id]
        return {
          name: s.name.toUpperCase(),
          number: s.cpf,
          publicSchool: s.publicSchool ? 'sim' : 'não',
          score: ans ? ans.hitCount : 0,
        }
      }),
      (r) => r.score,
      'desc'
    )

    downloadAsXlsx('exam-results.xlsx', `Resultados - ${exam.name}.xlsx`, {
      examName: exam.name.toUpperCase(),
      results,
    })
  }

  function downloadEssay(studentName, essayText) {
    const templateData = getEssayTemplateData(studentName, essayText)
    downloadAsDocx('essay.docx', templateData.filename, templateData.data)
  }

  async function downloadAllEssaysDocx() {
    setIsDownloading(true)
    await downloadAsDocx('essay-list.docx', `Redações - ${exam.name}.docx`, {
      essays: getEssayTemplateDataList().map((i) => i.data),
    })
    setIsDownloading(false)
  }

  async function downloadAllEssaysZip() {
    setIsDownloading(true)
    await downloadAsDocxZip(
      'essay.docx',
      `Redações - ${exam.name}.zip`,
      getEssayTemplateDataList()
    )
    setIsDownloading(false)
  }

  return (
    <PageWrapper showTitle={false} isLoading={isLoading || isFetchingTheme}>
      <ExamDashboardHeader
        examName={exam.name}
        examStudents={examStudents}
        questionMap={questionMap}
        answersMap={answersMap}
        startedExamsCount={startedExamsCount}
        deliveredExamsCount={deliveredExamsCount}
        isDownloading={isDownloading}
        isPolling={!!pollTimer}
        onDownloadResults={downloadResults}
        onDownloadEssaysDocx={downloadAllEssaysDocx}
        onDownloadEssaysZip={downloadAllEssaysZip}
        onTogglePolling={togglePolling}
      />
      {examStudents.map(
        (student) =>
          student.user_id && (
            <ExamDashboardRow
              key={student.user_id}
              student={student}
              answer={answersMap[student.user_id]}
              onDownloadEssay={downloadEssay}
              onSetExamStatus={setExamStatus}
            />
          )
      )}
    </PageWrapper>
  )
}
