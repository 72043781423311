import { Button, Form, Input } from 'antd'
import React, { useState } from 'react'

import { Auth } from 'aws-amplify'
import { querystring } from '../libs/utils'
import styled from 'styled-components'

const StyledContainer = styled.div`
  & span.login-error {
    color: #ff4d4f;
    text-align: center;
  }

  & .ant-input-affix-wrapper {
    line-height: 2;
  }

  & hr {
    margin-bottom: 20px;
    border: 0;
    border-top: 1px solid #eee;
  }

  @media all and (min-width: 480px) {
    & {
      padding: 60px 0;
    }

    & form {
      margin: 0 auto;
      max-width: 320px;
    }
  }
`

const ERR_INVALID_PASSWORD = `
  Email ou senha incorreta, tente novamente.
`

const ERR_TOO_MANY_ATTEMPTS = `
  Conta bloqueada devido ao excesso de tentativas de login com senha inválida.
  Por favor, entre em contato com a administração.
`

const ERR_PASSWORD_UNSAFE = `
  Por favor, escolha uma senha segura com pelo menos 8 caracteres.
`

const ERR_USER_ALREADY_EXISTS = `
  Este email já está cadastrado. Se você já possui uma conta, clique em "Já tenho uma conta" para fazer o login.
`

const ERR_INVALID_CODE = `
  Código inválido, tente novamente.
`

const ERR_LIMIT_EXCEEDED = `
  Limite de tentativas excedido. Por favor, tente novamente mais tarde ou entre em contato com a administração.
`

const ERR_EXPIRED_CODE = `
  Código de confirmação expirado. Por favor, verifique o novo código enviado no seu email.
`

export default function AuthContainer(props) {
  const [form] = Form.useForm()
  const [isLoading, setIsLoading] = useState(false)
  const [isUserNotConfirmed, setConfirmationStep] = useState(false)
  const [userDetails, setUserDetails] = useState({})
  const [error, setError] = useState(null)

  const confirmationCode = querystring('code')

  function login() {
    form.resetFields()
    setError(null)
    props.history.push('/entrar')
  }

  function signUp() {
    form.resetFields()
    setError(null)
    props.history.push('/cadastro')
  }

  async function handleLoginSubmit(fields) {
    setIsLoading(true)
    let stillLoading = false

    try {
      await Auth.signIn(fields.email, fields.password)
      props.userHasAuthenticated(true)
    } catch (e) {
      if (
        e.code === 'NotAuthorizedException' ||
        e.code === 'UserNotFoundException'
      ) {
        if (e.message === 'Password attempts exceeded') {
          setError(ERR_TOO_MANY_ATTEMPTS)
        } else {
          setError(ERR_INVALID_PASSWORD)
        }
      } else if (e.code === 'UserNotConfirmedException') {
        setError(null)
        setUserDetails(fields)
        setConfirmationStep(true)
        if (confirmationCode) {
          form.setFieldsValue({ confirmationCode })
          handleConfirmationSubmit({ ...fields, confirmationCode })
          stillLoading = true
        }
      } else {
        console.error(e.message, e)
      }

      if (!stillLoading) {
        setIsLoading(false)
      }
    }
  }

  async function handleSignupSubmit(fields) {
    setIsLoading(true)

    try {
      await Auth.signUp({
        username: fields.email,
        password: fields.password,
        attributes: {
          'custom:firstname': fields.firstname,
          'custom:lastname': fields.lastname,
          'custom:phone': fields.phone,
          'custom:city': fields.city,
          'custom:addr': fields.address,
        },
      })
      setError(null)
      setUserDetails(fields)
      setConfirmationStep(true)
    } catch (e) {
      if (
        e.code === 'InvalidParameterException' ||
        e.code === 'InvalidPasswordException'
      ) {
        setError(ERR_PASSWORD_UNSAFE)
      } else if (e.code === 'UsernameExistsException') {
        setError(ERR_USER_ALREADY_EXISTS)
      } else {
        console.error(e.message, e)
      }
    }

    setIsLoading(false)
  }

  async function handleConfirmationSubmit(fields) {
    fields = { ...userDetails, ...fields }
    setIsLoading(true)

    try {
      await Auth.confirmSignUp(fields.email, fields.confirmationCode)
      await Auth.signIn(fields.email, fields.password)
      props.userHasAuthenticated(true)
    } catch (e) {
      if (e.code === 'CodeMismatchException') {
        setError(ERR_INVALID_CODE)
      } else if (e.code === 'LimitExceededException') {
        setError(ERR_LIMIT_EXCEEDED)
      } else if (e.code === 'ExpiredCodeException') {
        await Auth.resendSignUp(fields.email)
        setError(ERR_EXPIRED_CODE)
      } else if (e.code === 'NotAuthorizedException') {
        setUserDetails({})
        setConfirmationStep(false)
        handleLoginSubmit(fields)
      } else {
        console.error(e.message, e)
      }
    }

    setIsLoading(false)
  }

  function renderLoginForm() {
    return (
      <Form key='login' form={form} size='large' onFinish={handleLoginSubmit}>
        <Form.Item
          name='email'
          hasFeedback
          rules={[
            { required: true, message: 'Por favor, preencha seu email.' },
            { type: 'email', message: 'Email inválido!' },
          ]}
        >
          <Input placeholder='Email' autoFocus />
        </Form.Item>
        <Form.Item
          name='password'
          hasFeedback
          rules={[
            { required: true, message: 'Por favor, preencha sua senha.' },
          ]}
        >
          <Input.Password placeholder='Senha' />
        </Form.Item>
        <Form.Item help='Esqueceu sua senha? Entre em contato com a administração para recuperá-la.'>
          <Button type='primary' htmlType='submit' block loading={isLoading}>
            Entrar
          </Button>
          <span className='login-error'>{error}</span>
        </Form.Item>
        <Form.Item>
          <hr />
          <Button block onClick={signUp}>
            Criar uma conta
          </Button>
        </Form.Item>
      </Form>
    )
  }

  function renderSignupForm() {
    return (
      <Form key='signup' form={form} size='large' onFinish={handleSignupSubmit}>
        <Form.Item
          name='firstname'
          hasFeedback
          rules={[{ required: true, message: 'Por favor, preencha seu nome.' }]}
        >
          <Input placeholder='Nome' autoFocus />
        </Form.Item>
        <Form.Item
          name='lastname'
          hasFeedback
          rules={[
            { required: true, message: 'Por favor, preencha seu sobrenome.' },
          ]}
        >
          <Input placeholder='Sobrenome' autoFocus />
        </Form.Item>
        <Form.Item
          name='phone'
          hasFeedback
          rules={[
            { required: true, message: 'Por favor, preencha seu telefone.' },
          ]}
        >
          <Input placeholder='Telefone' autoFocus />
        </Form.Item>
        <Form.Item
          name='city'
          hasFeedback
          rules={[
            { required: true, message: 'Por favor, preencha sua cidade.' },
          ]}
        >
          <Input placeholder='Cidade' autoFocus />
        </Form.Item>
        <Form.Item
          name='address'
          hasFeedback
          rules={[
            { required: true, message: 'Por favor, preencha seu endereço.' },
          ]}
        >
          <Input placeholder='Endereço' autoFocus />
        </Form.Item>
        <Form.Item
          name='email'
          hasFeedback
          rules={[
            { required: true, message: 'Por favor, preencha seu email.' },
            { type: 'email', message: 'Email inválido!' },
          ]}
        >
          <Input placeholder='Email' autoFocus />
        </Form.Item>
        <Form.Item
          name='password'
          hasFeedback
          rules={[
            { required: true, message: 'Por favor, preencha sua senha.' },
            { min: 8, message: ERR_PASSWORD_UNSAFE },
          ]}
        >
          <Input.Password placeholder='Senha' autoComplete='new-password' />
        </Form.Item>
        <Form.Item
          name='confirmPassword'
          hasFeedback
          dependencies={['password']}
          rules={[
            { required: true, message: 'Por favor, preencha sua senha.' },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve()
                } else {
                  return Promise.reject('As senhas não conferem.')
                }
              },
            }),
          ]}
        >
          <Input.Password
            placeholder='Confirme a senha'
            autoComplete='new-password'
          />
        </Form.Item>
        <Form.Item>
          <Button type='primary' htmlType='submit' block loading={isLoading}>
            Criar conta
          </Button>
          <span className='login-error'>{error}</span>
        </Form.Item>
        <Form.Item>
          <hr />
          <Button block onClick={login}>
            Já tenho uma conta
          </Button>
        </Form.Item>
      </Form>
    )
  }

  function renderConfirmationForm() {
    return (
      <>
        <p>
          Verifique o email de confirmação enviado para{' '}
          <b>{userDetails.email}</b> e clique no link para ativar sua conta.
        </p>
        <p>
          Caso não receba o email de confirmação, o link não funcione ou tenha
          digitado o email errado, entre em contato com a administração.
          Lembre-se de verificar a caixa de spam!
        </p>
      </>
    )
  }

  return (
    <StyledContainer>
      {isUserNotConfirmed
        ? renderConfirmationForm()
        : props.location.pathname === '/cadastro'
        ? renderSignupForm()
        : renderLoginForm()}
    </StyledContainer>
  )
}
