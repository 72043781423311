import { ArrayListType } from './ArrayListType'
import { ArrayTabsType } from './ArrayTabsType'
import { BaseArrayType } from './BaseArrayType'

export class ArrayType extends BaseArrayType {
  static getDefaultTypeParams() {
    return BaseArrayType.extendTypeParams({
      onSelect: undefined,
      selectedIndex: undefined,
      displayMode: 'list',
      displayAs: this.fromParams(
        ({ displayMode, isFormControlled }) =>
          ({
            list: isFormControlled ? 'text' : 'custom',
            tabs: isFormControlled ? 'input' : 'invalid',
          }[displayMode]),
        ['displayMode', 'isFormControlled']
      ),
      renderArray: ({ displayMode, ...props }) => {
        if (displayMode === 'list') {
          return ArrayListType.renderArray(props)
        } else if (displayMode === 'tabs') {
          return ArrayTabsType.renderArray(props)
        }
      },
      renderType: this.fromParams(
        ({ childrenType }) =>
          'allowMultiple' in childrenType.getDefaultTypeParams()
            ? childrenType
            : false,
        ['childrenType']
      ),
    })
  }
}
