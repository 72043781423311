import { mapFields, mapObject } from '../../libs/utils'
import { useCRUD, useResources } from './crud.hooks'

import DataCRUD from './DataCRUD'
import PageWrapper from '../ui/PageWrapper'
import React from 'react'
import { ViewSettingsProvider } from './viewSettings'
import { useLocation } from 'react-router'
import { useRouteConfig } from '../routing/routingConfig'

export default function CRUDPage({
  user,
  history,
  params,
  resource,
  listResource,
  recordResource,
  header,
  footer,
  dependencies = {},
  listParser,
  schema,
  listColumns,
  formFields,
  listOptions,
  formOptions,
  crudOptions,
}) {
  const routeConfig = useRouteConfig()
  const { search } = useLocation()
  const { list, record, isLoading, saveRecord, deleteRecord } = useCRUD(
    listResource ?? resource,
    recordResource ?? resource,
    params.id
  )

  const dependencyKeys = Object.keys(dependencies)
  const dependencyQueries = Object.fromEntries(
    useResources(
      dependencyKeys.map((key) =>
        typeof dependencies[key] === 'string'
          ? dependencies[key]
          : dependencies[key].resource
      )
    ).map((query, index) => [dependencyKeys[index], query])
  )

  const isPageLoading =
    isLoading ||
    Object.values(dependencyQueries).some((query) => query.isLoading)

  const injectDependencies = (prop) =>
    typeof prop === 'function'
      ? prop({ list, record: record ?? {}, ...dependencyQueries })
      : prop

  function onViewList() {
    if (
      !crudOptions?.hook?.viewList ||
      crudOptions?.hook?.viewList() === true
    ) {
      history.push(`${routeConfig.path}${search}`)
    }
  }

  function onViewRecord(id) {
    if (
      !crudOptions?.hook?.viewRecord ||
      crudOptions?.hook?.viewRecord(id) === true
    ) {
      history.push(`${routeConfig.path}/${id}${search}`)
    }
  }

  const defaultListParser = ({ list, ...queries }) => {
    const depsToPopulate = dependencyKeys.filter(
      (key) => typeof dependencies[key] !== 'string'
    )
    const maps = depsToPopulate.reduce(
      (maps, key) => ({ ...maps, [key]: mapFields(queries[key].data, 'id') }),
      {}
    )
    return depsToPopulate.length === 0
      ? list
      : list.map((record) =>
          depsToPopulate.reduce(
            (populatedRecord, key) => ({
              ...populatedRecord,
              ...mapObject(
                maps[key][record[dependencies[key].key]],
                undefined,
                (k) => `${dependencies[key].prefix}__${k}`
              ),
            }),
            record
          )
        )
  }

  const renderPage = () => (
    <>
      {injectDependencies(header)}
      <ViewSettingsProvider>
        <DataCRUD
          user={user}
          schema={injectDependencies(schema)}
          list={injectDependencies(listParser ?? defaultListParser)}
          listColumns={injectDependencies(listColumns)}
          recordId={params.id}
          record={record}
          formFields={injectDependencies(formFields)}
          listOptions={listOptions}
          formOptions={formOptions}
          crudOptions={crudOptions}
          onViewList={onViewList}
          onViewRecord={onViewRecord}
          onSaveRecord={saveRecord}
          onDeleteRecord={deleteRecord}
        />
      </ViewSettingsProvider>
      {injectDependencies(footer)}
    </>
  )

  return (
    <PageWrapper isLoading={isPageLoading}>
      {!isPageLoading && renderPage()}
    </PageWrapper>
  )
}

CRUDPage.isCreating = ({ record }) => !record.id
CRUDPage.isUpdating = ({ record }) => !!record.id
CRUDPage.isEditing = ({ readOnly }) => !readOnly
CRUDPage.isViewing = ({ readOnly }) => !!readOnly
