import CRUDPage from '../../components/data/CRUDPage'
import React from 'react'
import { TextType } from '../../components/data/fieldtypes'

export default function Courses(props) {
  return (
    <CRUDPage
      user={props.user}
      history={props.history}
      params={props.match.params}
      resource='/course'
      schema={{
        name: new TextType({ unique: true }),
      }}
      listColumns={[
        {
          header: 'Curso',
          name: 'name',
        },
      ]}
      formFields={[
        {
          name: 'name',
          label: 'Curso',
        },
      ]}
    />
  )
}
