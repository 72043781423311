import { AsyncRender } from '../../../utils'
import { Collapse } from 'antd'
import { LinkType } from './LinkType'
import React from 'react'
import YouTube from 'react-youtube'
import { YoutubeIcon } from '../../../ui/Icons'
import { assert } from '../../../../libs/utils'

async function fetchYoutubeMetadata(videoId) {
  const resp = await fetch(
    `https://www.youtube.com/oembed?url=http://www.youtube.com/watch?v=${videoId}&format=json`
  )
  if (resp.status === 200) {
    return resp.json()
  } else {
    return null
  }
}

export class YoutubeType extends LinkType {
  static getDefaultTypeParams() {
    return LinkType.extendTypeParams({
      validationTriggers: ['save'],
      linkPrefix: 'https://www.youtube.com/watch?v=',
      placeholder: 'ID do Youtube',
      icon: YoutubeIcon,
      expanded: false,
      renderPreview: ({ value, expanded }) => (
        <AsyncRender
          input={value}
          process={(value) => (value ? fetchYoutubeMetadata(value) : null)}
          render={(videoData, isLoading) => (
            <Collapse key={value} defaultActiveKey={expanded ? ['video'] : []}>
              <Collapse.Panel
                key='video'
                header={
                  !value
                    ? 'Nenhum vídeo selecionado'
                    : isLoading
                    ? 'Carregando...'
                    : !videoData
                    ? 'Vídeo não encontrado'
                    : videoData.title
                }
                collapsible={videoData && !isLoading ? 'header' : 'disabled'}
                forceRender={videoData && !isLoading}
              >
                <YouTube
                  videoId={value}
                  opts={{
                    width: '100%',
                    playerVars: {
                      controls: 1,
                      modestbranding: 1,
                      rel: 0,
                      showinfo: 0,
                    },
                  }}
                />
              </Collapse.Panel>
            </Collapse>
          )}
        />
      ),
    })
  }

  static validators = async (value) => [
    assert(!!(await fetchYoutubeMetadata(value)), `Vídeo não encontrado`),
  ]
}
