import React, { useEffect, useState } from 'react'
import { mapFields, sortArray } from '../../libs/utils'

import { API } from 'aws-amplify'
import { Alert } from 'antd'
import ExamContainer from '../../components/exams/live/ExamContainer'
import ExamSchedule from '../../components/exams/live/ExamSchedule'
import IconButton from '../../components/ui/IconButton'
import PageWrapper from '../../components/ui/PageWrapper'
import { appRoutes } from '../../components/routing/routingConfig'
import moment from 'moment'
import { useMultiState } from '../../libs/hooksLib'

// TODO:
// - Fix CSS (big & small screens)
// - Use queries
// - Test and fix production issues (missing questions? request limit?)

const DEV_LONG_POLLING = true
const DEV = process.env.REACT_APP_Stage === 'dev'
const POLL_INTERVAL_SHORT = 1000 * 15 * (DEV && DEV_LONG_POLLING ? 100 : 1) // 15s
const POLL_INTERVAL_LONG = 1000 * 3600 * 8 // 8h
const AUTOSAVE_ESSAY_INTERVAL = 1000 * 60 // 60s

function startExam(exam) {
  return API.post('api', '/live-exam', { body: { exam } })
}

function fetchExam(id, statusCheck) {
  return API.get('api', `/live-exam/${id}${statusCheck ? '?status=true' : ''}`)
}

function updateExam(id, answers) {
  return API.put('api', `/live-exam/${id}`, { body: answers })
}

export default function LiveExam(props) {
  const [isLoading, setIsLoading] = useState(true)
  const [isSaving, setIsSaving] = useState(null)
  const [lastSaved, setLastSaved] = useState(0)
  const [essayTimer, setEssayTimer] = useState(0)
  const [error, setError] = useState('')
  const [examId, setExamId] = useState(null)
  const [isToday, setIsToday] = useState(false)
  const [isCourseExam, setIsCourseExam] = useState(null)
  const [examDate, setExamDate] = useState(null)
  const [examTimeRange, setExamTimeRange] = useState(null)
  const [examStatus, setExamStatus] = useState(null)
  const [isExamLoaded, setIsExamLoaded] = useState(false)
  const [selectedSubject, setSelectedSubject] = useState(null)
  const [essayTheme, setEssayTheme] = useState({})
  const [subjectQuestions, setSubjectQuestions] = useState([])
  const [answers, setAnswer, setAnswers] = useMultiState({})
  const [isEndModalVisible, toggleEndModal] = useState(false)

  // TODO
  console.log(isCourseExam)

  useEffect(() => {
    async function onLoad() {
      try {
        const exam = await startExam(props.match.params.id)
        setExamId(exam.id)
        setIsCourseExam(exam.course_exam)
        setExamDate(exam.date)
        setIsToday(moment().isSame(exam.date, 'day'))
        setExamTimeRange(exam.time_range)
        setExamStatus(exam.status)
        if (exam.status === 'scheduled') {
          setIsLoading(false)
        }
      } catch (err) {
        setIsLoading(false)
        if (err.response) {
          if (err.response.status === 403) {
            setError(
              'Simulado não encontrado. Tente recarregar a página ou entre em contato com a administração.'
            )
          } else {
            setError(
              'Houve um erro inesperado. Tente recarregar a página ou entre em contato com a administração.'
            )
          }
        } else {
          throw err
        }
      }
    }
    onLoad()
  }, [props.match.params.id])

  useEffect(() => {
    function loadExam(exam) {
      const subjectMap = mapFields(exam.subjects, 'id')
      const examSubjects = []
      const questions = {}

      sortArray(exam.questions, (q) => parseInt(q.index)).forEach((qs) => {
        const s = qs.id_subject
        const firstIndex = parseInt(qs.index)
        if (!questions[s]) {
          questions[s] = []
          examSubjects.push(s)
        }
        if (qs.introduction) {
          questions[s].push({
            intro: qs.introduction,
            index: firstIndex,
          })
        }
        qs.questions.forEach((q, i) => {
          questions[s].push({
            index: firstIndex + i,
            ...q,
          })
        })
      })

      exam.subjects.forEach((s) => {
        if (!questions[s.id]) {
          questions[s.id] = []
          examSubjects.push(s.id)
        }
      })

      setEssayTheme(exam.essay_theme || null)
      setAnswers(exam.answers || {})
      setSubjectQuestions(
        examSubjects
          .map((s) => ({
            id: s,
            name: subjectMap[s].name,
            variant: subjectMap[s].variant,
            questions: questions[s],
          }))
          .filter((s) =>
            s.variant === 'essay' ? !!exam.essay_theme : s.questions.length > 0
          )
      )
      setSelectedSubject(examSubjects.length > 0 ? examSubjects[0] : null)
      setIsExamLoaded(true)
    }

    async function getExam(statusCheck) {
      try {
        const exam = await fetchExam(examId, statusCheck)
        setExamStatus(exam.status)
        if (
          statusCheck &&
          exam.status === 'processing' &&
          examStatus === 'in_progress'
        ) {
          toggleEndModal(true)
        } else if (!statusCheck) {
          if (exam.status !== 'scheduled') {
            if (examStatus === 'scheduled') {
              setIsLoading(true)
            }
            loadExam(exam)
            setTimeout(() => setIsLoading(false), 100)
          } else {
            setExamDate(exam.date)
            setIsToday(moment().isSame(exam.date, 'day'))
            setExamTimeRange(exam.time_range)
          }
        }
      } catch (err) {
        console.error(err)
        if (err.response) {
          // TODO: handle intermittent errors
          // setError("Simulado não encontrado.");
        } else {
          throw err
        }
      }
    }

    let timer
    if (examId && !error) {
      if (!isExamLoaded) {
        if (examStatus === 'scheduled') {
          timer = setInterval(
            () => getExam(false),
            isToday ? POLL_INTERVAL_SHORT : POLL_INTERVAL_LONG
          )
        } else {
          getExam(false)
        }
      } else if (examStatus === 'in_progress') {
        timer = setInterval(() => getExam(true), POLL_INTERVAL_SHORT)
      }
    }

    return () => clearInterval(timer)
  }, [examId, examStatus, isExamLoaded, error, setAnswers, isToday])

  function goToSubject(id) {
    setSelectedSubject(id)
    window.scrollTo(0, 0)
  }

  async function submitAnswer(index, answer) {
    setAnswer(index, answer)
    setIsSaving(true)
    await updateExam(examId, { [index]: answer })
    setLastSaved(moment())
    setIsSaving(false)
  }

  async function deliverExam() {
    setIsLoading(true)
    await updateExam(examId, { ...answers, delivered: true })
    setExamStatus('delivered')
    setIsLoading(false)
    toggleEndModal(true)
  }

  return (
    <PageWrapper showTitle={false} isLoading={isLoading}>
      {error ? (
        <Alert type='warning' className='center' message={error} />
      ) : examStatus === 'scheduled' ? (
        <>
          <ExamSchedule
            isToday={isToday}
            examDate={examDate}
            startTime={examTimeRange?.start}
          />
          <IconButton.Back
            className='pull-right'
            onClick={() =>
              props.history.push(appRoutes.exams.studentExams.path)
            }
          />
        </>
      ) : (
        <ExamContainer
          status={examStatus}
          endTime={examTimeRange?.end}
          isSaving={isSaving}
          lastSaved={lastSaved}
          onDeliverExam={deliverExam}
          selectedSubject={selectedSubject}
          onSelectSubject={goToSubject}
          subjectQuestions={subjectQuestions}
          essayTheme={essayTheme}
          answers={answers}
          onSubmitAnswer={submitAnswer}
          showEndModal={isEndModalVisible}
          onCloseEndModal={() => toggleEndModal(false)}
          onChangeEssay={(text, saveNow) => {
            if (essayTimer) {
              clearTimeout(essayTimer)
              setEssayTimer(0)
            }
            const now = moment()
            if (saveNow || now - lastSaved >= AUTOSAVE_ESSAY_INTERVAL) {
              submitAnswer('essay', text)
            } else {
              setIsSaving(undefined)
              setEssayTimer(
                setTimeout(() => {
                  submitAnswer('essay', text)
                }, AUTOSAVE_ESSAY_INTERVAL - (now - lastSaved))
              )
            }
          }}
        />
      )}
    </PageWrapper>
  )
}
