import { AutoComplete, Input } from 'antd'
import React, { useState } from 'react'
import { assert, stringMatch } from '../../../../libs/utils'

import { BaseType } from '../BaseType'
import { WrapIf } from '../../../utils'
import styled from 'styled-components'

const StyledAutoComplete = styled(AutoComplete)`
  width: 100%;
`

export class TextType extends BaseType {
  static getDefaultTypeParams() {
    return BaseType.extendTypeParams({
      valueDefault: '',
      formatEmpty: '---',
      format: (value, record) => value,
      formatUpperCase: false,
      filters: 'custom',
      minLength: 0,
      maxLength: Infinity,
      placeholder: '',
      addonBefore: undefined,
      addonAfter: undefined,
      getSuggestions: undefined,
    })
  }

  static isEmpty = (value) => !value || value.length === 0

  static renderer = ({
    value,
    onChange,
    onBlur,
    readOnly,
    disabled,
    optional,
    placeholder,
    addonBefore,
    addonAfter,
    getSuggestions,
  }) => {
    const [options, setOptions] = useState([])
    const inputProps = { value, onBlur, allowClear: optional }

    return (
      <WrapIf
        condition={!!getSuggestions}
        wrapper={StyledAutoComplete}
        wrapperProps={{
          ...inputProps,
          onChange,
          options,
          onSearch: async (text) => setOptions(await getSuggestions(text)),
          disabled: disabled || readOnly,
        }}
      >
        <Input
          {...(!getSuggestions
            ? { ...inputProps, onChange: (e) => onChange(e.target.value) }
            : {})}
          readOnly={readOnly}
          disabled={disabled}
          placeholder={placeholder}
          addonBefore={addonBefore}
          addonAfter={addonAfter}
        />
      </WrapIf>
    )
  }

  static formatter = (value, { getRecord, format, formatUpperCase }) =>
    format(
      formatUpperCase ? String(value).toUpperCase() : String(value),
      getRecord()
    )

  static filterRenderer = (filters, onChangeFilters, onConfirm, focusRef) => (
    <Input.Search
      ref={focusRef}
      value={filters[0]}
      onChange={(e) => onChangeFilters(e.target.value ? [e.target.value] : [])}
      onSearch={(value) => {
        onChangeFilters(value ? [value] : [])
        onConfirm()
      }}
      enterButton
      allowClear
      autoFocus
    />
  )

  static filterComparator = (value, filter) => stringMatch(value, filter)

  static validators = (value, { minLength, maxLength }) => [
    assert(
      value.length >= minLength,
      `O campo %% deve ter no mínimo ${minLength} caracteres`
    ),
    assert(
      value.length <= maxLength,
      `O campo %% deve ter no máximo ${maxLength} caracteres`
    ),
  ]
}
