import {
  ArrayType,
  DateRangeType,
  DateTimeRangeType,
  DateTimeType,
  DateType,
  ObjectType,
  TextType,
  TimeRangeType,
  TimeType,
} from '../../components/data/fieldtypes'

import CRUDPage from '../../components/data/CRUDPage'
import React from 'react'
import { message } from 'antd'

export default function Test(props) {
  return (
    <CRUDPage
      user={props.user}
      history={props.history}
      params={props.match.params}
      resource='/teacher'
      schema={{
        dtt: new DateTimeType(),
        dt: new DateType(),
        tt: new TimeType(),
        dtrt: new DateTimeRangeType(),
        drt: new DateRangeType(),
        trt: new TimeRangeType(),
        single: new TextType(),
        arr: new ArrayType({ childrenType: new TextType() }),
        obj: new ObjectType({
          schema: { obj__f1: new TextType(), obj__f2: new TextType() },
        }),
        arr_obj: new ArrayType({
          childrenType: new ObjectType({
            schema: {
              arr_obj__f1: new TextType(),
              arr_obj__f2: new TextType(),
            },
          }),
        }),
        obj_arr: new ObjectType({
          schema: {
            obj_arr__f1: new TextType(),
            obj_arr__arr: new ArrayType({ childrenType: new TextType() }),
          },
        }),
        arr_obj_arr: new ArrayType({
          childrenType: new ObjectType({
            schema: {
              arr_obj_arr__f1: new TextType(),
              arr_obj_arr__arr: new ArrayType({ childrenType: new TextType() }),
            },
          }),
        }),
        obj_arr_obj: new ObjectType({
          schema: {
            obj_arr_obj__f1: new TextType(),
            obj_arr_obj__arr: new ArrayType({
              childrenType: new ObjectType({
                schema: {
                  obj_arr_obj__arr__f1: new TextType(),
                  obj_arr_obj__arr__f2: new TextType(),
                },
              }),
            }),
          },
        }),
        arr_arr: new ArrayType({
          childrenType: new ArrayType({ childrenType: new TextType() }),
        }),
        obj_obj: new ObjectType({
          schema: {
            obj_obj__obj1: new ObjectType({
              schema: {
                obj_obj__obj1__f1: new TextType(),
                obj_obj__obj1__f2: new TextType(),
              },
            }),
            obj_obj__obj2: new ObjectType({
              schema: {
                obj_obj__obj2__f1: new TextType(),
                obj_obj__obj2__f2: new TextType(),
              },
            }),
          },
        }),
      }}
      listColumns={[
        {
          header: 'Simple',
          name: 'single',
        },
      ]}
      formFields={[
        {
          label: 'DateTime',
          name: 'dtt',
        },
        {
          label: 'Date',
          name: 'dt',
        },
        {
          label: 'Time',
          name: 'tt',
        },
        {
          label: 'DateTimeRange',
          name: 'dtrt',
        },
        {
          label: 'DateRange',
          name: 'drt',
        },
        {
          label: 'TimeRange',
          name: 'trt',
        },
        {
          label: 'Simple',
          name: 'single',
        },
        {
          label: 'Array',
          name: 'arr',
        },
        {
          label: 'Object',
          name: 'obj',
          typeParams: {
            formFields: [
              {
                label: 'Field 1',
                name: 'obj__f1',
              },
              {
                label: 'Field 2',
                name: 'obj__f2',
              },
            ],
          },
        },
        {
          label: 'Array of objects',
          name: 'arr_obj',
          typeParams: {
            childrenParams: {
              formFields: [
                {
                  label: 'Field 1',
                  name: 'arr_obj__f1',
                },
                {
                  label: 'Field 2',
                  name: 'arr_obj__f2',
                },
              ],
            },
          },
        },
        {
          label: 'Object with array',
          name: 'obj_arr',
          typeParams: {
            formFields: [
              {
                label: 'Field 1',
                name: 'obj_arr__f1',
              },
              {
                label: 'Array',
                name: 'obj_arr__arr',
              },
            ],
          },
        },
        {
          label: 'Array of objects with array',
          name: 'arr_obj_arr',
          typeParams: {
            childrenParams: {
              formFields: [
                {
                  label: 'Field 1',
                  name: 'arr_obj_arr__f1',
                },
                {
                  label: 'Array',
                  name: 'arr_obj_arr__arr',
                },
              ],
            },
          },
        },
        {
          label: 'Object with array of objects',
          name: 'obj_arr_obj',
          typeParams: {
            formFields: [
              {
                label: 'Field 1',
                name: 'obj_arr_obj__f1',
              },
              {
                label: 'Array',
                name: 'obj_arr_obj__arr',
                typeParams: {
                  childrenParams: {
                    formFields: [
                      {
                        label: 'Field 1',
                        name: 'obj_arr_obj__arr__f1',
                      },
                      {
                        label: 'Field 2',
                        name: 'obj_arr_obj__arr__f2',
                      },
                    ],
                  },
                },
              },
            ],
          },
        },
        {
          label: 'Array of arrays',
          name: 'arr_arr',
        },
        {
          label: 'Object with objects',
          name: 'obj_obj',
          typeParams: {
            formFields: [
              {
                label: 'Object 1',
                name: 'obj_obj__obj1',
                typeParams: {
                  formFields: [
                    {
                      label: 'Field 1',
                      name: 'obj_obj__obj1__f1',
                    },
                    {
                      label: 'Field 2',
                      name: 'obj_obj__obj1__f2',
                    },
                  ],
                },
              },
              {
                label: 'Object 2',
                name: 'obj_obj__obj2',
                typeParams: {
                  formFields: [
                    {
                      label: 'Field 1',
                      name: 'obj_obj__obj2__f1',
                    },
                    {
                      label: 'Field 2',
                      name: 'obj_obj__obj2__f2',
                    },
                  ],
                },
              },
            ],
          },
        },
      ]}
      crudOptions={{
        hooks: {
          beforeSave: (record) => {
            console.log({ record })
            message.success('Valores enviados para o console.')
            return false
          },
        },
      }}
    />
  )
}
