import { TextType, TokenType } from '../../components/data/fieldtypes'

import CRUDPage from '../../components/data/CRUDPage'
import React from 'react'

export default function Subjects(props) {
  return (
    <CRUDPage
      user={props.user}
      history={props.history}
      params={props.match.params}
      resource='/subject'
      schema={{
        name: new TextType({ unique: true }),
        variant: new TokenType({
          tokens: [{ none: 'Normal' }, { essay: 'Redações' }],
          valueDefault: 'none',
        }),
        order: new TextType({ valueDefault: '0' }),
      }}
      listColumns={[
        {
          header: 'Matéria',
          name: 'name',
        },
        {
          header: 'Ordem',
          name: 'order',
        },
      ]}
      formFields={[
        {
          name: 'name',
          label: 'Matéria',
        },
        {
          name: 'variant',
          label: 'Variação',
        },
        {
          name: 'order',
          label: 'Ordem',
        },
      ]}
      listOptions={{
        sortColumn: 'order',
      }}
    />
  )
}
