import { DateType, TimeType } from '../../data/fieldtypes'

import { Alert } from 'antd'
import React from 'react'

export default function ExamSchedule({ isToday, examDate, startTime }) {
  return isToday ? (
    <Alert
      type='warning'
      className='center'
      message='Você tem um simulado hoje! O horário de início é:'
      description={
        <>
          <h3>{TimeType.format(startTime)}</h3>
          <p>
            Não se preocupe, as questões serão carregadas automaticamente nesta
            página quando o simulado começar!
          </p>
        </>
      }
    />
  ) : (
    <Alert
      type='warning'
      className='center'
      message={
        <>
          <p>Você tem um simulado programado para a seguinte data:</p>
          <h3>
            {DateType.format(examDate)} {TimeType.format(startTime)}
          </h3>
          <p>
            Lembre-se de entrar com alguns minutos de antecedência para evitar
            atrasos!
          </p>
        </>
      }
    />
  )
}
