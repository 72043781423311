import ButtonBar from './ButtonBar'
import IconButton from './IconButton'
import React from 'react'

export default function CRUDButtonBar({
  handlers,
  flags,
  omitResetFilters = false,
  omitCreate = false,
  omitRemove = false,
  omitViewList = false,
  omitEdit = false,
  omitSave = false,
  disableResetFilters = false,
  disableCreate = false,
  disableEdit = false,
  disableRemove = false,
  disableSave = false,
  captions = {},
  hidden = false,
}) {
  const hasListButtons = !omitResetFilters || !omitCreate
  const hasItemButtons = !omitRemove || !omitViewList || !omitEdit || !omitSave
  hidden =
    hidden ||
    (flags.isListView && !hasListButtons) ||
    (!flags.isListView && !hasItemButtons)

  const renderListButtons = () => (
    <>
      {!omitResetFilters && (
        <IconButton.ResetFilters
          onClick={handlers.resetFilters}
          hidden={omitResetFilters}
          disabled={disableResetFilters}
          text={captions.resetFilters}
        />
      )}
      {!omitCreate && (
        <IconButton.New
          onClick={handlers.create}
          hidden={omitCreate}
          disabled={disableCreate}
          text={captions.new}
        />
      )}
    </>
  )

  const renderItemButtons = () => (
    <>
      {!omitRemove && !flags.isEditing && (
        <ButtonBar.Group>
          <IconButton.Remove
            onClick={handlers.delete}
            disabled={disableRemove}
            text={captions.remove}
          />
        </ButtonBar.Group>
      )}
      {!flags.isEditing && (
        <ButtonBar.Group>
          <IconButton.Back
            onClick={handlers.viewList}
            hidden={omitViewList}
            text={captions.viewList}
          />
          <IconButton.Edit
            onClick={handlers.edit}
            hidden={omitEdit}
            disabled={disableEdit}
            text={captions.edit}
          />
        </ButtonBar.Group>
      )}
      {flags.isEditing && (
        <ButtonBar.Group>
          <IconButton.Cancel
            onClick={handlers.cancelEdit}
            hidden={flags.isCreating}
            text={captions.cancelEdit}
          />
          <IconButton.Cancel
            onClick={handlers.viewList}
            hidden={!flags.isCreating}
            text={captions.cancelCreate}
          />
          <IconButton.Save
            onClick={handlers.save}
            disabled={disableSave}
            hidden={omitSave}
            text={captions.save}
          />
        </ButtonBar.Group>
      )}
    </>
  )

  return (
    !hidden && (
      <ButtonBar>
        {flags.isListView ? renderListButtons() : renderItemButtons()}
      </ButtonBar>
    )
  )
}
