import {
  ArrayType,
  DateType,
  FileType,
  RefType,
  TextType,
  TokenType,
} from '../../components/data/fieldtypes'

import CRUDPage from '../../components/data/CRUDPage'
import React from 'react'
import { appRoutes } from '../../components/routing/routingConfig'

export default function EssayThemes(props) {
  return (
    <CRUDPage
      user={props.user}
      history={props.history}
      params={props.match.params}
      resource='/tema'
      dependencies={{
        courseQuery: '/course',
      }}
      schema={({ courseQuery }) => ({
        category: new TokenType({
          tokens: [
            { 'Curso CTI/ETEC': 'Curso CTI/ETEC' },
            { 'CTI/ETEC': 'Pacote CTI/ETEC' },
            { ENEM: 'Pacote ENEM' },
            { Simulado: 'Simulado' },
          ],
        }),
        id_courses: new ArrayType({
          childrenType: new RefType({
            query: courseQuery,
            display: 'name',
            routeConfig: appRoutes.courses.root,
          }),
        }),
        dtavailable: new DateType(),
        dtdelivery: new DateType(),
        titulo: new TextType({
          uniqueWith: 'category',
          duplicateError:
            'O campo %% deve ser único para cada categoria, mas já existe um registro com este valor',
        }),
        tema: new TextType(),
        material: new FileType({ type: 'pdf' }),
      })}
      listColumns={[
        {
          header: 'Categoria',
          name: 'category',
        },
        {
          header: 'Disponível',
          name: 'dtavailable',
        },
        {
          header: 'Entrega',
          name: 'dtdelivery',
        },
        {
          header: 'Descrição',
          name: 'titulo',
        },
        {
          header: 'Tema',
          name: 'tema',
        },
      ]}
      formFields={[
        {
          name: 'category',
          label: 'Categoria',
          onChange: ({ value, setFields }) => {
            if (value !== 'Curso CTI/ETEC') {
              setFields({
                id_courses: [],
                dtavailable: undefined,
                dtdelivery: undefined,
              })
            } else {
              setFields({
                dtavailable: DateType.now(),
                dtdelivery: DateType.now(),
              })
            }
          },
        },
        {
          name: 'id_courses',
          label: 'Cursos',
          hiddenFn: ({ record }) => record.category !== 'Curso CTI/ETEC',
          optionalFn: ({ record }) => record.category !== 'Curso CTI/ETEC',
          dependencies: ['category'],
        },
        {
          name: 'dtavailable',
          label: 'Data Disponível',
          hiddenFn: ({ record }) => record.category !== 'Curso CTI/ETEC',
          optionalFn: ({ record }) => record.category !== 'Curso CTI/ETEC',
          dependencies: ['category'],
        },
        {
          name: 'dtdelivery',
          label: 'Prazo de Entrega',
          hiddenFn: ({ record }) => record.category !== 'Curso CTI/ETEC',
          optionalFn: ({ record }) => record.category !== 'Curso CTI/ETEC',
          dependencies: ['category'],
        },
        {
          name: 'titulo',
          label: 'Descrição',
        },
        {
          name: 'tema',
          label: 'Tema',
        },
        {
          name: 'material',
          label: 'Material de Apoio (PDF)',
        },
      ]}
      listOptions={{
        sortFunction: (t) => t.category + t.titulo,
      }}
    />
  )
}
